define('jason-frontend/controllers/compositeproducts/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "compositeproducts.edit",
    queryParams: ['referer', 'refererInvoiceId', 'refererPatientId', 'selectTab', 'type'],
    referer: null,
    refererInvoiceId: null,
    refererPatientId: null,
    showTherapies: true,
    showDiagnoses: true,
    showSymptoms: true,
    showReport: true,
    sidebarActive: false,
    showAnamnese: true,
    selectTab: 'masterdata',
    actions: {
      triggerSubmit: function triggerSubmit() {
        $('form#productEdit').submit();
      },
      save: function save(referer, refererInvoiceId, refererPatientId) {

        var allProducts = new Array(),
            allServices = new Array(),
            indexCount = 0;

        $('div.product-row').each(function () {
          var line = $(this),
              id = line.data('id'),
              qty = $('input.product-quantity-input', line).val();

          if (qty) {
            qty = qty.replace(",", ".");
          }

          if (id > 0) {
            allProducts[indexCount] = {
              id: id,
              quantity: qty
            };
            indexCount++;
          }
        });

        indexCount = 0;
        $('div.service-row').each(function () {
          var line = $(this),
              id = line.data('id'),
              qty = $('input.product-quantity-input', line).val();

          if (qty) {
            qty = qty.replace(",", ".");
          }

          if (id > 0) {
            allServices[indexCount] = {
              id: id,
              quantity: qty
            };
            indexCount++;
          }
        });

        var self = this;

        setTimeout(function () {
          var model = self.get('model');

          var product = {};
          product.id = model.id;
          product.name = model.get('name');
          product.description = model.get('description');
          product.products = allProducts;
          product.services = allServices;
          product.favorite = model.get('favorite');
          product.ageFrom = model.get('ageFrom');
          product.ageTo = model.get('ageTo');
          product.weightFrom = model.get('weightFrom');
          product.weightTo = model.get('weightTo');

          $.ajax({
            url: "/api/compositeArticles/" + model.id,
            method: "PUT",
            data: JSON.stringify(product),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            if (referer === 'sale') {
              self.transitionTo('sale.compositeproducts');
            } else if (referer === 'add-composite') {
              self.transitionTo('cash.add_composite', refererInvoiceId, refererPatientId);
            } else {
              jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
            }
          }, function (error) {});
        }, 1000);
      }
    }
  });

});
define('jason-frontend/routes/rlogin', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/config/environment'], function (exports, Ember, API, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      API['default'].logout();
      this.session.set('user', null);
      API['default'].resetToken();
      var token = params.queryParams.code;
      if (token) {
        this.login(token);
      } else {
        window.location.href = '/login';
      }
    },
    login: function login(authorizationCode) {
      var self = this;

      var requestData = {
        "grant_type": "authorization_code",
        "redirect_uri": config['default'].baseUrl + "/rlogin",
        "code": authorizationCode
      };

      $.ajax({
        url: config['default'].ssoUrl + "/oauth2/token",
        method: "POST",
        data: requestData,
        // contentType: "application/json",
        dataType: "json",
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization': 'Basic ' + btoa(config['default'].oauth2clientId + ':' + config['default'].appSecret)
        }
      }).then(function (data) {
        var accessToken = data.access_token;

        var deferred = $.ajax({
          url: "/api/users/rlogin",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(function (data) {
          API['default'].processLogin(data.login, API['default']);
          API['default'].setPermissions(data.login.permissions);

          if (data.login.passwordExpired) {
            self.transitionTo('expired');
          } else {
            self.session.set('user', data.login.user);
            window.location.replace("/dashboard/welcome");
          }
        }, function (error) {
          console.log(error);
        });
      }, function (data) {});
    },
    actions: {}
  });

});
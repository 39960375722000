define('jason-frontend/models/composite-article-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    articleId: DS['default'].attr('number'),
    quantity: DS['default'].attr('number'),
    unit: DS['default'].attr('string'),
    first: DS['default'].attr(''),
    unitQuantity: DS['default'].attr('number'),
    totalSingle: DS['default'].attr('number'),
    applied: DS['default'].attr('boolean'),
    deleted: DS['default'].attr('boolean'),
    tgd: DS['default'].attr('boolean'),
    jasonPrice: DS['default'].attr('boolean'),
    jason: DS['default'].attr('boolean'),
    jasonPriceAuto: DS['default'].attr('boolean'),
    tax: DS['default'].attr('number'),
    type: DS['default'].attr(''),
    media: DS['default'].attr(''),
    tags: DS['default'].attr(''),
    gotFactor: DS['default'].attr(''),
    priceNet: DS['default'].attr(''),
    gotNumber: DS['default'].attr(''),
    gotDeprecated: DS['default'].attr(''),
    editable: DS['default'].attr('boolean')
  });

});
define('jason-frontend/controllers/profile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Mein Profil",
    breadCrumbPath: "profile",
    themes: [{
      id: "elovet", name: 'elovet (grau)'
    }, {
      id: "eltiga", name: 'eltiga (blau)'
    }, {
      id: "elord", name: 'elord (rot)'
    }, {
      id: "barbie", name: 'bonbon (pink)'
    }, {
      id: "pride", name: 'pride (regenbogen)'
    }]
  });

  // {
  //   id: "green", name: 'natur (green)'
  // }

});
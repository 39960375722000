define('jason-frontend/routes/invoices/create', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    scrollSelector: window,
    activate: function activate() {
      this._super.apply(this, arguments);
      var self = this;
      if (this.get('lastScroll')) {

        Ember['default'].run.next(function () {
          Ember['default'].$(self.scrollSelector).scrollTop(self.get('lastScroll'));
        });
      } else {
        Ember['default'].$(this.scrollSelector).scrollTop(0);
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.set('lastScroll', Ember['default'].$(this.scrollSelector).scrollTop());
    },
    setupController: function setupController(controller, model, params) {
      var invoiceId = params.params['invoices.create'].id;
      var invoice = new Object();
      var cont = controller;
      var self = this;

      controller.set('shownHeads', new Array());
      controller.set('selectedPatient', 0);
      controller.set('customerType', 'anon');

      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      $.ajax({
        url: "/api/users/role/doctor",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        cont.set("doctors", data.user);
      });

      $.ajax({
        url: "/api/users/role/assistant",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        cont.set("assistants", data.user);
      });

      controller.set('users', this.store.find('user', { per_page: 100 }));
      controller.set('submitButtonEnabled', true);

      if (invoiceId > 0) {
        controller.set('shownHeads', new Array());
        controller.set('currentInvoiceId', invoiceId);
        this.store.fetch('invoice', invoiceId).then(function (inv) {
          if (inv.get('customer')) {
            cont.set('customerType', 'known');

            controller.set('customerDeposit', self.store.fetch('customer', inv.get('customer').get('id')));

            if (params.queryParams.entry === 'true' && inv.get('customer').get('sued')) {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-customer-nopayer-info-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }

            $.ajax({
              url: "/api/customerDeposits/" + inv.get('customer').get('id'),
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set('balanceSum', data.meta.sum);
              controller.set('depositSum', data.meta.depositSum);
              controller.set('status', data.meta.status);
            });
          }
          return inv;
        });

        this.store.find('invoice', invoiceId).then(function (data) {
          var status = data.get('orderStatus');
          if (status && status !== 'created') {
            self.transitionTo('cash.choose');
          }
          controller.set('invoice', data);
        });

        if (controller.get('styleStepPayment') === '' || controller.get('styleStepConfirmation') === '') {
          controller.set('styleStepPositions', '');
          controller.set('styleStepPayment', 'hidden');
          controller.set('styleStepConfirmation', 'hidden');
        }
      }

      Ember['default'].$.ajax({
        url: "/api/cashdesk/signatureUnitAvailable",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (data != true) {
          jason.notifiction.systemError('Signatureinheit ausgefallen', 'Sie können aber weiterhin kassieren!');
        }
      }, function (error) {});

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('slipNotice', 'Lieferdatum = Rechnungsdatum');

      $.ajax({
        url: "/api/cashdesk/cashdeskSettings",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('terminalActivated', data.cashDeskSettings.terminalActivated);
        controller.set('externalCashRegister', data.cashDeskSettings.externalCashRegister);
      }, function (data) {});
    },
    actions: {
      extractInvoice: function extractInvoice(invoiceId, patientId, mode, targetInvoiceId, targetPatientId) {
        var self = this;
        var data = {
          sourcePatientId: patientId,
          targetPatientId: targetPatientId,
          targetInvoiceId: targetInvoiceId,
          mode: mode
        };

        $.ajax({
          url: "/api/invoices/" + invoiceId + "/movePositions",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Positionen erfolgreich verschoben');
          self.refresh();
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Positionen erfolgreich verschoben');
          self.refresh();
        });
      },
      openExtract: function openExtract(invoiceId, patientId, customerId) {

        if (!patientId) {
          patientId = "";
        }
        var self = this;
        $.ajax({
          url: "/api/invoices/openForCustomer?filterCustomer=" + customerId + "&currentInvoiceId=" + invoiceId + "&currentPatientId=" + patientId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-extract-invoice-panel-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
          self.get('controller').get('extractInvoicePanel').send('load', invoiceId, patientId, data.treatmentPatientInvoice, 'new');
        });

        // let suffix = '';
        // if (exportMode === 'new') {
        //   suffix = '?selectedInvoiceId=0';
        // } else if (exportMode === 'composite') {
        //   suffix = '?selectedInvoiceId=' + exportInvoiceId;
        // }
        // var self = this,
        //   deferred = $.ajax({
        //     url: "/api/treatmentOffers/" + id + "/checkout" + suffix,
        //     method: "POST",
        //     contentType: "application/json",
        //     dataType: "json",
        //     headers: {
        //       'X-AUTH-TOKEN': API.getToken()
        //     }
        //   }).then(
        //     function (data) {
        //       // self.transitionTo('invoices.create', data, {queryParams: {entry: "true"}});
        //       // jason.notifiction.notifiy('In Ausgangsrechnung übertragen', '');
        //     },
        //     function (error) {
        //       // jason.notifiction.error('Fehler', 'Behandlung wurde bereits übergeben');
        //     }
        //   );

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-extract-invoice-panel-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toggleProductFavorite: function toggleProductFavorite(id, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/products/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/products/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      togglePriceFavorite: function togglePriceFavorite(id, priceId, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/products/" + id + "/price/" + priceId + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/products/" + id + "/price/" + priceId + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      saveCorrection: function saveCorrection(data) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        });
      },

      addDistance: function addDistance() {

        var controller = this.get('controller');
        var invoiceId = controller.get('invoice').id;
        var self = this;

        Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/addDistance",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Anfahrtszeiten', 'hinzugefügt');
        }, function (data) {
          self.refresh();
          jason.notifiction.notifiy('Anfahrtszeiten', 'hinzugefügt');
        });
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(entryId, format) {
        window.open('/api/invoices/downloadSloping/' + entryId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadPatientBarcode: function downloadPatientBarcode(patientId, format) {
        window.open('/api/patients/downloadBarcode/' + patientId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadLaboklinBarcode: function downloadLaboklinBarcode(formId, format) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      gotoProduct: function gotoProduct(id) {
        this.transitionTo('products.edit', id, { queryParams: { selectTab: "sloping" } });
      },
      saveSlopingInfo: function saveSlopingInfo(data, entryId) {
        var self = this,
            controller = this.get('controller'),
            deferred = $.ajax({
          url: "/api/invoices/" + self.get('controller').get('invoice').id + "/changeSlopingInfo/" + entryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {});
      },
      selectPrice: function selectPrice(qty, productId, referenceId, priceId, productName, patientId, directEhapo, stockId) {
        var self = this;
        var controller = this.get('controller');

        if (patientId == true || typeof patientId === 'undefined') {
          patientId = 0;
        }

        var deferred = jQuery.ajax({
          url: "/api/invoices/" + self.get('controller').get('invoice').id + "/add/product/" + productId + "/" + qty + "/" + priceId + "/" + patientId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          //self.get('controller').set("invoice", self.store.fetch('invoice', invoiceId));
          jason.notifiction.notifiy('Produkt(e) hinzugefügt', productName);

          var entryId = data.orderEntry.id,
              qty = data.orderEntry.quantity;

          qty = qty * data.orderEntry.unitQuantity;

          if (directEhapo && directEhapo.id === 'ehapo') {

            var bookoutData = {
              entryId: entryId,
              invoiceId: self.get('model').id,
              stockId: stockId,
              amount: qty,
              potentialConversion: true,
              potentialConversionRecalculate: true
            };

            Ember['default'].$.ajax({
              url: "/api/inventoryStocks/bookOutFromInvoice",
              method: "POST",
              contentType: "application/json",
              data: JSON.stringify(bookoutData),
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (dataNew) {
              if (dataNew.orderEntry.fullyBooked) {
                self.refresh();
                setTimeout(function (e) {
                  controller.send('scan', patientId, directEhapo);
                }, 1000);
              } else {
                self.refresh();
              }
            }, function (data) {});
          } else {
            self.refresh();
            setTimeout(function (e) {
              controller.send('scan', patientId, directEhapo);
            }, 1000);
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      scanArticle: function scanArticle(id, patientId) {

        var self = this;

        var controller = this.get('controller');

        if (patientId == true || patientId === 'undefined') {
          patientId = 0;
        }

        if (id.startsWith('package')) {

          var deferred = jQuery.ajax({
            url: "/api/invoices/" + self.get('controller').get('invoice').id + "/add/composite/" + id.replace("package", "") + "/1/" + patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Paket', 'hinzugefügt');
            self.refresh();
          });
        } else if (id.startsWith('service')) {

          var deferred = jQuery.ajax({
            url: "/api/invoices/" + self.get('controller').get('invoice').id + "/add/service/" + id.replace("service", "") + "/1/" + patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Leistung', 'hinzugefügt');
            self.refresh();
            setTimeout(function () {
              controller.send('scan', true);
            }, 500);
          });
        } else if (id.startsWith('product')) {

          $.ajax({
            url: "/api/products/" + id.replace("product", ""),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {

            var prices = data.product.prices != null ? data.product.prices.length : 0,
                productId = data.product.id;

            if (prices == 0) {

              var priceId = prices > 0 ? +data.product.prices[0].id : 0;

              var deferred = jQuery.ajax({
                url: "/api/invoices/" + self.get('controller').get('invoice').id + "/add/product/" + productId + "/1/" + priceId + "/" + patientId,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {

                var entryId = data;

                $.ajax({
                  url: "/api/inventoryStocks/checkNumber/" + id,
                  method: "GET",
                  contentType: "application/json",
                  dataType: "json",
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  jason.notifiction.notifiy('Produkt', 'hinzugefügt');
                  self.refresh();
                }, function (error) {
                  self.refresh();
                });
              }, function (error) {
                return { status: error.statusText, message: error.responseText };
              });
              return Ember['default'].RSVP.resolve(deferred);
            } else {

              controller.get('quickScanSelectPricePanel').send('loadSingle', data, patientId);

              setTimeout(function () {
                $.magnificPopup.open({
                  removalDelay: 500,
                  closeOnBgClick: false,
                  items: {
                    src: '#modal-quick-scan-select-price-panel'
                  },
                  callbacks: {
                    beforeOpen: function beforeOpen() {
                      var Animation = "mfp-with-fade";
                      this.st.mainClass = Animation;
                    }
                  },
                  midClick: true
                });
              }, 500);
            }
          }, function () {
            jason.notifiction.error('Fehler', 'Produkt nicht erkannt');
          });
        }
      },
      scanProduct: function scanProduct(id, patientId, directEhapo) {
        var controller = this.get('controller'),
            self = this;

        if (patientId !== null && (typeof patientId === 'object' || typeof patientId === 'boolean')) {
          patientId = 0;
        }

        $.ajax({
          url: "/api/products?filterId=" + id + '&patientId=' + patientId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          var prices = data.product[0].prices != null ? data.product[0].prices.length : 0,
              productId = data.product[0].id;

          var priceId = prices > 0 ? +data.product[0].prices[0].id : 0;

          var allPrices = data.product[0].prices,
              favPriceOnes = 0;

          if (allPrices && directEhapo.useFavorites) {
            allPrices.forEach(function (price) {
              if (price.favorite && price.quantity == 1) {
                priceId = price.id;
                favPriceOnes++;
              }
            });

            if (favPriceOnes == 1) {
              prices = 0;
            }
          }

          if (prices == 0) {

            var deferred = jQuery.ajax({
              url: "/api/invoices/" + self.get('controller').get('invoice').id + "/add/product/" + productId + "/1/" + priceId + "/" + patientId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {

              var entryId = data.orderEntry.id,
                  qty = data.orderEntry.quantity;

              $.ajax({
                url: "/api/inventoryStocks/checkNumber/" + id,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {

                if (data.inventoryStock.flatStock <= 0) {
                  jason.notifiction.error('Achtung', 'Dieses Produkte wurde bereit vollständig ausgebucht');
                } else {
                  jason.notifiction.notifiy('Produkt', 'hinzugefügt');

                  if (directEhapo.id === 'ehapo') {

                    var bookoutData = {
                      entryId: entryId,
                      productId: data.inventoryStock.product.id,
                      invoiceId: self.get('model').id,
                      stockId: data.inventoryStock.id,
                      amount: qty,
                      potentialConversion: true,
                      potentialConversionRecalculate: true
                    };

                    Ember['default'].$.ajax({
                      url: "/api/inventoryStocks/bookOutFromInvoice",
                      method: "POST",
                      contentType: "application/json",
                      data: JSON.stringify(bookoutData),
                      dataType: "json",
                      headers: {
                        'X-AUTH-TOKEN': API['default'].getToken()
                      }
                    }).then(function (dataNew) {

                      if (dataNew.orderEntry.fullyBooked) {
                        self.refresh();
                        setTimeout(function (e) {
                          controller.send('scan', patientId, directEhapo);
                        }, 500);
                      }
                    }, function (data) {});
                  } else {
                    setTimeout(function (e) {
                      controller.send('scan', patientId, directEhapo);
                    }, 500);
                  }
                }
                self.refresh();
              }, function (error) {
                self.refresh();
              });
            }, function (error) {
              return { status: error.statusText, message: error.responseText };
            });
            return Ember['default'].RSVP.resolve(deferred);
          } else {

            if (directEhapo.id === 'ehapo') {

              $.ajax({
                url: "/api/inventoryStocks/checkNumber/" + id,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (dataCheck) {
                controller.get('quickScanSelectPricePanel').send('load', data, patientId, directEhapo, dataCheck.inventoryStock.id);
              });
            } else {
              controller.get('quickScanSelectPricePanel').send('load', data, patientId, directEhapo);
            }

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-quick-scan-select-price-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 500);
          }
        }, function () {
          jason.notifiction.error('Fehler', 'Produkt nicht erkannt');
        });
      },
      doBookout: function doBookout(data) {
        var controller = this.get('controller'),
            model = controller.get('model'),
            self = this;

        Ember['default'].$.ajax({
          url: "/api/inventoryStocks/bookOutFromInvoice",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (dataNew) {
          jason.notifiction.notifiy('Erfolgreich', 'Warenausgang gebucht');

          if (dataNew.orderEntry.fullyBooked) {
            self.refresh();
          } else {
            controller.send('openInventoryStockPanel', dataNew.orderEntry, false);
          }
        }, function (data) {});
      },
      removeMovement: function removeMovement(id) {
        var controller = this.get('controller'),
            model = controller.get('model'),
            self = this;

        Ember['default'].$.ajax({
          url: "/api/inventoryStockMovements/" + id + "/invoice",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (dataNew) {
          jason.notifiction.notifiy('Storniert', 'Warenausgang wurde storniert');
          self.refresh();
        }, function (data) {});
      },
      createPatientForCustomer: function createPatientForCustomer(customerId, patientData, invoiceId) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          customerId: customerId,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.patientCategory) {
          newPatient.category = {
            id: patientData.patientCategory
          };
        }
        if (patientData.patientGender) {
          newPatient.gender = {
            id: patientData.patientGender
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          $.ajax({
            url: "/api/invoices/" + invoiceId + "/changePatient/" + data.patient.id + "/true",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            self.refresh();
          });
        }, function (error) {});
      },
      toChoose: function toChoose() {
        this.transitionTo('cash.choose');
      },
      changeGlobalUser: function changeGlobalUser(invoiceId, user, type) {
        var self = this,
            data = {
          userId: user ? user.id : null,
          type: type
        };

        $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeUser",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
        }, function (error) {
          self.refresh();
        });
      },
      sendEmail: function sendEmail(invoiceId, email) {
        jason.notifiction.notifiy('Erfolgreich', 'Rechnung wurde per Email übermittelt');
        Ember['default'].$.ajax({
          url: "/api/invoices/mailto/" + invoiceId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(email),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {});
      },
      reload: function reload() {
        var controller = this.get('controller');

        if (controller.get('invoice')) {
          controller.get('invoice').reload();
        }
      },
      changeDay: function changeDay(invoiceId, day, entryId) {

        var data = {
          day: moment['default'](day, 'DD. MM. YYYY').format('YYYY-MM-DD')
        };

        $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDay/" + entryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {});
      },
      sort: function sort(invoiceId) {
        this.refresh();
      },
      printInvoice: function printInvoice(invoice) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: invoice.id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/invoices/downloadPreview/' + invoice.id + '?token=' + data.responseText);
        });
      },
      toggleDeadPatients: function toggleDeadPatients(enabled) {
        var controller = this.get('controller');
        controller.set('filterDeadPatients', enabled);
      },
      changeCustomer: function changeCustomer(invoice) {},
      saveTreatment: function saveTreatment(data) {
        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      reloadHistory: function reloadHistory(patientId) {
        this.get('controller').get('getTreatmentInfoHistory').send('load', this.store.find('treatment-patient', { patientId: patientId }), patientId);
      },
      download: function download(url) {
        window.open(url);
      },
      print: function print(transactionGroup) {
        $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      selectInvoice: function selectInvoice(id) {
        var controller = this.get('controller');
        this.transitionTo('invoices.create', id);
        controller.set('styleStepCustomer', 'hidden');
        controller.set('styleStepPositions', '');
        controller.set('styleStepPayment', 'hidden');
        controller.set('styleStepConfirmation', 'hidden');
        controller.set('selectedPatient', 0);
      },
      closeInvoice: function closeInvoice(id) {
        var self = this,
            currentInvoiceId = this.get('controller').get('currentInvoiceId');

        $.ajax({
          url: "/api/invoices/" + id + "/close",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          if (currentInvoiceId == id) {
            self.transitionTo('cash.choose');
          }
          jason.notifiction.notifiy('Verrechnung', 'geschlossen');
        }, function () {
          if (currentInvoiceId == id) {
            self.transitionTo('cash.choose');
          }
          jason.notifiction.notifiy('Verrechnung', 'geschlossen');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      toAddArticle: function toAddArticle(invoiceId, patientId) {
        this.transitionTo('cash.add_article', 'invoices', invoiceId, patientId);
      },
      toAddProduct: function toAddProduct(invoiceId, patientId) {
        this.transitionTo('cash.add_product', invoiceId, patientId);
      },
      toAddComposite: function toAddComposite(invoiceId, patientId) {
        this.transitionTo('cash.add_composite', invoiceId, patientId);
      },
      toAddService: function toAddService(invoiceId, patientId) {
        this.transitionTo('cash.add_service', invoiceId, patientId);
      },
      changePatient: function changePatient(invoiceId, patientId, add) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + invoiceId + "/changePatient/" + patientId + "/" + add,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
        });
      },
      registerBankadvicePayment: function registerBankadvicePayment(payment, deposit) {
        var controller = this.get('controller'),
            invoiceId = controller.get('currentInvoiceId'),
            notice = controller.get('notice'),
            voucherNumber = controller.get('voucherNumber'),
            invoiceDate = controller.get('invoiceDate'),
            treatmentNotice = $('div#treatmentNotice').html(),
            dueDate = controller.get('dueDate'),
            selectedCashRegister = controller.get('selectedCashRegister');

        controller.set('submitButtonEnabled', false);

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var payload = {
          invoiceId: invoiceId,
          dueDate: moment['default'](dueDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          invoiceDate: moment['default'](invoiceDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          notice: notice,
          treatmentNotice: treatmentNotice,
          selectedCashRegister: selectedCashRegister,
          deposit: deposit,
          payment: payment
        };

        var self = this;

        $.ajax({
          url: "/api/cashdesk/bankadvice",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data && data > 0) {
            controller.set('currentTransactionId', data);

            controller.set('styleStepPayment', 'hidden');
            controller.set('styleStepConfirmation', '');

            self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
              controller.set('transactionGroup', data2);
              controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
            });
          } else {
            self.transitionTo('invoices.show', invoiceId);
          }

          jason.notifiction.notifiy('Buchung erfolgreich', '');
          controller.set('submitButtonEnabled', true);
          return data;
        }, function () {
          jason.notifiction.error('Unbekannter Fehler', 'Bitte wenden Sie sich an den Support');
          controller.set('submitButtonEnabled', true);
        });
      },
      toSelectConfirmationWithExternalCashRegister: function toSelectConfirmationWithExternalCashRegister(given) {
        var controller = this.get('controller');
        var invoiceId = controller.get('currentInvoiceId');

        controller.set('submitButtonEnabled', false);

        var payload = {
          invoiceId: invoiceId,
          payment: given,
          type: 'in'
        };

        controller.set('styleStepPayment', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        var self = this;

        $.ajax({
          url: "/api/cashdesk/extern",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.show', invoiceId);
          controller.set('showLoading', false);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Unbekannter Fehler', '');
        });
      },
      toSelectConfirmationWithoutPayment: function toSelectConfirmationWithoutPayment() {
        var controller = this.get('controller');

        var invoiceId = controller.get('currentInvoiceId'),
            notice = controller.get('notice'),
            treatmentNotice = $('div#treatmentNotice').html(),
            voucherNumber = controller.get('voucherNumber'),
            invoiceDate = controller.get('invoiceDate'),
            dueDate = controller.get('dueDate'),
            slipInfo = controller.get('slipNotice');

        controller.set('submitButtonEnabled', false);

        var payload = {
          invoiceId: invoiceId,
          voucherNumber: voucherNumber,
          dueDate: moment['default'](dueDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          invoiceDate: moment['default'](invoiceDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          notice: notice,
          treatmentNotice: treatmentNotice,
          recieptInfo: slipInfo,
          type: 'in'
        };

        controller.set('styleStepPayment', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        var self = this;

        $.ajax({
          url: "/api/cashdesk/inWithoutPayment",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.show', invoiceId);
          controller.set('showLoading', false);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Unbekannter Fehler', '');
        });
      },
      toSelectConfirmation: function toSelectConfirmation(paymentItems) {
        var controller = this.get('controller');

        var invoiceId = controller.get('currentInvoiceId'),
            notice = controller.get('notice'),
            treatmentNotice = $('div#treatmentNotice').html(),
            voucherNumber = controller.get('voucherNumber'),
            invoiceDate = controller.get('invoiceDate'),
            dueDate = controller.get('dueDate'),
            selectedCashRegister = controller.get('selectedCashRegister'),
            slipInfo = controller.get('slipNotice');

        controller.set('submitButtonEnabled', false);

        if (controller.get('customerType') === 'anon' && (!paymentItems || paymentItems && paymentItems.length == 0 || paymentItems && paymentItems[0].given < 0.01)) {
          jason.notifiction.error('Betrag fehlt', 'Bitte geben Sie einen Mindestbetrag von € 0,01 ein');
          controller.set('submitButtonEnabled', true);
          return false;
        }

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var payload = {
          invoiceId: invoiceId,
          voucherNumber: voucherNumber,
          dueDate: moment['default'](dueDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          invoiceDate: moment['default'](invoiceDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          notice: notice,
          treatmentNotice: treatmentNotice,
          selectedCashRegister: selectedCashRegister,
          recieptInfo: slipInfo,
          type: 'in',
          payments: paymentItems
        };

        controller.set('styleStepPayment', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        var self = this;

        $.ajax({
          url: "/api/cashdesk/in",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data && data > 0) {
            controller.set('currentTransactionId', data);

            self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
              controller.set('transactionGroup', data2);
              controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
            });

            jason.notifiction.notifiy('Buchung erfolgreich', '');
            controller.set('submitButtonEnabled', true);
          } else {
            self.transitionTo('invoices.show', invoiceId);
          }
          controller.set('showLoading', false);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Unbekannter Fehler', '');
        });
      },
      toSelectPositions: function toSelectPositions() {
        var controller = this.get('controller');
        controller.set('shownHeads', new Array());
        controller.set('styleStepCustomer', 'hidden');
        controller.set('styleStepType', 'hidden');
        controller.set('styleStepPositions', '');
        controller.set('styleStepPayment', 'hidden');
      },
      toSelectCustomerType: function toSelectCustomerType() {
        var controller = this.get('controller');
        controller.set('styleStepCustomer', '');
        controller.set('styleStepType', 'hidden');
        controller.set('styleStepPositions', 'hidden');
        controller.set('styleStepPayment', 'hidden');
      },
      toSelectPaymentOptions: function toSelectPaymentOptions() {
        var controller = this.get('controller');

        if (API['default'].getEhapoCheckCashdesk() === 'active' && !controller.get('invoice').get('fullyBooked')) {
          jason.notifiction.errorLong('Fehlende Lagerbuchung', 'Es gibt mind. eine Position für die noch kein Lagerausgang gebucht wurde');
          return;
        }

        controller.set('paymentInfosReady', false);
        controller.set('styleStepCustomer', 'hidden');
        controller.set('styleStepType', 'hidden');
        controller.set('styleStepPositions', 'hidden');
        controller.set('styleStepPayment', '');
        controller.set('styleStepConfirmation', 'hidden');
        controller.set('customerDeposit', null);
        controller.set('balanceSum', null);
        controller.set('depositSum', null);
        controller.set('status', null);

        controller.set('submitButtonEnabled', true);

        var today = moment['default']().format("DD. MM. YYYY"),
            self = this;

        if (controller.get('invoiceDate') == null) {
          controller.set('invoiceDate', today);
        }

        if (controller.get('dueDate') == null) {
          controller.set('dueDate', today);
        }

        setTimeout(function () {
          self.store.fetch('invoice', controller.get('currentInvoiceId')).then(function (data) {
            controller.set("invoice", data);
            if (data.get('customer') && data.get('customer').get('id') > 0) {
              controller.set('customerDeposit', self.store.fetch('customer', data.get('customer').get('id')));

              $.ajax({

                url: "/api/customerDeposits/" + data.get('customer').get('id'),
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                controller.set('balanceSum', data.meta.sum);
                controller.set('depositSum', data.meta.depositSum);
                controller.set('status', data.meta.status);
                controller.set('paymentInfosReady', true);
              });
            } else {
              controller.set('paymentInfosReady', true);
            }
          });
        }, 0);

        $.ajax({
          url: "/api/cashRegisters?hideInactive=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('cashRegisterList', data.cashRegister);
          controller.set('selectedCashRegister', data.cashRegister.get('firstObject'));
          if (API['default'].getPrimaryCashRegisterId()) {
            data.cashRegister.forEach(function (item) {
              if (item.id == API['default'].getPrimaryCashRegisterId()) {
                controller.set('selectedCashRegister', item);
              }
            });
          }
        });

        if (controller.get('invoice') != null) {
          var total = controller.get('invoice').get('total') + '';
          total = total.replace('.', ',');
          controller.set('amount', total);
        }
      },
      toSelectPaymentOptionsReverse: function toSelectPaymentOptionsReverse() {
        var controller = this.get('controller');
        controller.set('styleStepTwo', 'hidden');
        controller.set('styleStepThree', '');
        controller.set('styleStepFour', 'hidden');
        var total = controller.get('amount').replace('.', ',');
        controller.set('amount', total);
      },
      reset: function reset() {
        var controller = this.get('controller');
        controller.set('styleStepCustomer', '');
        controller.set('styleStepType', 'hidden');
        controller.set('styleStepPositions', 'hidden');
        controller.set('styleStepPayment', 'hidden');
        controller.set('styleStepConfirmation', 'hidden');

        controller.set('customerType', 'anon');
        controller.set('currentInvoiceId', null);
        controller.set('cashRegisterList', null);
        controller.set('selectedCashRegister', null);
        controller.set('selectedPaymentType', 'cash');

        controller.set('invoiceDate', moment['default']().format("DD. MM. YYYY"));
        controller.set('dueDate', moment['default']().format("DD. MM. YYYY"));

        controller.set('notice', null);
        controller.set('slipNotice', null);
        controller.set('amount', 0);
        controller.set('given', 0);
        controller.set('changeMoney', 0);

        //create new transaction
        controller.set('currentTransactionId', null);

        var self = this;
        var invoice = new Object();
        $.ajax({
          url: "/api/invoices",
          method: "POST",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', data);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      addDeposit: function addDeposit(invoiceId) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + invoiceId + "/add/deposit",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(null),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set("invoice", self.store.fetch('invoice', invoiceId));
          jason.notifiction.notifiy('Anzahlung', 'Eingetragen');
        }, function (data) {
          self.get('controller').set("invoice", self.store.fetch('invoice', invoiceId));
          jason.notifiction.notifiy('Anzahlung', 'Eingetragen');
        });
      },
      selectPatient: function selectPatient(patientId) {
        this.get('controller').set('selectedPatient', patientId);
      },
      deleteProduct: function deleteProduct(entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();

          //self.get('controller').set("invoice", self.store.fetch('invoice', invoiceId));
          self.get('controller').set('shownHeads', new Array());
          //self.refresh();
          jason.notifiction.notifiy('Produkt entfernt', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeQuantity: function changeQuantity(quantity, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeQuantity/" + entryId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Menge aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changePrice: function changePrice(price, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changePrice/" + entryId + "/" + price,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Preis aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeApplied: function changeApplied(applied, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeApplied/" + entryId + "/" + applied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          jason.notifiction.notifiy('Aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeBankadvice: function changeBankadvice(applied, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeBankadvice/" + entryId + "/" + applied,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('In Gutschrift umgewandelt', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeText: function changeText(articleText, entryId, invoiceId, productName) {
        var self = this;

        var data = {
          text: articleText
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeArticleText/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Bezeichnung geändert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeDescription: function changeDescription(descriptionText, entryId, invoiceId, productName) {
        var self = this;

        var data = {
          text: descriptionText
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeArticleDescription/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Beschreibung geändert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeInvoiceDiscount: function changeInvoiceDiscount(invoiceId, discount, absolute) {
        var self = this;

        var data = {
          amount: discount,
          absolute: absolute
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', '');
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeRelativeInvoiceDiscount: function changeRelativeInvoiceDiscount(invoiceId, discount) {
        var self = this;

        var data = {
          amount: discount,
          absolute: false
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', '');
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeAbsoluteInvoiceDiscount: function changeAbsoluteInvoiceDiscount(invoiceId, discount) {
        var self = this;
        var data = {
          amount: discount,
          absolute: true
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', '');
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeDiscount: function changeDiscount(discount, absolute, entryId, invoiceId, productName) {
        var self = this;

        var data = {
          amount: discount,
          absolute: absolute
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeRelativeDiscount: function changeRelativeDiscount(entryId, discount, invoiceId, productName) {
        var self = this;

        var data = {
          amount: discount,
          absolute: false
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeAbsoluteDiscount: function changeAbsoluteDiscount(entryId, discount, invoiceId, productName) {
        var self = this;
        var data = {
          amount: discount,
          absolute: true
        };

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeDiscount/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          jason.notifiction.notifiy('Rabatt geändert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});
define('jason-frontend/helpers/is-journal-enabled', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isJournalEnabled = isJournalEnabled;

  function isJournalEnabled() {
    return API['default'].getMandant() === '3' || API['default'].getMandant() === '153';
  }

  exports['default'] = Ember['default'].Helper.helper(isJournalEnabled);

});
define('jason-frontend/templates/components/treatment-now', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1,"id","positionsHeading");
            var el2 = dom.createTextNode("Tierärztliche Leistungen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 64
              },
              "end": {
                "line": 12,
                "column": 134
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","table__title title");
            var el2 = dom.createTextNode("VK Brutto");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 199
                },
                "end": {
                  "line": 13,
                  "column": 240
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Rabatt");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 240
                },
                "end": {
                  "line": 13,
                  "column": 254
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Faktor");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 145
              },
              "end": {
                "line": 13,
                "column": 268
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","table__title title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[13,205],[13,232]]]]],[],0,1,["loc",[null,[13,199],[13,261]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 64
              },
              "end": {
                "line": 14,
                "column": 131
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","table__title title");
            var el2 = dom.createTextNode("Gesamt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 47,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","treatment-now-entry",[],["entry",["subexpr","@mut",[["get","entry",["loc",[null,[22,14],[22,19]]]]],[],[]],"offer",["subexpr","@mut",[["get","offer",["loc",[null,[23,14],[23,19]]]]],[],[]],"newMode",["subexpr","@mut",[["get","newMode",["loc",[null,[24,16],[24,23]]]]],[],[]],"treatmentPatientId",["subexpr","@mut",[["get","model.id",["loc",[null,[25,27],[25,35]]]]],[],[]],"readOnly",["subexpr","not",[["subexpr","is-treatment-editable",[["get","model",["loc",[null,[26,45],[26,50]]]]],[],["loc",[null,[26,22],[26,51]]]]],[],["loc",[null,[26,17],[26,52]]]],"edit",["subexpr","is-treatment-editable",[["get","model",["loc",[null,[27,36],[27,41]]]]],[],["loc",[null,[27,13],[27,42]]]],"showPrice",true,"appliedChanged","changeApplied","openDigitalForm","openDigitalForm","textChanged","changeText","gotFactorChanged","gotFactorChanged","downloadLaboklinBarcode","downloadBarcode","downloadSlopingBarcode","downloadSlopingBarcode","openSlopingInfos","openSlopingInfos","openTemplateEdit","openTemplateEdit","deleted","deleteProduct","priceChanged","changeGrossPrice","quantityChanged","changeQuantity","relativeDiscountChanged","changeRelativeDiscount","absoluteDiscountChanged","changeAbsoluteDiscount","discountChanged","changeDiscount","openLaboklinChoosePanel","openLaboklinChoosePanel","openInventoryStockPanel","openInventoryStockPanel","openXrayEntryPanel","openXrayEntryPanel"],["loc",[null,[21,8],[46,10]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 2
              },
              "end": {
                "line": 61,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","invoice-footer-div");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","u-text--right box");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sum-table__col col l-16/24");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","title title--big");
            var el5 = dom.createTextNode("Gesamtsumme Brutto");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sum-table__col col l-8/24 l-3/24@tablet");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","title title--big");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","offer.total",["loc",[null,[57,71],[57,82]]]]],[],[]]],["loc",[null,[57,47],[57,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 62,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","box no-box@phone table table--invoice no-hover");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Menge");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-5/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Position");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell table__cell--right l-2/24");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-3/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-1/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [3]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element5, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
          morphs[2] = dom.createAttrMorph(element6, 'class');
          morphs[3] = dom.createMorphAt(element6,0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [9]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element5, [11, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          morphs[7] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","newMode",["loc",[null,[3,8],[3,15]]]]],[],0,null,["loc",[null,[3,2],[5,9]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[12,70],[12,83]]]]],[],1,null,["loc",[null,[12,64],[12,141]]]],
          ["attribute","class",["concat",["table__cell l-3/24 table__cell--center ",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[13,85],[13,112]]]],"","table__cell--got-factor"],[],["loc",[null,[13,64],[13,143]]]]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[13,151],[13,164]]]]],[],2,null,["loc",[null,[13,145],[13,275]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[14,70],[14,83]]]]],[],3,null,["loc",[null,[14,64],[14,138]]]],
          ["inline","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[15,119],[15,146]]]],"Verabreicht","Angewandt"],[],["loc",[null,[15,98],[15,174]]]],
          ["block","each",[["get","offer.entries",["loc",[null,[20,14],[20,27]]]]],[],4,null,["loc",[null,[20,6],[47,15]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[50,8],[50,21]]]]],[],5,null,["loc",[null,[50,2],[61,9]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 6
                },
                "end": {
                  "line": 70,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1,"style","text-align: left !important;");
              var el2 = dom.createTextNode("Anmerkung für Verrechnung");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-24/24@phone l-24/24@tablet l-24/24 full-size");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"autocomplete","false");
              dom.setAttribute(el2,"data-lpignore","true");
              dom.setAttribute(el2,"type","text");
              dom.setAttribute(el2,"name","offerInfo");
              dom.setAttribute(el2,"id","offerInfo");
              dom.setAttribute(el2,"placeholder","");
              dom.setAttribute(el2,"class","input input--editable input--full-width");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element3, 'value');
              morphs[1] = dom.createAttrMorph(element3, 'onblur');
              morphs[2] = dom.createAttrMorph(element3, 'onchange');
              return morphs;
            },
            statements: [
              ["attribute","value",["concat",[["get","invoiceInfo",["loc",[null,[68,127],[68,138]]]]]]],
              ["attribute","onblur",["subexpr","action",["triggerAutosave"],[],["loc",[null,[68,197],[68,225]]]]],
              ["attribute","onchange",["subexpr","action",["dirtyInput"],[],["loc",[null,[68,235],[68,258]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 4
              },
              "end": {
                "line": 71,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","list-not-empty",[["get","offer.entries",["loc",[null,[65,28],[65,41]]]]],[],["loc",[null,[65,12],[65,42]]]]],[],0,null,["loc",[null,[65,6],[70,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 6
                },
                "end": {
                  "line": 85,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","box col l-2/24");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element0,1,1);
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-locked",[],["size","40","offerId",["subexpr","@mut",[["get","offer.id",["loc",[null,[80,44],[80,52]]]]],[],[]],"lockEntries","lockEntries"],["loc",[null,[80,10],[80,80]]]],
              ["element","action",["openCustomerReductions",["get","model.patientOwner.id",["loc",[null,[81,47],[81,68]]]]],[],["loc",[null,[81,13],[81,70]]]],
              ["inline","button-reductions",[],["size","40","showStroke",true],["loc",[null,[82,12],[82,59]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 4
              },
              "end": {
                "line": 86,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","textarea textarea--inline clearfix");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title");
            var el4 = dom.createTextNode("Anmerkung für Verrechnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),3,3);
            morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["get","showLockButtons",["loc",[null,[72,45],[72,60]]]],"18","24"],[],["loc",[null,[72,24],[72,72]]]],"/24"]]],
            ["inline","textarea",[],["rows","3","name","offerInfo","id","offerInfo","value",["subexpr","@mut",[["get","invoiceInfo",["loc",[null,[75,68],[75,79]]]]],[],[]],"class","input textarea__input full","placeholder","","focus-out","triggerAutosave","key-down","dirtyInput"],["loc",[null,[75,10],[75,181]]]],
            ["block","if",[["get","showLockButtons",["loc",[null,[78,12],[78,27]]]]],[],0,null,["loc",[null,[78,6],[85,13]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","newMode",["loc",[null,[64,10],[64,17]]]]],[],0,1,["loc",[null,[64,4],[86,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-now.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","or",[["subexpr","not",[["get","newMode",["loc",[null,[1,15],[1,22]]]]],[],["loc",[null,[1,10],[1,23]]]],["subexpr","and",[["get","newMode",["loc",[null,[1,29],[1,36]]]],["subexpr","list-not-empty",[["get","offer.entries",["loc",[null,[1,53],[1,66]]]]],[],["loc",[null,[1,37],[1,67]]]]],[],["loc",[null,[1,24],[1,68]]]]],[],["loc",[null,[1,6],[1,69]]]]],[],0,null,["loc",[null,[1,0],[62,7]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[63,10],[63,23]]]]],[],1,null,["loc",[null,[63,4],[87,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('jason-frontend/controllers/editor/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Textvorlagen",
    breadCrumbPath: "editor.index",
    sidebarActive: true,
    style: 'style1',
    selectedTab: 'treatment',
    showSettings: true,
    showTextelements: true,
    showShareAnimation: false,
    showTextmodules: true,
    styles: [{ value: "Stil 1", id: "style1" }, { value: "Stil 2", id: "styleinvoice" }, { value: "Leeres Briefpapier", id: "styleempty" }],
    styles10009: [{ value: "Stil 1", id: "style1" }, { value: "Bedrucktes Briefpapier", id: "printed" }, { value: "Leeres Briefpapier", id: "styleempty" }],
    stylesAlternative: [{ value: "Stil 1", id: "style1" }, { value: "Stil 2", id: "style3" }, { value: "Leeres Briefpapier", id: "styleempty" }],
    watchStyle: (function () {
      this.send('styleChanged');
    }).observes('style')
  });

});
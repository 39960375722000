define('jason-frontend/routes/notifications', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 20,
    page: 0,
    queryParams: {},
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      controller.set('showLoadingOverride', true);
      this.selectTab('inbox');

      this.store.find('messageCategory', { paged: false }).then(function (items) {
        controller.set('categories', items.sortBy('name'));
      });

      if (params.queryParams.filterType === 'etiga') {
        setTimeout(function () {
          controller.set('filterCategory', 'etigaall');
        }, 500);
      } else if (params.queryParams.filterType === 'all') {
        setTimeout(function () {
          controller.set('filterCategory', 'all');
        }, 500);
      }

      setTimeout(function () {
        controller.set('showLoadingOverride', false);
      }, 1500);

      controller.set("genders", this.store.find('gender'));
      controller.set('users', this.store.find('user', { per_page: 100, addAll: false }));
    },
    selectTab: function selectTab(selected, pageOverride) {

      var controller = this.get('controller');

      if (!selected) {
        selected = controller.get('selectTab');
      }

      var cat = controller.get('filterCategory');

      if (pageOverride) {
        controller.set('page', pageOverride);
      }

      if (cat === 'all') {
        cat = null;
      }

      controller.set('selectTab', selected);
      if (selected === 'inbox') {
        controller.set('showLoading', true);
        this.findPaged('msg', {
          perPage: 10,
          page: controller.get('page'),
          type: "in",
          category: cat,
          status: controller.get('filterStatus')
        }).then(function (msgs) {
          controller.set('model', msgs);
          controller.set('showLoading', false);
        });
      }
      if (selected === 'unread') {
        controller.set('showLoading', true);
        this.findPaged('msg', {
          perPage: 10,
          page: controller.get('page'),
          type: "unread",
          category: cat,
          status: controller.get('filterStatus')
        }).then(function (msgs) {
          controller.set('model', msgs);
          controller.set('showLoading', false);
        });
      }
      if (selected === 'outbox') {
        controller.set('showLoading', true);

        this.findPaged('msg', {
          perPage: 10,
          page: controller.get('page'),
          type: "out",
          category: cat,
          status: controller.get('filterStatus')
        }).then(function (msgs) {
          controller.set('model', msgs);
          controller.set('showLoading', false);
        });
      }
      if (selected === 'future') {
        controller.set('showLoading', true);

        $.ajax({
          url: "/api/msgs/future",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('futureItems', data.msg);
          controller.set('showLoading', false);
        });
      }
    },
    actions: {
      openGen2Chat: function openGen2Chat() {
        var deferred = $.ajax({
          url: "https://identity.vetnative.com/api/identity/auth/elovetRedirect",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText + '&redirect=chat');
        }, function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText + '&redirect=chat');
        });
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      startAdmission: function startAdmission(patientId, customerId) {

        var self = this;
        var patients = [patientId];

        this.get('controller').get('startTreatmentPanel').send('load', customerId, patients);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      confirmAppRequest: function confirmAppRequest(msg, refData, dateDetails) {
        var self = this;
        var controller = this.get('controller');

        if (dateDetails) {
          refData.dateOverride = dateDetails.date, refData.timeFromOverride = dateDetails.dateFrom, refData.timeToOverride = dateDetails.dateTo;
        }

        $.ajax({
          url: "/api/patientAppointments/" + msg.id + "/appConfirm",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(refData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('eltiga App Termin', 'bestätigt');
        }, function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('eltiga App Termin', 'bestätigt');
        });
      },
      abortAppRequest: function abortAppRequest(msg, refData) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/patientAppointments/" + msg.id + "/appAbort",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(refData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('eltiga App Termin', 'abgesagt');
        }, function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('eltiga App Termin', 'abgesagt');
        });
      },
      addDirectAppNotification: function addDirectAppNotification(data) {

        var controller = this.get('controller'),
            self = this;

        $.ajax({
          url: "/api/treatmentPatients/0/futureDirectNotification",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('APP Benachrichtigung', 'erfolgreich verschickt');
          self.selectTab(controller.get('selectTab'));
        }, function () {
          jason.notifiction.systemError('Fehlercode 121', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      gotoPracticeCart: function gotoPracticeCart() {
        var self = this;
        Ember['default'].$.ajax({
          url: "/api/purchaseOrders/practiceOrder",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('purchases.edit', data, { queryParams: { redirectToListing: "false" } });
        });
      },
      gotoPurchaseProduct: function gotoPurchaseProduct(id) {
        var self = this;

        Ember['default'].$.ajax({
          url: "/api/purchaseOrders/practiceOrder",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data && data > 0) {
            self.transitionTo('purchases.detail', id, data);
          }
        });
      },
      gotoCustomer: function gotoCustomer(id) {
        this.transitionTo('customers.edit', id);
      },
      gotoPatient: function gotoPatient(id) {
        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/gotoId",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.type === 'slave') {
            self.transitionTo('patients.edit', data.id);
          }
          if (data.type === 'master') {
            self.transitionTo('patients.etiga', data.id);
          }
        }, function () {});
      },
      saveTreatment: function saveTreatment(data) {
        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      selectPatientForEtigaActivation: function selectPatientForEtigaActivation(clientId, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + clientId + "/connectToEtigaMaster/" + masterId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        }, function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        });
      },
      startAdmissionFromMessage: function startAdmissionFromMessage(msg) {
        var self = this;
        var data = {
          id: msg.id
        };
        $.ajax({
          url: "/api/treatmentPatients/fromMessage",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Nachricht an Behandlung übergeben', '');
          self.transitionTo('practicemgmt.treatment-details', data);
        });
      },
      startAdmissionFromReorder: function startAdmissionFromReorder(msg) {
        var self = this;
        var data = {
          id: msg.id
        };
        $.ajax({
          url: "/api/treatmentPatients/fromReorder",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Bestellung an Behandlung übergeben', '');
          self.transitionTo('practicemgmt.treatment-details', data);
        });
      },
      selectCustomerForEtigaActivation: function selectCustomerForEtigaActivation(customerId, patientId, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/connectCustomerToEtigaMaster/" + masterId + "/" + customerId + "/" + patientId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        });
      },
      createCustomerForEtigaActivation: function createCustomerForEtigaActivation(customer, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/createCustomerAndConnectToEtigaMaster/" + masterId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(customer),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        });
      },
      linkTo: function linkTo(to, id) {
        this.transitionTo(to, id);
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addTaskPanel').send('loadPatients', data.patient);
        });
      },
      finishTaskSend: function finishTaskSend(taskData) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/tasks/finish",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'erledigt');
          self.selectTab(controller.get('selectTab'));
        });
      },
      changedTaskOwner: function changedTaskOwner(taskData) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/tasks/changedTaskOwner",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'Verantwortliche(n) geändert');
          self.selectTab(controller.get('selectTab'));
        });
      },
      connectTask: function connectTask(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/tasks",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('Aufgabe', 'erstellt und verknüpft');
        });
      },
      markAsRead: function markAsRead(fullMsg) {
        var self = this,
            controller = this.get('controller');
        var msg = {
          id: fullMsg.id
        };

        $.ajax({
          url: "/api/msgs/markAsRead",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(msg),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          Ember['default'].set(fullMsg, 'unread', false);
          Ember['default'].set(fullMsg, 'readOn', new Date());
        });
      },
      markAsReadForAll: function markAsReadForAll(fullMsg) {
        var self = this,
            controller = this.get('controller');
        var msg = {
          id: fullMsg.id
        };

        $.ajax({
          url: "/api/msgs/markAsReadForAll",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(msg),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          Ember['default'].set(fullMsg, 'unread', false);
          Ember['default'].set(fullMsg, 'readOn', new Date());
        });
      },
      selectTab: function selectTab(selected, pageOverride) {
        this.selectTab(selected, pageOverride);
      },
      sendForward: function sendForward(data) {
        var self = this;
        $.ajax({
          url: "/api/msgs/forward",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab('outbox');
          jason.notifiction.notifiy('Nachricht', 'weitergeleitet');
        });
      },
      addReply: function addReply(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/messageReplies",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
          self.selectTab();
        }, function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
          self.selectTab();
        });
      },
      create: function create(data) {
        var self = this;
        $.ajax({
          url: "/api/msgs",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab('outbox');
          jason.notifiction.notifiy('Nachricht', 'verschickt');
        });
      },
      'delete': function _delete(item) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/msgs/" + item.id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          Ember['default'].set(item, 'tmpHide', true);
          jason.notifiction.notifiy('Nachricht', 'gelöscht');
        });
      }
    }
  });

});
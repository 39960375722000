define('jason-frontend/templates/resourcemgmt/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 2
            },
            "end": {
              "line": 73,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticker");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createTextNode("Mitarbeiter:in");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element19, [3]),0,0);
          morphs[1] = dom.createUnsafeMorphAt(dom.childAt(element19, [5]),0,0);
          morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element19, [7]),0,0);
          morphs[3] = dom.createUnsafeMorphAt(dom.childAt(element19, [9]),0,0);
          morphs[4] = dom.createUnsafeMorphAt(dom.childAt(element19, [11]),0,0);
          morphs[5] = dom.createUnsafeMorphAt(dom.childAt(element19, [13]),0,0);
          morphs[6] = dom.createUnsafeMorphAt(dom.childAt(element19, [15]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.dates.0",["loc",[null,[65,62],[65,83]]]],
          ["content","model.dates.1",["loc",[null,[66,62],[66,83]]]],
          ["content","model.dates.2",["loc",[null,[67,62],[67,83]]]],
          ["content","model.dates.3",["loc",[null,[68,62],[68,83]]]],
          ["content","model.dates.4",["loc",[null,[69,62],[69,83]]]],
          ["content","model.dates.5",["loc",[null,[70,62],[70,83]]]],
          ["content","model.dates.6",["loc",[null,[71,63],[71,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 14
              },
              "end": {
                "line": 85,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","tooltipstered symbol symbol-circle symbol-40px");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'title');
            morphs[1] = dom.createAttrMorph(element1, 'style');
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[81,30],[81,52]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","userRow.user.color",["loc",[null,[81,138],[81,156]]]]," !important;"]]],
            ["content","userRow.user.capitals",["loc",[null,[82,44],[82,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 14
              },
              "end": {
                "line": 90,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'title');
            morphs[1] = dom.createAttrMorph(element0, 'class');
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[86,30],[86,52]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","userRow.user.selected",["loc",[null,[86,98],[86,119]]]],"selected",""],[],["loc",[null,[86,77],[86,135]]]]," symbol symbol-circle symbol-40px ",["get","userRow.user.color",["loc",[null,[86,171],[86,189]]]]]]],
            ["content","userRow.user.capitals",["loc",[null,[87,44],[87,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 12
              },
              "end": {
                "line": 93,
                "column": 98
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","block");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","userRow.user.userRole.name",["loc",[null,[93,61],[93,91]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 45
                },
                "end": {
                  "line": 99,
                  "column": 102
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[99,68],[99,80]]]],
              ["content","day.to",["loc",[null,[99,83],[99,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 12
              },
              "end": {
                "line": 99,
                "column": 113
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[99,55],[99,66]]]]],[],0,null,["loc",[null,[99,45],[99,113]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 105,
                  "column": 46
                },
                "end": {
                  "line": 105,
                  "column": 103
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[105,69],[105,81]]]],
              ["content","day.to",["loc",[null,[105,84],[105,94]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 105,
                "column": 12
              },
              "end": {
                "line": 105,
                "column": 114
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[105,56],[105,67]]]]],[],0,null,["loc",[null,[105,46],[105,114]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 48
                },
                "end": {
                  "line": 111,
                  "column": 105
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[111,71],[111,83]]]],
              ["content","day.to",["loc",[null,[111,86],[111,96]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 111,
                "column": 12
              },
              "end": {
                "line": 111,
                "column": 116
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[111,58],[111,69]]]]],[],0,null,["loc",[null,[111,48],[111,116]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 117,
                  "column": 47
                },
                "end": {
                  "line": 117,
                  "column": 104
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[117,70],[117,82]]]],
              ["content","day.to",["loc",[null,[117,85],[117,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 12
              },
              "end": {
                "line": 117,
                "column": 115
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[117,57],[117,68]]]]],[],0,null,["loc",[null,[117,47],[117,115]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 45
                },
                "end": {
                  "line": 123,
                  "column": 102
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[123,68],[123,80]]]],
              ["content","day.to",["loc",[null,[123,83],[123,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 12
              },
              "end": {
                "line": 123,
                "column": 113
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[123,55],[123,66]]]]],[],0,null,["loc",[null,[123,45],[123,113]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 129,
                  "column": 47
                },
                "end": {
                  "line": 129,
                  "column": 104
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[129,70],[129,82]]]],
              ["content","day.to",["loc",[null,[129,85],[129,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 12
              },
              "end": {
                "line": 129,
                "column": 115
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[129,57],[129,68]]]]],[],0,null,["loc",[null,[129,47],[129,115]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 135,
                  "column": 45
                },
                "end": {
                  "line": 135,
                  "column": 102
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","day.from",["loc",[null,[135,68],[135,80]]]],
              ["content","day.to",["loc",[null,[135,83],[135,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 135,
                "column": 12
              },
              "end": {
                "line": 135,
                "column": 113
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","day.deleted",["loc",[null,[135,55],[135,66]]]]],[],0,null,["loc",[null,[135,45],[135,113]]]]
          ],
          locals: ["day"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 8
            },
            "end": {
              "line": 143,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          dom.setAttribute(el2,"data-label","Mitarbeiter:in");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","symbol-group symbol-hover block mb0");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Montag");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Dienstag");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Mittwoch");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Donnerstag");
          var el3 = dom.createTextNode("\n             ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class"," block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Freitag");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Samstag");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
          dom.setAttribute(el2,"data-label","Sonntag");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell--right table__cell l-2/24 table__cell--action");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default icon-button--success list-action-square exp");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/copy-white.svg");
          dom.setAttribute(el4,"title","Wochenplanung kopieren");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element2, [5]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element2, [7]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element2, [9]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element2, [11]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(element2, [13]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element2, [15]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element2, [17, 1]);
          var morphs = new Array(27);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
          morphs[2] = dom.createMorphAt(element3,3,3);
          morphs[3] = dom.createMorphAt(element3,5,5);
          morphs[4] = dom.createMorphAt(element3,7,7);
          morphs[5] = dom.createElementMorph(element5);
          morphs[6] = dom.createMorphAt(element5,1,1);
          morphs[7] = dom.createMorphAt(element4,3,3);
          morphs[8] = dom.createElementMorph(element7);
          morphs[9] = dom.createMorphAt(element7,1,1);
          morphs[10] = dom.createMorphAt(element6,3,3);
          morphs[11] = dom.createElementMorph(element9);
          morphs[12] = dom.createMorphAt(element9,1,1);
          morphs[13] = dom.createMorphAt(element8,3,3);
          morphs[14] = dom.createElementMorph(element11);
          morphs[15] = dom.createMorphAt(element11,1,1);
          morphs[16] = dom.createMorphAt(element10,3,3);
          morphs[17] = dom.createElementMorph(element13);
          morphs[18] = dom.createMorphAt(element13,1,1);
          morphs[19] = dom.createMorphAt(element12,3,3);
          morphs[20] = dom.createElementMorph(element15);
          morphs[21] = dom.createMorphAt(element15,1,1);
          morphs[22] = dom.createMorphAt(element14,3,3);
          morphs[23] = dom.createElementMorph(element17);
          morphs[24] = dom.createMorphAt(element17,1,1);
          morphs[25] = dom.createMorphAt(element16,3,3);
          morphs[26] = dom.createElementMorph(element18);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","userRow.user.userRole",["loc",[null,[77,52],[77,73]]]],["get","userRow.user.userRole.id",["loc",[null,[77,74],[77,98]]]],""],[],["loc",[null,[77,31],[77,103]]]]]]],
          ["block","if",[["get","userRow.user.colorHex",["loc",[null,[80,20],[80,41]]]]],[],0,1,["loc",[null,[80,14],[90,21]]]],
          ["content","userRow.user.firstname",["loc",[null,[92,12],[92,38]]]],
          ["content","userRow.user.lastname",["loc",[null,[92,39],[92,64]]]],
          ["block","if",[["get","userRow.user.userRole",["loc",[null,[93,18],[93,39]]]]],[],2,null,["loc",[null,[93,12],[93,105]]]],
          ["element","action",["openEdit",["get","userRow.monday",["loc",[null,[96,134],[96,148]]]]],[],["loc",[null,[96,114],[96,150]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[97,14],[97,80]]]],
          ["block","each",[["get","userRow.monday",["loc",[null,[99,20],[99,34]]]]],[],3,null,["loc",[null,[99,12],[99,122]]]],
          ["element","action",["openEdit",["get","userRow.tuesday",["loc",[null,[102,134],[102,149]]]]],[],["loc",[null,[102,114],[102,151]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[103,14],[103,80]]]],
          ["block","each",[["get","userRow.tuesday",["loc",[null,[105,20],[105,35]]]]],[],4,null,["loc",[null,[105,12],[105,123]]]],
          ["element","action",["openEdit",["get","userRow.wednesday",["loc",[null,[108,134],[108,151]]]]],[],["loc",[null,[108,114],[108,153]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[109,14],[109,80]]]],
          ["block","each",[["get","userRow.wednesday",["loc",[null,[111,20],[111,37]]]]],[],5,null,["loc",[null,[111,12],[111,125]]]],
          ["element","action",["openEdit",["get","userRow.thursday",["loc",[null,[114,136],[114,152]]]]],[],["loc",[null,[114,116],[114,154]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[115,14],[115,80]]]],
          ["block","each",[["get","userRow.thursday",["loc",[null,[117,20],[117,36]]]]],[],6,null,["loc",[null,[117,12],[117,124]]]],
          ["element","action",["openEdit",["get","userRow.friday",["loc",[null,[120,134],[120,148]]]]],[],["loc",[null,[120,114],[120,150]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[121,14],[121,80]]]],
          ["block","each",[["get","userRow.friday",["loc",[null,[123,20],[123,34]]]]],[],7,null,["loc",[null,[123,12],[123,122]]]],
          ["element","action",["openEdit",["get","userRow.saturday",["loc",[null,[126,136],[126,152]]]]],[],["loc",[null,[126,116],[126,154]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[127,14],[127,80]]]],
          ["block","each",[["get","userRow.saturday",["loc",[null,[129,20],[129,36]]]]],[],8,null,["loc",[null,[129,12],[129,124]]]],
          ["element","action",["openEdit",["get","userRow.sunday",["loc",[null,[132,134],[132,148]]]]],[],["loc",[null,[132,114],[132,150]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[133,14],[133,80]]]],
          ["block","each",[["get","userRow.sunday",["loc",[null,[135,20],[135,34]]]]],[],9,null,["loc",[null,[135,12],[135,122]]]],
          ["element","action",["openCopyPanel",["get","userRow.user",["loc",[null,[138,45],[138,57]]]]],[],["loc",[null,[138,20],[138,60]]]]
        ],
        locals: ["userRow"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 151,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3,"class","d-flex align-items-center  mb0 ml20");
        dom.setAttribute(el3,"style","margin-top: -4px;");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mr20");
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("Dienstplan für KW");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","ml10 mr10");
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("\n      und\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-12/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/copy-white.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Wochenplanung auf andere KW übertragen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card with-context-bar");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box u-show@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","u-mb0");
        var el5 = dom.createTextNode("Ressourcenplanung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table box no-box@phone grid table--large");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0]);
        var element21 = dom.childAt(element20, [1, 1]);
        var element22 = dom.childAt(element20, [3, 1]);
        var element23 = dom.childAt(fragment, [2, 3, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element21, [2]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element21, [4]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element21, [8]),1,1);
        morphs[3] = dom.createElementMorph(element22);
        morphs[4] = dom.createMorphAt(dom.childAt(element23, [1]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element23, [3]),1,1);
        morphs[6] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","weeks",["loc",[null,[6,18],[6,23]]]]],[],[]],"value",["subexpr","@mut",[["get","weekNumber",["loc",[null,[7,16],[7,26]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown"],["loc",[null,[5,8],[13,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","years",["loc",[null,[17,18],[17,23]]]]],[],[]],"value",["subexpr","@mut",[["get","year",["loc",[null,[18,16],[18,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown"],["loc",[null,[16,8],[24,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","userRoles",["loc",[null,[32,18],[32,27]]]]],[],[]],"value",["subexpr","@mut",[["get","role",["loc",[null,[33,16],[33,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",true,"placeholder","Alle Rollen","cssClass","input--dropdown"],["loc",[null,[31,8],[40,10]]]],
        ["element","action",["openCopyPanel"],[],["loc",[null,[45,7],[45,34]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[62,30],[62,54]]]]],[],[]]],0,null,["loc",[null,[62,2],[73,23]]]],
        ["block","each",[["get","model.users",["loc",[null,[76,16],[76,27]]]]],[],1,null,["loc",[null,[76,8],[143,17]]]],
        ["inline","edit-resource-day-panel",[],["actionReceiver",["subexpr","@mut",[["get","editResourceDayPanel",["loc",[null,[149,41],[149,61]]]]],[],[]],"save","save"],["loc",[null,[149,0],[149,75]]]],
        ["inline","copy-resource-panel",[],["actionReceiver",["subexpr","@mut",[["get","copyResourcePanel",["loc",[null,[150,37],[150,54]]]]],[],[]]],["loc",[null,[150,0],[150,56]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
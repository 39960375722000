define('jason-frontend/components/add-task-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    type: null,
    reloadPatients: function reloadPatients(id) {
      this.sendAction('reloadPatients', id);
    },
    actions: {
      loadPatients: function loadPatients(patients) {
        this.set('patients', patients);
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      reloadPatients: function reloadPatients(id) {
        this.reloadPatients(id);
      },
      load: function load(data) {
        this.set('taskTitle', data.taskTitle);
        this.set('taskComment', data.taskComment);

        if (data.customerId) {
          this.set('customer', this.get('store').find('customer', data.customerId));
          this.set('customerOverride', data.customerId);
        }
        if (data.patientId) {
          this.set('patient', this.get('store').find('patient', data.patientId));
          this.set('patientOverride', data.patientId);
        }

        if (data.taskResponsible) {
          this.set('user', data.taskResponsible);
        }
      },
      loadCalenderEdit: function loadCalenderEdit(data) {
        console.log(data);
        this.set('taskTitle', data.title);
        this.set('taskComment', data.notice);
        this.set('taskId', data.id);

        if (data.due) {
          this.set('taskDue', moment(data.due).format('DD.MM.YYYY'));
          this.set('taskDueTime', moment(data.due).format('HH:mm'));
        }

        if (data.user) {
          this.set('user', data.user);
        }
      },
      confirm: function confirm(id) {
        var taskData = {
          title: this.get('taskTitle'),
          notice: this.get('taskComment'),
          id: this.get('taskId')
        };

        if (this.get('msgId')) {
          taskData.msgReference = this.get('msgId').id;
        }

        var time = this.get('taskDueTime');
        if (!time) {
          time = moment().format('HH:mm');
        }

        taskData.dueInput = moment(this.get('taskDue'), 'DD. MM. YYYY').format('YYYY-MM-DD') + ' ' + time;

        if (this.get('user')) {
          var username = '';
          try {
            username = this.get('user').get('username');
          } catch (e) {
            username = this.get('user').username;
          }

          taskData.user = {
            id: this.get('user').id,
            username: username
          };
        }
        if (this.get('customer')) {
          taskData.customer = {
            id: this.get('customer').id
          };
        }
        if (this.get('patient')) {
          taskData.patient = {
            id: this.get('patient').id
          };
        }

        if (this.get('customerOverride')) {
          taskData.customer = {
            id: this.get('customerOverride')
          };
        }
        if (this.get('patientOverride')) {
          taskData.patient = {
            id: this.get('patientOverride')
          };
        }

        if (!taskData.user) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie einen Verantwortlichen für die Aufgabe');
          return false;
        }

        this.sendAction('create', taskData);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset() {
        this.set('msgId', null);
        this.set('taskTitle', null);
        this.set('user', null);
        this.set('taskComment', null);
        this.set('taskNotice', null);
        this.set('patients', null);
        this.set('taskDueTime', moment().format("HH:mm"));
        this.set('taskDue', moment().format('DD. MM. YYYY'));
      },
      setMsgReference: function setMsgReference(msg) {
        this.set('msgId', msg);
        this.set('patient', msg.get('patient'));
        this.set('customer', msg.get('customer'));
      },
      setPatient: function setPatient(patient, customer) {
        this.set('patient', patient);
        this.set('customer', customer);
      }
    },
    watchCustomer: (function () {
      if (this.get('customer') && this.get('customer').id) {
        this.sendAction('reloadPatients', this.get('customer').id);
      } else {
        this.set('patient', null);
        this.set('patients', null);
      }
    }).observes('customer')
  });

});
define('jason-frontend/templates/components/treatment-patient-list', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 22
                },
                "end": {
                  "line": 22,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub");
              dom.setAttribute(el1,"style","display: inline");
              var el2 = dom.createTextNode("Termin: ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"for","time");
              dom.setAttribute(el1,"class","input-element");
              var el2 = dom.createTextNode("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"style","font-size: 15px");
              dom.setAttribute(el2,"autocomplete","false");
              dom.setAttribute(el2,"type","text");
              dom.setAttribute(el2,"placeholder","");
              dom.setAttribute(el2,"class","input date-picker__input time time-small");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element15, 'id');
              morphs[1] = dom.createAttrMorph(element15, 'value');
              morphs[2] = dom.createAttrMorph(element15, 'onclick');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["time",["get","treatment.id",["loc",[null,[20,99],[20,111]]]]]]],
              ["attribute","value",["concat",[["get","treatment.time",["loc",[null,[20,139],[20,153]]]]]]],
              ["attribute","onclick",["subexpr","action",["openTime",["get","treatment",["loc",[null,[20,234],[20,243]]]]],[],["loc",[null,[20,214],[20,245]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 69
                },
                "end": {
                  "line": 26,
                  "column": 181
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.firstname",["loc",[null,[26,117],[26,149]]]],
              ["content","treatment.customer.lastname",["loc",[null,[26,150],[26,181]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 194
                },
                "end": {
                  "line": 26,
                  "column": 355
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-ical",[],["size",32,"classNames","ml5 inline-block vertical-bottom","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[26,238],[26,355]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 355
                  },
                  "end": {
                    "line": 26,
                    "column": 530
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-ical",[],["size",32,"classNames","ml5 inline-block vertical-bottom","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[26,404],[26,530]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 355
                },
                "end": {
                  "line": 26,
                  "column": 530
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","treatment.customer.etigaCodeRequested",["loc",[null,[26,365],[26,402]]]]],[],0,null,["loc",[null,[26,355],[26,530]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 584
                },
                "end": {
                  "line": 26,
                  "column": 647
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.street",["loc",[null,[26,617],[26,646]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 24
                },
                "end": {
                  "line": 29,
                  "column": 87
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.street",["loc",[null,[29,57],[29,86]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 24
                    },
                    "end": {
                      "line": 36,
                      "column": 248
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[36,107],[36,138]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[36,147],[36,183]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[36,192],[36,225]]]]],[],[]],"classNames","mr5"],["loc",[null,[36,83],[36,244]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 24
                    },
                    "end": {
                      "line": 37,
                      "column": 151
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[37,94],[37,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[37,127],[37,149]]]]],[],[]]],["loc",[null,[37,75],[37,151]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 38,
                      "column": 24
                    },
                    "end": {
                      "line": 38,
                      "column": 193
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","20","width",60,"color","00AAC6","classNames","ml10","connected",true],["loc",[null,[38,63],[38,193]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 43,
                      "column": 20
                    },
                    "end": {
                      "line": 43,
                      "column": 130
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","block mt5 mb5");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.station.name",["loc",[null,[43,94],[43,122]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child4 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 22
                    },
                    "end": {
                      "line": 44,
                      "column": 171
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","block");
                  var el2 = dom.createElement("small");
                  var el3 = dom.createTextNode("seit ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","get-formatted-timespan",[["get","patientInfo.lastStateChange",["loc",[null,[44,127],[44,154]]]]],[],["loc",[null,[44,102],[44,156]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child5 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 22
                    },
                    "end": {
                      "line": 50,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","patientInfo.totalOfferSum",["loc",[null,[49,48],[49,73]]]]],[],[]]],["loc",[null,[49,24],[49,75]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child6 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 22
                    },
                    "end": {
                      "line": 58,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Abbrechen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","patientInfo",["loc",[null,[56,104],[56,115]]]]],[],[]],"confirmed","abort","content","Behandlung abbrechen","text","Wollen Sie die Behandlung dieses Patienten wirklich abbrechen?"],["loc",[null,[56,26],[56,236]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child7 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 22
                    },
                    "end": {
                      "line": 63,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Behandeln");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element9);
                  morphs[1] = dom.createMorphAt(element9,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[60,129],[60,143]]]]],[],["loc",[null,[60,91],[60,145]]]],
                  ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[61,26],[61,135]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child8 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 24
                      },
                      "end": {
                        "line": 69,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createTextNode("Kassieren");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-ready4payment",[],["size","40","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[67,28],[67,131]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 69,
                        "column": 24
                      },
                      "end": {
                        "line": 73,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createTextNode("Kassieren");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element8);
                    morphs[1] = dom.createMorphAt(element8,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["exportInvoice",["get","patientInfo.id",["loc",[null,[70,118],[70,132]]]],["get","patientInfo.info.invoiceId",["loc",[null,[70,133],[70,159]]]]],[],["loc",[null,[70,93],[70,161]]]],
                    ["inline","button-ready4payment",[],["size","40","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[71,28],[71,131]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 22
                    },
                    "end": {
                      "line": 74,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","inProgress",["loc",[null,[65,30],[65,40]]]]],[],0,1,["loc",[null,[65,24],[73,31]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child9 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 76,
                        "column": 24
                      },
                      "end": {
                        "line": 78,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createTextNode("Behandlungsdetails");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","Behandlungsdetails"],["loc",[null,[77,26],[77,118]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 22
                    },
                    "end": {
                      "line": 79,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[76,68],[76,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[76,83],[76,110]]]]],["class","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[76,24],[78,36]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child10 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 22
                    },
                    "end": {
                      "line": 84,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Aufnehmen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element7);
                  morphs[1] = dom.createMorphAt(element7,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["movePatientToWaitingRoom",["get","patientInfo.id",["loc",[null,[81,127],[81,141]]]]],[],["loc",[null,[81,91],[81,143]]]],
                  ["inline","button-waiting-room",[],["size","40","color","#fff","showStroke",false],["loc",[null,[82,26],[82,89]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child11 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 85,
                      "column": 22
                    },
                    "end": {
                      "line": 89,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Auf Station legen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element6);
                  morphs[1] = dom.createMorphAt(element6,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["openStationPanel",["get","patientInfo.id",["loc",[null,[86,119],[86,133]]]]],[],["loc",[null,[86,91],[86,135]]]],
                  ["inline","button-station",[],["size","40","color","#fff","showStroke",false,"content","Auf Station legen"],["loc",[null,[87,26],[87,112]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child12 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 95,
                      "column": 24
                    },
                    "end": {
                      "line": 96,
                      "column": 104
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","medium block mt10");
                  var el2 = dom.createTextNode("Diagnose: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.info.diagnose",["loc",[null,[96,68],[96,97]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child13 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 97,
                      "column": 24
                    },
                    "end": {
                      "line": 98,
                      "column": 112
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","medium block mt10");
                  var el2 = dom.createTextNode("Behandlungs-Lokation: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.room.name",["loc",[null,[98,80],[98,105]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child14 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 103,
                        "column": 32
                      },
                      "end": {
                        "line": 108,
                        "column": 32
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element4, 'title');
                    morphs[1] = dom.createAttrMorph(element4, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[104,48],[104,76]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[104,166],[104,190]]]]," !important;"]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[105,62],[105,93]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 32
                      },
                      "end": {
                        "line": 113,
                        "column": 32
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element3, 'title');
                    morphs[1] = dom.createAttrMorph(element3, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[109,48],[109,76]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[109,126],[109,153]]]],"selected",""],[],["loc",[null,[109,105],[109,169]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[109,205],[109,229]]]]]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[110,62],[110,93]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 99,
                      "column": 24
                    },
                    "end": {
                      "line": 117,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","clickable align-items-center d-flex mt5 mb5");
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","medium");
                  var el3 = dom.createTextNode("Tierärzt:in:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-group");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                               ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","text--editable");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element5);
                  morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[100,112],[100,123]]]]],[],["loc",[null,[100,83],[100,125]]]],
                  ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[103,38],[103,65]]]]],[],0,1,["loc",[null,[103,32],[113,39]]]],
                  ["content","patientInfo.doctor.shortName",["loc",[null,[115,60],[115,92]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child15 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 123,
                        "column": 28
                      },
                      "end": {
                        "line": 128,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element1, 'title');
                    morphs[1] = dom.createAttrMorph(element1, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[124,44],[124,75]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[124,165],[124,192]]]]," !important;"]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[125,58],[125,92]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 128,
                        "column": 28
                      },
                      "end": {
                        "line": 133,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element0, 'title');
                    morphs[1] = dom.createAttrMorph(element0, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[129,44],[129,75]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[129,125],[129,155]]]],"selected",""],[],["loc",[null,[129,104],[129,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[129,207],[129,234]]]]]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[130,58],[130,92]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 118,
                      "column": 24
                    },
                    "end": {
                      "line": 137,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","clickable align-items-center d-flex mt5 mb5");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","medium");
                  var el3 = dom.createTextNode("Assistent:in: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-group");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","text--editable");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[119,110],[119,121]]]]],[],["loc",[null,[119,81],[119,123]]]],
                  ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[123,34],[123,64]]]]],[],0,1,["loc",[null,[123,28],[133,35]]]],
                  ["content","patientInfo.assistant.shortName",["loc",[null,[135,55],[135,90]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 18
                  },
                  "end": {
                    "line": 139,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell table__cell--name verticaltop l-9/24");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","flex-center");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell l-5/24 treatment-status table__cell--center");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"class","info-badge");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell table__cell--name l-3/24 table__cell--right");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell  table__cell--action l-7/24 buttons-4 pr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell l-24/24 pt0");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","medium block");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","clickable");
                var el4 = dom.createTextNode("Anamnese: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text--editable");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1, 1]);
                var element11 = dom.childAt(fragment, [3]);
                var element12 = dom.childAt(fragment, [7]);
                var element13 = dom.childAt(fragment, [9]);
                var element14 = dom.childAt(element13, [1, 1]);
                var morphs = new Array(19);
                morphs[0] = dom.createMorphAt(element10,1,1);
                morphs[1] = dom.createMorphAt(element10,3,3);
                morphs[2] = dom.createMorphAt(element10,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                morphs[4] = dom.createMorphAt(element11,3,3);
                morphs[5] = dom.createMorphAt(element11,5,5);
                morphs[6] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),1,1);
                morphs[7] = dom.createMorphAt(element12,1,1);
                morphs[8] = dom.createMorphAt(element12,2,2);
                morphs[9] = dom.createMorphAt(element12,3,3);
                morphs[10] = dom.createMorphAt(element12,4,4);
                morphs[11] = dom.createMorphAt(element12,5,5);
                morphs[12] = dom.createMorphAt(element12,6,6);
                morphs[13] = dom.createElementMorph(element14);
                morphs[14] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
                morphs[15] = dom.createMorphAt(element13,3,3);
                morphs[16] = dom.createMorphAt(element13,5,5);
                morphs[17] = dom.createMorphAt(element13,7,7);
                morphs[18] = dom.createMorphAt(element13,8,8);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[36,30],[36,58]]]]],[],0,null,["loc",[null,[36,24],[36,255]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[37,51],[37,73]]]]],[],1,null,["loc",[null,[37,24],[37,163]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[38,30],[38,61]]]]],[],2,null,["loc",[null,[38,24],[38,200]]]],
                ["content","patientInfo.state.name",["loc",[null,[42,48],[42,74]]]],
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[43,30],[43,50]]]],"in_station"],[],["loc",[null,[43,26],[43,64]]]]],[],3,null,["loc",[null,[43,20],[43,137]]]],
                ["block","if",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[44,37],[44,57]]]],"closed"],[],["loc",[null,[44,33],[44,67]]]]],[],["loc",[null,[44,28],[44,68]]]]],[],4,null,["loc",[null,[44,22],[44,178]]]],
                ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[48,28],[48,41]]]]],[],5,null,["loc",[null,[48,22],[50,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[54,42],[54,62]]]],"closed"],[],["loc",[null,[54,38],[54,72]]]]],[],["loc",[null,[54,33],[54,73]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[54,83],[54,103]]]],"ready_for_payment"],[],["loc",[null,[54,79],[54,124]]]]],[],["loc",[null,[54,74],[54,125]]]]],[],["loc",[null,[54,28],[54,126]]]]],[],6,null,["loc",[null,[54,22],[58,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[59,42],[59,62]]]],"in_treatment"],[],["loc",[null,[59,38],[59,78]]]]],[],["loc",[null,[59,33],[59,79]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[59,89],[59,109]]]],"closed"],[],["loc",[null,[59,85],[59,119]]]]],[],["loc",[null,[59,80],[59,120]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[59,130],[59,150]]]],"ready_for_payment"],[],["loc",[null,[59,126],[59,171]]]]],[],["loc",[null,[59,121],[59,172]]]]],[],["loc",[null,[59,28],[59,173]]]]],[],7,null,["loc",[null,[59,22],[63,29]]]],
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[64,32],[64,52]]]],"ready_for_payment"],[],["loc",[null,[64,28],[64,73]]]]],[],8,null,["loc",[null,[64,22],[74,29]]]],
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[75,36],[75,56]]]],"ready_for_payment"],[],["loc",[null,[75,32],[75,77]]]],["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[75,82],[75,102]]]],"in_treatment"],[],["loc",[null,[75,78],[75,118]]]]],[],["loc",[null,[75,28],[75,119]]]]],[],9,null,["loc",[null,[75,22],[79,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[80,42],[80,62]]]],"in_waitingroom"],[],["loc",[null,[80,38],[80,80]]]]],[],["loc",[null,[80,33],[80,81]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[80,91],[80,111]]]],"closed"],[],["loc",[null,[80,87],[80,121]]]]],[],["loc",[null,[80,82],[80,122]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[80,132],[80,152]]]],"ready_for_payment"],[],["loc",[null,[80,128],[80,173]]]]],[],["loc",[null,[80,123],[80,174]]]]],[],["loc",[null,[80,28],[80,175]]]]],[],10,null,["loc",[null,[80,22],[84,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[85,42],[85,62]]]],"in_station"],[],["loc",[null,[85,38],[85,76]]]]],[],["loc",[null,[85,33],[85,77]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[85,87],[85,107]]]],"closed"],[],["loc",[null,[85,83],[85,117]]]]],[],["loc",[null,[85,78],[85,118]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[85,128],[85,148]]]],"ready_for_payment"],[],["loc",[null,[85,124],[85,169]]]]],[],["loc",[null,[85,119],[85,170]]]]],[],["loc",[null,[85,28],[85,171]]]]],[],11,null,["loc",[null,[85,22],[89,29]]]],
                ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[92,82],[92,93]]]]],[],["loc",[null,[92,53],[92,95]]]],
                ["content","patientInfo.info.medicalHistory",["loc",[null,[94,47],[94,82]]]],
                ["block","if",[["get","patientInfo.info.diagnose",["loc",[null,[95,30],[95,55]]]]],[],12,null,["loc",[null,[95,24],[96,111]]]],
                ["block","if",[["get","patientInfo.room",["loc",[null,[97,30],[97,46]]]]],[],13,null,["loc",[null,[97,24],[98,119]]]],
                ["block","if",[["get","patientInfo.doctor",["loc",[null,[99,30],[99,48]]]]],[],14,null,["loc",[null,[99,24],[117,31]]]],
                ["block","if",[["get","patientInfo.assistant",["loc",[null,[118,30],[118,51]]]]],[],15,null,["loc",[null,[118,24],[137,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 16
                },
                "end": {
                  "line": 140,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-patient",[["get","patientInfo.state.id",["loc",[null,[33,48],[33,68]]]],["get","showAll",["loc",[null,[33,69],[33,76]]]],["get","showAdmission",["loc",[null,[33,77],[33,90]]]],["get","showWaiting",["loc",[null,[33,91],[33,102]]]],["get","showTreatment",["loc",[null,[33,103],[33,116]]]],["get","showPayment",["loc",[null,[33,117],[33,128]]]],["get","showStation",["loc",[null,[33,129],[33,140]]]],["get","showStationOverride",["loc",[null,[33,141],[33,160]]]],["get","filteredStations",["loc",[null,[33,161],[33,177]]]],["get","patientInfo",["loc",[null,[33,178],[33,189]]]],["get","selectedUsers",["loc",[null,[33,190],[33,203]]]]],[],["loc",[null,[33,24],[33,204]]]]],[],0,null,["loc",[null,[33,18],[139,25]]]]
            ],
            locals: ["patientInfo"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 12
              },
              "end": {
                "line": 143,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--name table__cell--center l-8/24@tablet l-3/24@desk verticaltop");
            dom.setAttribute(el2,"data-label","Aufgenommen");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"style","display: block;margin-top: 6px;margin-bottom: 3px;");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Uhr");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block mt20");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@tablet table__cell--name  l-4/24@desk verticaltop");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","flex-center");
            dom.setAttribute(el3,"style","line-height:32px");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block small u-hide@tablet");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-8/24@tablet l-6/24@desk u-hide@desk");
            dom.setAttribute(el2,"data-label","Adresse");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-24/24@tablet pr0 l-24/24@phone l-17/24@desk patient-treatments");
            dom.setAttribute(el2,"data-label","Tiere");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element16, [3]);
            var element19 = dom.childAt(element18, [1]);
            var element20 = dom.childAt(element18, [2]);
            var element21 = dom.childAt(element16, [5, 1]);
            var element22 = dom.childAt(element16, [7]);
            var morphs = new Array(14);
            morphs[0] = dom.createAttrMorph(element17, 'data-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element17, [5]),1,1);
            morphs[4] = dom.createMorphAt(element19,0,0);
            morphs[5] = dom.createMorphAt(element19,2,2);
            morphs[6] = dom.createMorphAt(element20,0,0);
            morphs[7] = dom.createMorphAt(element20,2,2);
            morphs[8] = dom.createMorphAt(element20,4,4);
            morphs[9] = dom.createMorphAt(element21,0,0);
            morphs[10] = dom.createMorphAt(element21,2,2);
            morphs[11] = dom.createMorphAt(element21,4,4);
            morphs[12] = dom.createAttrMorph(element22, 'id');
            morphs[13] = dom.createMorphAt(element22,1,1);
            return morphs;
          },
          statements: [
            ["attribute","data-id",["concat",[["get","treatment.id",["loc",[null,[13,138],[13,150]]]]]]],
            ["inline","time-format",[["get","treatment.created",["loc",[null,[14,101],[14,118]]]],"DD. MM. YYYY"],[],["loc",[null,[14,87],[14,135]]]],
            ["inline","time-format",[["get","treatment.created",["loc",[null,[15,54],[15,71]]]],"HH:mm"],[],["loc",[null,[15,40],[15,81]]]],
            ["block","if",[["get","treatment.time",["loc",[null,[17,28],[17,42]]]]],[],0,null,["loc",[null,[17,22],[22,29]]]],
            ["block","link-to",["customers.edit",["get","treatment.customer",["loc",[null,[26,97],[26,115]]]]],[],1,null,["loc",[null,[26,69],[26,193]]]],
            ["block","if",[["get","treatment.customer.etigaAppConnected",["loc",[null,[26,200],[26,236]]]]],[],2,3,["loc",[null,[26,194],[26,537]]]],
            ["block","if",[["get","treatment.customer.street",["loc",[null,[26,590],[26,615]]]]],[],4,null,["loc",[null,[26,584],[26,654]]]],
            ["content","treatment.customer.postalCode",["loc",[null,[26,655],[26,688]]]],
            ["content","treatment.customer.town",["loc",[null,[26,689],[26,716]]]],
            ["block","if",[["get","treatment.customer.street",["loc",[null,[29,30],[29,55]]]]],[],5,null,["loc",[null,[29,24],[29,94]]]],
            ["content","treatment.customer.postalCode",["loc",[null,[29,95],[29,128]]]],
            ["content","treatment.customer.town",["loc",[null,[29,129],[29,156]]]],
            ["attribute","id",["concat",["patientsForCustomer",["get","treatment.customer.id",["loc",[null,[31,158],[31,179]]]]]]],
            ["block","each",[["get","treatment.treatmentPatients",["loc",[null,[32,24],[32,51]]]]],[],6,null,["loc",[null,[32,16],[140,25]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 10
            },
            "end": {
              "line": 144,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","show-treatment",[["get","treatment",["loc",[null,[11,34],[11,43]]]],["get","showAll",["loc",[null,[11,44],[11,51]]]],["get","showAdmission",["loc",[null,[11,52],[11,65]]]],["get","showWaiting",["loc",[null,[11,66],[11,77]]]],["get","showTreatment",["loc",[null,[11,78],[11,91]]]],["get","showPayment",["loc",[null,[11,92],[11,103]]]],["get","showStation",["loc",[null,[11,104],[11,115]]]],["get","showStationOverride",["loc",[null,[11,116],[11,135]]]],["get","filteredStations",["loc",[null,[11,136],[11,152]]]],["get","selectedUsers",["loc",[null,[11,153],[11,166]]]]],[],["loc",[null,[11,18],[11,167]]]]],[],0,null,["loc",[null,[11,12],[143,19]]]]
        ],
        locals: ["treatment"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 147,
              "column": 2
            },
            "end": {
              "line": 151,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[149,29],[149,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[149,6],[149,55]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 156,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","treatment-patient-list");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","grid table table--large box no-box@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-4/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Kund:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-10/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Details");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element23 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element23, [1, 3]),1,1);
        morphs[1] = dom.createMorphAt(element23,3,3);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["block","each",[["get","model",["loc",[null,[10,18],[10,23]]]]],[],0,null,["loc",[null,[10,10],[144,19]]]],
        ["block","if",[["subexpr","and",[["get","model",["loc",[null,[147,13],[147,18]]]],["get","showPager",["loc",[null,[147,19],[147,28]]]]],[],["loc",[null,[147,8],[147,29]]]]],[],1,null,["loc",[null,[147,2],[151,9]]]],
        ["inline","select-station-panel",[],["movePatientToStation","movePatientToStation","actionReceiver",["subexpr","@mut",[["get","setStationPanel",["loc",[null,[155,82],[155,97]]]]],[],[]]],["loc",[null,[155,0],[155,99]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('jason-frontend/components/start-treatment-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    doctor: null,
    type: null,
    confirmRoot: function confirmRoot(directTreatment, customerId, selectedPatientIds) {
      var patientTreatmentData = [],
          error = false;
      $.each(selectedPatientIds, function (index, value) {

        var weight = $('#' + value + '_weight').find('input').val();

        if (weight) {
          weight = weight.replace(',', '.');
        }

        if (weight && isNaN(weight)) {
          error = true;
        }

        var data = {
          patientId: value,
          medicalHistory: $('#' + value + '_anamnese').find('textarea').val(),
          weight: weight,
          introductionReasons: $('#' + value + '_introReasons').attr('data-ids'),
          adminTasks: $('#' + value + '_adminTasks').attr('data-ids'),
          treatmentInfo: $('#' + value + '_treatmentInfo').find('textarea').val(),
          room: $('#' + value + '_room').find('input.input-md').val(),
          wishDoctor: $('#' + value + '_wishDoctor').find('input.input-md').val(),
          wishAssistant: $('#' + value + '_wishAssistant').find('input.input-md').val(),
          putDirectlyInTreatment: directTreatment
        };
        patientTreatmentData.push(data);
      });

      if (error) {
        jason.notifiction.error('Eingabe überprüfen', '');
        return;
      }

      var appointment = true;
      var time = $('#time').val();

      this.sendAction('confirm', customerId, patientTreatmentData, appointment, time, directTreatment, this.get('app'));
      $.magnificPopup.close();
    },
    actions: {
      updateSelects: function updateSelects(values, patient, provider) {
        Ember['default'].set(patient, provider, values);

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-start-treatment-panel'
            },
            callbacks: {
              close: function close() {}
            }
          });
        }, 1000);
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, selected, title, reference) {

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanel').send('loadMultiselect', data.treatmentLookup, selected, title, reference, provider);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      changePatient: function changePatient(invoiceId, patientId, add) {
        this.sendAction('changePatient', invoiceId, patientId, add);
      },
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      gotoCustomer: function gotoCustomer(id) {
        $.magnificPopup.close();
        this.sendAction('gotoCustomer', id);
      },
      gotoPatient: function gotoPatient(id) {
        $.magnificPopup.close();
        this.sendAction('gotoPatient', id);
      },
      confirmHelper: function confirmHelper() {
        this.confirmRoot(true, this.get('customerId'), this.get('selectedPatients'));
      },
      confirm: function confirm(directTreatment, customerId, selectedPatientIds) {
        this.confirmRoot(directTreatment, customerId, selectedPatientIds);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      addPatient: function addPatient(customerId, invoiceId) {
        this.sendAction('addPatient', customerId, invoiceId);
      },
      loadTreatment: function loadTreatment(data) {
        this.set('historicTreatments', data);
      },
      load: function load(customerId, patients, app) {

        this.set('selectedPatients', patients);
        this.set('firstPatientId', patients[0]);

        this.set('customerId', customerId);

        this.set('appointment', false);
        this.set('app', app);

        this.set('appointmentTime', null);

        var self = this;
        if (app) {
          if (app.internalUser1) {
            this.get('doctors').forEach(function (item) {
              if (item.id == app.internalUser1.id) {
                self.set('doctor', item);
              }
            });
          }
          if (app.internalAssistant1) {
            this.get('assistants').forEach(function (item) {
              if (item.id == app.internalAssistant1.id) {
                self.set('assistant', item);
              }
            });
          }
        }

        var self = this;
        $.ajax({
          url: "/api/customers/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.customer.patients && data.customer.patients.length > 0) {

            if (app) {
              $(data.customer.patients).each(function () {
                if (this.id === patients[0]) {
                  this.introductionReason = app.introductionReasons;
                }
              });
            }
            self.set('customer', data.customer);
          }
        }, function (error) {});

        $.ajax({
          url: "/api/customerDeposits/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('balanceSum', data.meta.sum);
          self.set('depositSum', data.meta.depositSum);
          self.set('status', data.meta.status);
        });
      }
    }
  });

});
define('jason-frontend/routes/compositeproducts/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.findRecord('compositeArticle', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    saveTextModules: function saveTextModules() {

      var medicalHistoryEntries = new Array();
      var symptomsEntries = new Array();
      var diagnosesEntries = new Array();
      var therapiesEntries = new Array();
      var reportEntries = new Array();

      $('#sortableMedicalHistory div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        medicalHistoryEntries.push(entry);
      });
      $('#sortableSymptoms div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        symptomsEntries.push(entry);
      });
      $('#sortableDiagnoses div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        diagnosesEntries.push(entry);
      });
      $('#sortableTherapies div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        therapiesEntries.push(entry);
      });
      $('#sortableReports div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        reportEntries.push(entry);
      });

      var data = {
        medicalHistoryEntries: medicalHistoryEntries,
        diagnosesEntries: diagnosesEntries,
        therapiesEntries: therapiesEntries,
        symptomsEntries: symptomsEntries,
        reportEntries: reportEntries
      };

      $.ajax({
        url: "/api/products/" + this.get('controller').get('model').id + "/textModules",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
      }, function () {});
    },
    selectTab: function selectTab(selected) {
      var self = this;
      var controller = this.get('controller');
      controller.set('selectTab', selected);
      controller.set('sidebarActive', false);

      if (selected === 'future') {
        controller.set('futureItems', this.store.find('future-action-blueprint', { articleId: controller.get('model').get('id') }));
      } else if (selected === 'todo') {
        $.ajax({
          url: "/api/products/" + controller.get('model').get('id') + "/todos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('items', data.todoTemplate);
        });
      } else if (selected === 'treatment') {
        controller.set('sidebarActive', true);
        $.ajax({
          url: "/api/products/" + controller.get('model').get('id') + "/treatmentvalues",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('selectedTreatmentValues', data.articleTreatmentValues);
        });

        $('.sortable div.sortable-item').each(function (index, val) {
          $(this).remove();
        });

        setTimeout(function () {
          self.searchTextmodules();
          $.ajax({
            url: "/api/products/" + self.get('controller').get('model').id + "/textModules",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').set('selectedTextModules', data.textModuleArticle);
          }, function () {});
        }, 500);
      }
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;
      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".draggable").draggable({
            connectToSortable: ".sortable",
            helper: "clone",
            revert: "invalid"
          });
        }, 500);
      });

      $(".sortable").sortable({
        revert: true
      });

      $(".sortable").droppable({
        drop: function drop(event, ui) {
          setTimeout(function () {
            ui.draggable.addClass('fixed');
          }, 500);
        }
      });
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var selectedTab = params.queryParams.selectTab;
      if (selectedTab) {
        this.selectTab(selectedTab);
      }
      var type = params.queryParams.type;
      controller.set('type', type);
      if (model.get('type')) {
        controller.set('type', model.get('type'));
      }
      controller.set('timeUnits', this.store.find('time-unit'));
    },
    actions: {
      editTodo: function editTodo(id) {
        var self = this;
        if (id) {
          $.ajax({
            url: "/api/todoTemplates/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').get('editTodoPanel').send('load', data.todoTemplate);
          }, function (error) {});
        } else {
          this.get('controller').get('editTodoPanel').send('loadNew');
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-todo-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      searchTextModules: function searchTextModules() {
        this.searchTextmodules();
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
        }
      },
      addServiceCategory: function addServiceCategory(category, title, selectedValues) {

        var self = this;

        $.ajax({
          url: "/api/services?filterMandant=all&page=1&per_page=10&selectedCategoryFilters=" + category + "&sortBy=&sortDir=",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectServicePanel').send('load', data.service, selectedValues, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-service-panel'
          },
          callbacks: {
            close: function close() {
              var values = Array();

              $('#treatmentSelectServiceFormSelectedValues > div').each(function (index, item) {
                values.push($(item).attr('data-id'));
              });

              var data = {
                id: category,
                value: values.join(',')
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectMultistageTreatmentDetail: function selectMultistageTreatmentDetail(provider, selected, title) {
        this.saveTextModules();
        var self = this;

        this.get('controller').get('treatmentMultiStageSelectPanel').send('load', provider, selected, self, title);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-multistage-select-panel'
          },
          callbacks: {
            close: function close() {
              var data = {
                id: provider,
                value: $('form#treatmentMultistageSelectForm').attr('data-ids')
              };

              if (self.get('controller').get('selectTab') === 'targeting') {
                if (title === 'Spezies') {
                  $.ajax({
                    url: "/api/compositeArticles/" + self.get('controller').get('model').id + "/species",
                    method: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    data: JSON.stringify(data),
                    headers: {
                      'X-AUTH-TOKEN': API['default'].getToken()
                    }
                  }).then(function (data) {
                    self.get('controller').get('model').reload();
                  }, function (error) {
                    self.get('controller').get('model').reload();
                  });
                } else if (provider === 'diagnoses') {
                  $.ajax({
                    url: "/api/compositeArticles/" + self.get('controller').get('model').id + "/diagnosis",
                    method: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    data: JSON.stringify(data),
                    headers: {
                      'X-AUTH-TOKEN': API['default'].getToken()
                    }
                  }).then(function (data) {
                    self.get('controller').get('model').reload();
                  }, function (error) {
                    self.get('controller').get('model').reload();
                  });
                }
              } else {
                $.ajax({
                  url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                  method: "POST",
                  contentType: "application/json",
                  dataType: "json",
                  data: JSON.stringify(data),
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.selectTab('treatment');
                }, function (error) {
                  self.selectTab('treatment');
                });
              }
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, selected, title) {
        this.saveTextModules();

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanel').send('loadMultiselect', data.treatmentLookup, selected, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-panel'
          },
          callbacks: {
            close: function close() {

              var value = null;
              $('#treatmentSelectFormSelectedValues > div').each(function (index, item) {
                value += $(item).attr('data-id') + ',';
              });

              var data = {
                id: provider,
                value: value
              };

              if (self.get('controller').get('selectTab') === 'targeting') {
                $.ajax({
                  url: "/api/compositeArticles/" + self.get('controller').get('model').id + "/" + provider,
                  method: "POST",
                  contentType: "application/json",
                  dataType: "json",
                  data: JSON.stringify(data),
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.get('controller').get('model').reload();
                }, function (error) {
                  self.get('controller').get('model').reload();
                });
              } else {
                $.ajax({
                  url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                  method: "POST",
                  contentType: "application/json",
                  dataType: "json",
                  data: JSON.stringify(data),
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.selectTab('treatment');
                }, function (error) {
                  self.selectTab('treatment');
                });
              }
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reload: function reload() {
        this.get('controller').get('model').reload();
      },
      saveTextModules: function saveTextModules() {
        this.saveTextModules();
      },
      addProduct: function addProduct(id) {
        this.get('controller').send('save');
        this.transitionTo('cash.add_article', 'compositeAddProduct', id, 0);
      },
      addService: function addService(id) {
        this.get('controller').send('save');
        this.transitionTo('cash.add_article', 'compositeAddService', id, 0);
      },
      addTemplate: function addTemplate(id) {
        this.get('controller').send('save');
        this.transitionTo('cash.add_article', 'compositeAddTemplate', id, 0);
      },
      addDoc: function addDoc(id) {
        this.get('controller').send('save');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-upload-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('controller').get('documentUploadPanel').send('load', 'therapy', this.get('controller').get('model').id);
      },
      editEntry: function editEntry(itemId, modelId) {
        this.transitionTo('practicemgmt.treatment-future-action', 'compositeproducts', itemId, modelId);
      },
      printQr: function printQr(id) {
        window.open('/api/compositeArticles/' + id + '/qr');
      },
      deleteEntry: function deleteEntry(modelId, itemId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/futureActionBlueprints/" + itemId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Maßnahme entfernt', '');
          self.get('controller').set('futureItems', self.store.find('future-action-blueprint', { articleId: self.get('controller').get('model').get('id') }));
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      addFutureAction: function addFutureAction(id) {
        var self = this,
            data = {};
        $.ajax({
          url: "/api/futureActionBlueprints/" + id,
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.transitionTo('practicemgmt.treatment-future-action', 'compositeproducts', id, responseData);
        });
      },
      storeTodo: function storeTodo(data) {
        var self = this;
        $.ajax({
          url: "/api/todoTemplates",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.selectTab('todo');
        });
      },
      deleteTodo: function deleteTodo(id) {
        var self = this;
        $.ajax({
          url: "/api/todoTemplates/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.selectTab('todo');
        });
      }
    }
  });

});
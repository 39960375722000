define('jason-frontend/models/treatment-patient', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    patient: DS['default'].attr(),
    patientOwner: DS['default'].attr(),
    info: DS['default'].belongsTo('patient-treatment-info'),
    lastStateChange: DS['default'].attr('number'),
    lastStateChangeDate: DS['default'].attr('date'),
    state: DS['default'].belongsTo('treatment-state'),
    station: DS['default'].belongsTo('treatment-station'),
    room: DS['default'].belongsTo('treatment-room'),
    doctor: DS['default'].belongsTo('user'),
    wishDoctor: DS['default'].belongsTo('user'),
    assistant: DS['default'].belongsTo('user'),
    offerId: DS['default'].attr('number'),
    calcFever: DS['default'].attr('number'),
    deleted: DS['default'].attr(''),
    totalOfferSum: DS['default'].attr('number'),
    invoiceInfo: DS['default'].attr('string'),
    mandantName: DS['default'].attr('string'),
    billed: DS['default'].attr('boolean'),
    version: DS['default'].attr(''),
    reorderMessageId: DS['default'].attr('number'),
    appointment: DS['default'].attr('boolean'),
    time: DS['default'].attr('string'),
    entries: DS['default'].attr(''),
    day: DS['default'].attr('string'),
    dayDate: DS['default'].attr('string'),
    dayTime: DS['default'].attr('string'),
    hasEntries: DS['default'].attr('boolean'),
    hidden: DS['default'].attr('boolean'),
    hiddenForApp: DS['default'].attr('boolean'),
    hideAppOverride: DS['default'].attr(''),
    globalAppOverride: DS['default'].attr(''),
    duration: DS['default'].attr(''),
    durationHours: DS['default'].attr(''),
    rating: DS['default'].attr(''),
    ratingComment: DS['default'].attr(''),
    durationMinutes: DS['default'].attr(''),
    virtualTreatment: DS['default'].attr(''),
    owner: DS['default'].attr('boolean'),
    appEditable: DS['default'].attr(''),
    treatmentReportAvailable: DS['default'].attr(''),
    treatmentInfoText: DS['default'].attr('string')
  });

});
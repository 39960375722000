define('jason-frontend/routes/application', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/config/environment'], function (exports, Ember, API, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!API['default'].isLoggedIn() && transition.targetName !== 'rlogin.index' && transition.targetName !== 'register.index' && transition.targetName !== 'expired.index' && transition.targetName !== 'confirm.index' && transition.targetName !== 'etigaconfirm.index' && transition.targetName !== 'admission.index' && transition.targetName !== 'digitalhelper.index' && transition.targetName !== 'partner.index') {
        this.transitionTo('login');
      }
    },

    actions: {
      gotoTerm: function gotoTerm() {
        var deferred = $.ajax({
          url: "https://identity.vetnative.com/api/identity/auth/elovetRedirect",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText);
        }, function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText);
        });
      },
      saveEtigaPermission: function saveEtigaPermission(patientId, data) {
        var self = this;
        try {
          var deferred = $.ajax({
            url: "/api/patients/" + patientId + "/saveEtigaPermissions",
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {}, function (error) {});
        } catch (e) {}
      },
      selectMandantBarcode: function selectMandantBarcode() {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-mandant-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadMandantBarcode: function downloadMandantBarcode(format) {
        window.open('/api/mandants/downloadBarcode/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      openNotification: function openNotification(id) {
        this.transitionTo('notifications', { queryParams: { filterType: "all" } });
      },
      openTasks: function openTasks() {
        this.transitionTo('tasks');
      },
      openCalendar: function openCalendar() {
        this.transitionTo('appointments.calendar');
      },
      openEtigaNotifications: function openEtigaNotifications() {
        this.transitionTo('notifications', { queryParams: { filterType: "etiga" } });
      },
      updateUndoneCount: function updateUndoneCount(value) {
        this.get('controller').set('undoneCount', value);
      },
      markAllAsRead: function markAllAsRead() {
        $.ajax({
          url: "/api/msgs/markAllAsRead?type=default",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      markAllAsReadEtiga: function markAllAsReadEtiga() {

        $.ajax({
          url: "/api/msgs/markAllAsRead?type=etiga",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      markAsRead: function markAsRead(item) {

        var msg = {};
        msg.id = item.id;

        $.ajax({
          url: "/api/msgs/markAsRead",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(msg),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      markAsReadForAll: function markAsReadForAll(item) {

        var msg = {};
        msg.id = item.id;

        $.ajax({
          url: "/api/msgs/markAsReadForAll",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(msg),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      collapse: function collapse(item) {},
      logout: function logout() {
        var route = this;
        API['default'].logout();
        route.session.set('user', null);
        window.location.href = config['default'].ssoUrl + '/logout?redirect=' + btoa(config['default'].baseUrl);
        // route.transitionTo('index');
      },
      expireSession: function expireSession() {
        API['default'].token = 'expired';
      },
      error: function error(_error, transition) {
        if (_error.status === 403 || _error.status === 'Unauthorized' || _error.errors && _error.errors.length > 0 && _error.errors[0].status === '403') {
          var loginController = this.controllerFor('login');

          loginController.setProperties({
            message: _error.message,
            transition: transition
          });

          this.transitionTo('login');
        } else {
          return true;
        }
      },
      createPatient: function createPatient() {
        this.transitionTo('patients');
      },
      addArticlesToOrder: function addArticlesToOrder() {

        var currentPath = window.location.pathname;
        if (currentPath.match("^\/dashboard\/purchases\/(.+)\/addproduct")) {
          return true;
        }

        var self = this;
        Ember['default'].$.ajax({
          url: "/api/purchaseOrders/practiceOrder",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data && data > 0) {
            self.transitionTo('purchases.edit', data, { queryParams: { redirectToListing: "ever" } });
          } else {
            self.transitionTo('purchases.choose');
          }
        });
      },
      openPracticeCart: function openPracticeCart() {

        var currentPath = window.location.pathname;
        if (currentPath.match("^\/dashboard\/purchases\/(.+)\/edit")) {
          return true;
        }

        var self = this;
        Ember['default'].$.ajax({
          url: "/api/purchaseOrders/practiceOrder",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('purchases.edit', data, { queryParams: { redirectToListing: "false" } });
        });
      },
      gotoAdmission: function gotoAdmission() {
        this.transitionTo('practicemgmt.admission-select-customer');
      },
      gotoPromotions: function gotoPromotions() {
        this.transitionTo('purchases.promotions');
      },

      createInvoice: function createInvoice() {
        var invoice = {},
            self = this;

        Ember['default'].$.ajax({
          url: "/api/invoices",
          method: "POST",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.select_customer', data);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      openHelp: function openHelp() {
        window.open('https://cschwarz.atlassian.net/wiki/spaces/JASONPUBLIC/pages/24870954/Anmeldung+am+Portal');
      },
      openDataprotection: function openDataprotection() {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-dataprotection-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      willTransition: function willTransition(transition) {
        if (!API['default'].isLoggedIn()) {
          route.transitionTo('login');
        }
      }
    }
  });

});
define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 12
                  },
                  "end": {
                    "line": 30,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element49 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element49, 'title');
                morphs[1] = dom.createAttrMorph(element49, 'class');
                morphs[2] = dom.createAttrMorph(element49, 'style');
                morphs[3] = dom.createElementMorph(element49);
                morphs[4] = dom.createMorphAt(dom.childAt(element49, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[26,28],[26,42]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[26,121],[26,134]]]],"selected",""],[],["loc",[null,[26,100],[26,150]]]]," symbol symbol-circle symbol-40px"]]],
                ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[26,212],[26,222]]]]," !important;"]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[26,72],[26,76]]]]],[],["loc",[null,[26,46],[26,78]]]],
                ["content","user.capitals",["loc",[null,[27,42],[27,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 12
                  },
                  "end": {
                    "line": 35,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element48 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element48, 'title');
                morphs[1] = dom.createAttrMorph(element48, 'class');
                morphs[2] = dom.createElementMorph(element48);
                morphs[3] = dom.createMorphAt(dom.childAt(element48, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[31,28],[31,42]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[31,121],[31,134]]]],"selected",""],[],["loc",[null,[31,100],[31,150]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[31,186],[31,196]]]]]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[31,72],[31,76]]]]],[],["loc",[null,[31,46],[31,78]]]],
                ["content","user.capitals",["loc",[null,[32,42],[32,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 36,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.colorHex",["loc",[null,[25,18],[25,31]]]]],[],0,1,["loc",[null,[25,12],[35,19]]]]
            ],
            locals: ["user"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","quick-filter mb10 mtm10 col l-24/24");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","title");
            var el3 = dom.createTextNode("Mitarbeiter:innen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","users",["loc",[null,[24,18],[24,23]]]]],[],0,null,["loc",[null,[24,10],[36,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 24
                  },
                  "end": {
                    "line": 62,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[62,64],[62,91]]]],
                ["content","item.customer.lastname",["loc",[null,[62,92],[62,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 24
                    },
                    "end": {
                      "line": 65,
                      "column": 256
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[65,115],[65,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[65,155],[65,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[65,200],[65,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[65,83],[65,252]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 22
                    },
                    "end": {
                      "line": 66,
                      "column": 149
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[66,92],[66,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[66,125],[66,147]]]]],[],[]]],["loc",[null,[66,73],[66,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 67,
                      "column": 22
                    },
                    "end": {
                      "line": 67,
                      "column": 190
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[67,61],[67,190]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 18
                  },
                  "end": {
                    "line": 69,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element38 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element38,1,1);
                morphs[1] = dom.createMorphAt(element38,3,3);
                morphs[2] = dom.createMorphAt(element38,5,5);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[65,30],[65,58]]]]],[],0,null,["loc",[null,[65,24],[65,263]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[66,49],[66,71]]]]],[],1,null,["loc",[null,[66,22],[66,161]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[67,28],[67,59]]]]],[],2,null,["loc",[null,[67,22],[67,197]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 72,
                    "column": 18
                  },
                  "end": {
                    "line": 76,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 mb5 ml0");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element37 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element37);
                morphs[1] = dom.createMorphAt(element37,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[73,134],[73,150]]]],["get","patientInfo.patient.id",["loc",[null,[73,151],[73,173]]]],["get","item.appointmentId",["loc",[null,[73,174],[73,192]]]]],[],["loc",[null,[73,106],[73,194]]]],
                ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[74,22],[74,101]]]]
              ],
              locals: ["patientInfo"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 12
                },
                "end": {
                  "line": 79,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Termin");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-14/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action pt20 l-2/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element39 = dom.childAt(fragment, [1]);
              var element40 = dom.childAt(element39, [1]);
              var element41 = dom.childAt(element39, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element40, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element41, [1]),0,0);
              morphs[3] = dom.createMorphAt(element41,3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element39, [5]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[57,97],[57,109]]]],"DD. MM. YYYY"],[],["loc",[null,[57,83],[57,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[58,50],[58,62]]]],"HH:mm"],[],["loc",[null,[58,36],[58,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[61,52],[61,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[61,24],[62,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[63,26],[63,48]]]]],[],1,null,["loc",[null,[63,18],[69,27]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[72,26],[72,48]]]]],[],2,null,["loc",[null,[72,18],[76,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 22
                    },
                    "end": {
                      "line": 109,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element33, 'id');
                  morphs[1] = dom.createAttrMorph(element33, 'value');
                  morphs[2] = dom.createAttrMorph(element33, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[106,108],[106,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[106,143],[106,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[106,233],[106,237]]]]],[],["loc",[null,[106,213],[106,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 112,
                      "column": 28
                    },
                    "end": {
                      "line": 113,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[113,64],[113,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[113,92],[113,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 117,
                          "column": 24
                        },
                        "end": {
                          "line": 121,
                          "column": 85
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[118,81],[118,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[119,71],[119,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[120,71],[120,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[118,49],[121,81]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 122,
                          "column": 28
                        },
                        "end": {
                          "line": 123,
                          "column": 92
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[123,35],[123,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[123,68],[123,90]]]]],[],[]]],["loc",[null,[122,79],[123,92]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 124,
                          "column": 28
                        },
                        "end": {
                          "line": 126,
                          "column": 63
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[124,67],[126,63]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 131,
                              "column": 28
                            },
                            "end": {
                              "line": 136,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element30 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element30, 'title');
                          morphs[1] = dom.createAttrMorph(element30, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[132,44],[132,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[132,162],[132,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[133,60],[133,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 136,
                              "column": 28
                            },
                            "end": {
                              "line": 141,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element29 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element29, 'title');
                          morphs[1] = dom.createAttrMorph(element29, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[137,44],[137,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[137,122],[137,149]]]],"selected",""],[],["loc",[null,[137,101],[137,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[137,201],[137,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[138,60],[138,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 130,
                            "column": 26
                          },
                          "end": {
                            "line": 142,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[131,34],[131,61]]]]],[],0,1,["loc",[null,[131,28],[141,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 144,
                              "column": 34
                            },
                            "end": {
                              "line": 149,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element28 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element28, 'title');
                          morphs[1] = dom.createAttrMorph(element28, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[145,50],[145,81]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[145,171],[145,198]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[146,62],[146,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 149,
                              "column": 34
                            },
                            "end": {
                              "line": 154,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element27 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element27, 'title');
                          morphs[1] = dom.createAttrMorph(element27, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[150,50],[150,81]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[150,131],[150,161]]]],"selected",""],[],["loc",[null,[150,110],[150,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[150,213],[150,240]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[151,62],[151,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 143,
                            "column": 32
                          },
                          "end": {
                            "line": 155,
                            "column": 32
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[144,40],[144,70]]]]],[],0,1,["loc",[null,[144,34],[154,41]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 128,
                          "column": 28
                        },
                        "end": {
                          "line": 156,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[130,32],[130,50]]]]],[],0,null,["loc",[null,[130,26],[142,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[143,38],[143,59]]]]],[],1,null,["loc",[null,[143,32],[155,39]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 115,
                        "column": 24
                      },
                      "end": {
                        "line": 163,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                    var el3 = dom.createTextNode("\n                              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element31 = dom.childAt(fragment, [1]);
                    var element32 = dom.childAt(element31, [9]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(element31,1,1);
                    morphs[1] = dom.createMorphAt(element31,3,3);
                    morphs[2] = dom.createMorphAt(element31,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element31, [7]),1,1);
                    morphs[4] = dom.createElementMorph(element32);
                    morphs[5] = dom.createMorphAt(element32,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[117,30],[117,58]]]]],[],0,null,["loc",[null,[117,24],[121,92]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[122,55],[122,77]]]]],[],1,null,["loc",[null,[122,28],[123,104]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[124,34],[124,65]]]]],[],2,null,["loc",[null,[124,28],[126,70]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[128,38],[128,59]]]],["get","patientInfo.doctor",["loc",[null,[128,60],[128,78]]]]],[],["loc",[null,[128,34],[128,79]]]]],[],3,null,["loc",[null,[128,28],[156,35]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[159,149],[159,163]]]]],[],["loc",[null,[159,111],[159,165]]]],
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandeln"],["loc",[null,[160,30],[160,113]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 22
                    },
                    "end": {
                      "line": 164,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[115,59],[115,70]]]],["get","selectedUsers",["loc",[null,[115,71],[115,84]]]],["get","changeTrigger",["loc",[null,[115,85],[115,98]]]]],[],["loc",[null,[115,30],[115,99]]]]],[],0,null,["loc",[null,[115,24],[163,31]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 14
                  },
                  "end": {
                    "line": 168,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var element35 = dom.childAt(element34, [1]);
                var element36 = dom.childAt(element34, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element35, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element35, [3]),0,0);
                morphs[2] = dom.createMorphAt(element35,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                morphs[4] = dom.createMorphAt(element36,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[100,101],[100,113]]]],"DD. MM. YYYY"],[],["loc",[null,[100,87],[100,130]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[101,54],[101,66]]]],"HH:mm"],[],["loc",[null,[101,40],[101,76]]]],
                ["block","if",[["get","item.time",["loc",[null,[102,28],[102,37]]]]],[],0,null,["loc",[null,[102,22],[109,29]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[112,56],[112,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[112,28],[113,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[114,30],[114,52]]]]],[],2,null,["loc",[null,[114,22],[164,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 12
                },
                "end": {
                  "line": 169,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[97,41],[97,45]]]],["get","selectedUsers",["loc",[null,[97,46],[97,59]]]],["get","changeTrigger",["loc",[null,[97,60],[97,73]]]]],[],["loc",[null,[97,20],[97,74]]]]],[],0,null,["loc",[null,[97,14],[168,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 192,
                      "column": 18
                    },
                    "end": {
                      "line": 199,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element23 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element23, 'id');
                  morphs[1] = dom.createAttrMorph(element23, 'value');
                  morphs[2] = dom.createAttrMorph(element23, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[196,108],[196,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[196,143],[196,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[196,233],[196,237]]]]],[],["loc",[null,[196,213],[196,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 202,
                      "column": 24
                    },
                    "end": {
                      "line": 203,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[203,64],[203,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[203,92],[203,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 207,
                          "column": 24
                        },
                        "end": {
                          "line": 207,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[207,115],[207,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[207,155],[207,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[207,200],[207,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[207,83],[207,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 208,
                          "column": 24
                        },
                        "end": {
                          "line": 208,
                          "column": 151
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[208,94],[208,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[208,127],[208,149]]]]],[],[]]],["loc",[null,[208,75],[208,151]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 209,
                          "column": 24
                        },
                        "end": {
                          "line": 209,
                          "column": 192
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[209,63],[209,192]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 214,
                              "column": 30
                            },
                            "end": {
                              "line": 219,
                              "column": 44
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element21 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element21, 'title');
                          morphs[1] = dom.createAttrMorph(element21, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[215,46],[215,74]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[215,164],[215,188]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[216,60],[216,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 219,
                              "column": 44
                            },
                            "end": {
                              "line": 224,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element20 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element20, 'title');
                          morphs[1] = dom.createAttrMorph(element20, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[220,60],[220,88]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[220,138],[220,165]]]],"selected",""],[],["loc",[null,[220,117],[220,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[220,217],[220,241]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[221,60],[221,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 213,
                            "column": 26
                          },
                          "end": {
                            "line": 225,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[214,36],[214,63]]]]],[],0,1,["loc",[null,[214,30],[224,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 227,
                              "column": 30
                            },
                            "end": {
                              "line": 232,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element19 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element19, 'title');
                          morphs[1] = dom.createAttrMorph(element19, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[228,46],[228,77]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[228,167],[228,194]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[229,62],[229,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 232,
                              "column": 30
                            },
                            "end": {
                              "line": 237,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element18 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element18, 'title');
                          morphs[1] = dom.createAttrMorph(element18, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[233,46],[233,77]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[233,127],[233,157]]]],"selected",""],[],["loc",[null,[233,106],[233,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[233,209],[233,236]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[234,62],[234,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 226,
                            "column": 28
                          },
                          "end": {
                            "line": 238,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[227,36],[227,66]]]]],[],0,1,["loc",[null,[227,30],[237,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 212,
                          "column": 24
                        },
                        "end": {
                          "line": 239,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[213,32],[213,50]]]]],[],0,null,["loc",[null,[213,26],[225,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[226,34],[226,55]]]]],[],1,null,["loc",[null,[226,28],[238,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child4 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 242,
                          "column": 24
                        },
                        "end": {
                          "line": 244,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[243,26],[243,117]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 205,
                        "column": 22
                      },
                      "end": {
                        "line": 246,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element22 = dom.childAt(fragment, [1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createMorphAt(element22,1,1);
                    morphs[1] = dom.createMorphAt(element22,3,3);
                    morphs[2] = dom.createMorphAt(element22,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element22, [7]),1,1);
                    morphs[4] = dom.createMorphAt(element22,9,9);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[207,30],[207,58]]]]],[],0,null,["loc",[null,[207,24],[207,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[208,51],[208,73]]]]],[],1,null,["loc",[null,[208,24],[208,163]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[209,30],[209,61]]]]],[],2,null,["loc",[null,[209,24],[209,199]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[212,34],[212,55]]]],["get","patientInfo.doctor",["loc",[null,[212,56],[212,74]]]]],[],["loc",[null,[212,30],[212,75]]]]],[],3,null,["loc",[null,[212,24],[239,31]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[242,68],[242,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[242,83],[242,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],4,null,["loc",[null,[242,24],[244,36]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 204,
                      "column": 20
                    },
                    "end": {
                      "line": 247,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[205,57],[205,68]]]],["get","selectedUsers",["loc",[null,[205,69],[205,82]]]],["get","changeTrigger",["loc",[null,[205,83],[205,96]]]]],[],["loc",[null,[205,28],[205,97]]]]],[],0,null,["loc",[null,[205,22],[246,29]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 187,
                    "column": 14
                  },
                  "end": {
                    "line": 250,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var element25 = dom.childAt(element24, [1]);
                var element26 = dom.childAt(element24, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
                morphs[2] = dom.createMorphAt(element25,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                morphs[4] = dom.createMorphAt(element26,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[190,97],[190,109]]]],"DD. MM. YYYY"],[],["loc",[null,[190,83],[190,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[191,50],[191,62]]]],"HH:mm"],[],["loc",[null,[191,36],[191,72]]]],
                ["block","if",[["get","item.time",["loc",[null,[192,24],[192,33]]]]],[],0,null,["loc",[null,[192,18],[199,25]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[202,52],[202,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[202,24],[203,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[204,28],[204,50]]]]],[],2,null,["loc",[null,[204,20],[247,29]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 186,
                  "column": 12
                },
                "end": {
                  "line": 251,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[187,41],[187,45]]]],["get","selectedUsers",["loc",[null,[187,46],[187,59]]]],["get","changeTrigger",["loc",[null,[187,60],[187,73]]]]],[],["loc",[null,[187,20],[187,74]]]]],[],0,null,["loc",[null,[187,14],[250,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 274,
                      "column": 24
                    },
                    "end": {
                      "line": 275,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[275,64],[275,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[275,92],[275,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 279,
                          "column": 24
                        },
                        "end": {
                          "line": 279,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[279,115],[279,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[279,155],[279,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[279,200],[279,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[279,83],[279,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 281,
                          "column": 0
                        },
                        "end": {
                          "line": 281,
                          "column": 127
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[281,70],[281,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[281,103],[281,125]]]]],[],[]]],["loc",[null,[281,51],[281,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 282,
                          "column": 24
                        },
                        "end": {
                          "line": 284,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","block small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","patientInfo.room.name",["loc",[null,[283,52],[283,77]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 286,
                          "column": 22
                        },
                        "end": {
                          "line": 286,
                          "column": 190
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[286,61],[286,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 291,
                              "column": 28
                            },
                            "end": {
                              "line": 296,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element11 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element11, 'title');
                          morphs[1] = dom.createAttrMorph(element11, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[292,44],[292,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[292,162],[292,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[293,60],[293,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 296,
                              "column": 28
                            },
                            "end": {
                              "line": 301,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element10, 'title');
                          morphs[1] = dom.createAttrMorph(element10, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[297,44],[297,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[297,122],[297,149]]]],"selected",""],[],["loc",[null,[297,101],[297,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[297,201],[297,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[298,60],[298,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 290,
                            "column": 26
                          },
                          "end": {
                            "line": 302,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[291,34],[291,61]]]]],[],0,1,["loc",[null,[291,28],[301,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 304,
                              "column": 28
                            },
                            "end": {
                              "line": 309,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element9 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element9, 'title');
                          morphs[1] = dom.createAttrMorph(element9, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[305,44],[305,75]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[305,165],[305,192]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[306,62],[306,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 309,
                              "column": 28
                            },
                            "end": {
                              "line": 314,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element8 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element8, 'title');
                          morphs[1] = dom.createAttrMorph(element8, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[310,44],[310,75]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[310,125],[310,155]]]],"selected",""],[],["loc",[null,[310,104],[310,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[310,207],[310,234]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[311,62],[311,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 303,
                            "column": 26
                          },
                          "end": {
                            "line": 315,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[304,34],[304,64]]]]],[],0,1,["loc",[null,[304,28],[314,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 288,
                          "column": 22
                        },
                        "end": {
                          "line": 316,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[290,32],[290,50]]]]],[],0,null,["loc",[null,[290,26],[302,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[303,32],[303,53]]]]],[],1,null,["loc",[null,[303,26],[315,33]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 277,
                        "column": 20
                      },
                      "end": {
                        "line": 323,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createTextNode("Behandeln");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [1]);
                    var element13 = dom.childAt(element12, [3]);
                    var element14 = dom.childAt(element12, [9]);
                    var morphs = new Array(7);
                    morphs[0] = dom.createMorphAt(element12,1,1);
                    morphs[1] = dom.createMorphAt(element13,1,1);
                    morphs[2] = dom.createMorphAt(element13,3,3);
                    morphs[3] = dom.createMorphAt(element12,5,5);
                    morphs[4] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
                    morphs[5] = dom.createElementMorph(element14);
                    morphs[6] = dom.createMorphAt(element14,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[279,30],[279,58]]]]],[],0,null,["loc",[null,[279,24],[279,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[281,27],[281,49]]]]],[],1,null,["loc",[null,[281,0],[281,139]]]],
                    ["block","if",[["get","patientInfo.room",["loc",[null,[282,30],[282,46]]]]],[],2,null,["loc",[null,[282,24],[284,31]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[286,28],[286,59]]]]],[],3,null,["loc",[null,[286,22],[286,197]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[288,32],[288,53]]]],["get","patientInfo.doctor",["loc",[null,[288,54],[288,72]]]]],[],["loc",[null,[288,28],[288,73]]]]],[],4,null,["loc",[null,[288,22],[316,29]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[319,131],[319,145]]]]],[],["loc",[null,[319,93],[319,147]]]],
                    ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[320,26],[320,135]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 276,
                      "column": 18
                    },
                    "end": {
                      "line": 324,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[277,55],[277,66]]]],["get","selectedUsers",["loc",[null,[277,67],[277,80]]]],["get","changeTrigger",["loc",[null,[277,81],[277,94]]]]],[],["loc",[null,[277,26],[277,95]]]]],[],0,null,["loc",[null,[277,20],[323,27]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 267,
                    "column": 14
                  },
                  "end": {
                    "line": 327,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(element15, [1]);
                var element17 = dom.childAt(element15, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                morphs[3] = dom.createMorphAt(element17,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[270,97],[270,109]]]],"DD. MM. YYYY"],[],["loc",[null,[270,83],[270,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[271,50],[271,62]]]],"HH:mm"],[],["loc",[null,[271,36],[271,72]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[274,52],[274,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[274,24],[275,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[276,26],[276,48]]]]],[],1,null,["loc",[null,[276,18],[324,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 266,
                  "column": 12
                },
                "end": {
                  "line": 328,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[267,41],[267,45]]]],["get","selectedUsers",["loc",[null,[267,46],[267,59]]]],["get","changeTrigger",["loc",[null,[267,60],[267,73]]]]],[],["loc",[null,[267,20],[267,74]]]]],[],0,null,["loc",[null,[267,14],[327,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 353,
                    "column": 24
                  },
                  "end": {
                    "line": 354,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[354,64],[354,91]]]],
                ["content","item.customer.lastname",["loc",[null,[354,92],[354,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 357,
                      "column": 24
                    },
                    "end": {
                      "line": 361,
                      "column": 85
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[358,81],[358,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[359,71],[359,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[360,71],[360,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[358,49],[361,81]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 362,
                      "column": 22
                    },
                    "end": {
                      "line": 363,
                      "column": 119
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[362,92],[362,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[363,95],[363,117]]]]],[],[]]],["loc",[null,[362,73],[363,119]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 364,
                      "column": 22
                    },
                    "end": {
                      "line": 366,
                      "column": 107
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[364,61],[366,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 355,
                    "column": 18
                  },
                  "end": {
                    "line": 368,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element4,1,1);
                morphs[1] = dom.createMorphAt(element4,3,3);
                morphs[2] = dom.createMorphAt(element4,5,5);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[357,30],[357,58]]]]],[],0,null,["loc",[null,[357,24],[361,92]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[362,49],[362,71]]]]],[],1,null,["loc",[null,[362,22],[363,131]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[364,28],[364,59]]]]],[],2,null,["loc",[null,[364,22],[366,114]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 372,
                      "column": 20
                    },
                    "end": {
                      "line": 377,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 mb5 ml0 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[374,24],[375,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 384,
                        "column": 22
                      },
                      "end": {
                        "line": 386,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[385,24],[385,115]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 377,
                      "column": 20
                    },
                    "end": {
                      "line": 387,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 mb5 ml5 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element3);
                  morphs[1] = dom.createMorphAt(element3,1,1);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[379,94],[379,108]]]],["get","patientInfo.info.invoiceId",["loc",[null,[380,94],[380,120]]]]],[],["loc",[null,[378,109],[380,122]]]],
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[381,24],[382,87]]]],
                  ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[384,66],[384,80]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[384,81],[384,108]]]]],["class","mr0 icon-button icon-button--default list-action-square"],0,null,["loc",[null,[384,22],[386,34]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 371,
                    "column": 18
                  },
                  "end": {
                    "line": 388,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","inProgress",["loc",[null,[372,26],[372,36]]]]],[],0,1,["loc",[null,[372,20],[387,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 345,
                  "column": 12
                },
                "end": {
                  "line": 391,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-11/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
              morphs[3] = dom.createMorphAt(element7,3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[348,97],[348,109]]]],"DD. MM. YYYY"],[],["loc",[null,[348,83],[349,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[350,50],[350,62]]]],"HH:mm"],[],["loc",[null,[350,36],[350,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[353,52],[353,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[353,24],[354,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[355,26],[355,48]]]]],[],1,null,["loc",[null,[355,18],[368,27]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[371,26],[371,48]]]]],[],2,null,["loc",[null,[371,18],[388,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 0
              },
              "end": {
                "line": 396,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Demnächst");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Termin");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Wartezimmer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("In Behandlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            dom.setAttribute(el2,"id","stationsCard");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Auf Station");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Bezahlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-14/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element42 = dom.childAt(fragment, [1, 1]);
            var element43 = dom.childAt(fragment, [3, 1]);
            var element44 = dom.childAt(fragment, [5]);
            var element45 = dom.childAt(element44, [1]);
            var element46 = dom.childAt(element44, [3]);
            var element47 = dom.childAt(fragment, [7, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element42, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element42, [3, 3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element43, [1, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element43, [3, 3]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element45, [1, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element45, [3, 3]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element46, [1, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element46, [3, 3]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element47, [1, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element47, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","get-size",[["get","upcomingItems",["loc",[null,[45,92],[45,105]]]]],[],["loc",[null,[45,81],[45,107]]]],
            ["block","each",[["get","upcomingItems",["loc",[null,[54,20],[54,33]]]]],[],0,null,["loc",[null,[54,12],[79,21]]]],
            ["inline","get-size",[["get","waitingroomItems",["loc",[null,[87,92],[87,108]]]]],[],["loc",[null,[87,81],[87,110]]]],
            ["block","each",[["get","waitingroomItems",["loc",[null,[96,20],[96,36]]]]],[],1,null,["loc",[null,[96,12],[169,21]]]],
            ["inline","get-size",[["get","treatmentItems",["loc",[null,[177,92],[177,106]]]]],[],["loc",[null,[177,81],[177,108]]]],
            ["block","each",[["get","treatmentItems",["loc",[null,[186,20],[186,34]]]]],[],2,null,["loc",[null,[186,12],[251,21]]]],
            ["inline","get-size",[["get","stationItems",["loc",[null,[257,92],[257,104]]]]],[],["loc",[null,[257,81],[257,106]]]],
            ["block","each",[["get","stationItems",["loc",[null,[266,20],[266,32]]]]],[],3,null,["loc",[null,[266,12],[328,21]]]],
            ["inline","get-size",[["get","paymentItems",["loc",[null,[336,92],[336,104]]]]],[],["loc",[null,[336,81],[336,106]]]],
            ["block","each",[["get","paymentItems",["loc",[null,[345,20],[345,32]]]]],[],4,null,["loc",[null,[345,12],[391,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 398,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","with-context-bar grid");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element50 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element50,1,1);
          morphs[1] = dom.createMorphAt(element50,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[20,14],[20,22]]]],"tiles"],[],["loc",[null,[20,10],[20,31]]]]],[],0,null,["loc",[null,[20,4],[39,11]]]],
          ["block","if",[["get","users",["loc",[null,[41,6],[41,11]]]]],[],1,null,["loc",[null,[41,0],[396,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 414,
                "column": 10
              },
              "end": {
                "line": 416,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[415,65],[415,81]]]],"selected",""],[],["loc",[null,[415,44],[415,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[415,124],[415,131]]]]],[],["loc",[null,[415,99],[415,133]]]],
            ["content","station.name",["loc",[null,[415,134],[415,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 411,
              "column": 6
            },
            "end": {
              "line": 418,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[414,18],[414,26]]]]],[],0,null,["loc",[null,[414,10],[416,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 424,
                "column": 14
              },
              "end": {
                "line": 429,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element1, 'title');
            morphs[1] = dom.createAttrMorph(element1, 'class');
            morphs[2] = dom.createAttrMorph(element1, 'style');
            morphs[3] = dom.createElementMorph(element1);
            morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[425,30],[425,44]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[425,123],[425,136]]]],"selected",""],[],["loc",[null,[425,102],[425,152]]]]," symbol symbol-circle symbol-40px"]]],
            ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[425,214],[425,224]]]]," !important;"]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[425,74],[425,78]]]]],[],["loc",[null,[425,48],[425,80]]]],
            ["content","user.capitals",["loc",[null,[426,44],[426,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 429,
                "column": 14
              },
              "end": {
                "line": 434,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'title');
            morphs[1] = dom.createAttrMorph(element0, 'class');
            morphs[2] = dom.createElementMorph(element0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[430,30],[430,44]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[430,123],[430,136]]]],"selected",""],[],["loc",[null,[430,102],[430,152]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[430,188],[430,198]]]]]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[430,74],[430,78]]]]],[],["loc",[null,[430,48],[430,80]]]],
            ["content","user.capitals",["loc",[null,[431,44],[431,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 423,
              "column": 12
            },
            "end": {
              "line": 435,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[424,20],[424,33]]]]],[],0,1,["loc",[null,[424,14],[434,21]]]]
        ],
        locals: ["user"],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 480,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        dom.setAttribute(el1,"id","treatmentOverviewNav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn-group");
        dom.setAttribute(el3,"role","group");
        dom.setAttribute(el3,"aria-label","Basic example");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Nur Liste");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-10/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 mt10 col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element51 = dom.childAt(fragment, [0]);
        var element52 = dom.childAt(element51, [1, 1]);
        var element53 = dom.childAt(element52, [1]);
        var element54 = dom.childAt(element52, [3]);
        var element55 = dom.childAt(element52, [5]);
        var element56 = dom.childAt(element51, [3, 1]);
        var element57 = dom.childAt(fragment, [2]);
        var element58 = dom.childAt(element57, [3]);
        var element59 = dom.childAt(element58, [1]);
        var element60 = dom.childAt(element59, [1]);
        var element61 = dom.childAt(element60, [3]);
        var element62 = dom.childAt(element60, [5]);
        var element63 = dom.childAt(element60, [7]);
        var element64 = dom.childAt(element60, [9]);
        var element65 = dom.childAt(element60, [11]);
        var morphs = new Array(27);
        morphs[0] = dom.createAttrMorph(element53, 'class');
        morphs[1] = dom.createElementMorph(element53);
        morphs[2] = dom.createAttrMorph(element54, 'class');
        morphs[3] = dom.createElementMorph(element54);
        morphs[4] = dom.createAttrMorph(element55, 'class');
        morphs[5] = dom.createElementMorph(element55);
        morphs[6] = dom.createElementMorph(element56);
        morphs[7] = dom.createAttrMorph(element57, 'class');
        morphs[8] = dom.createMorphAt(element57,1,1);
        morphs[9] = dom.createAttrMorph(element58, 'class');
        morphs[10] = dom.createAttrMorph(element61, 'class');
        morphs[11] = dom.createElementMorph(element61);
        morphs[12] = dom.createAttrMorph(element62, 'class');
        morphs[13] = dom.createElementMorph(element62);
        morphs[14] = dom.createAttrMorph(element63, 'class');
        morphs[15] = dom.createElementMorph(element63);
        morphs[16] = dom.createAttrMorph(element64, 'class');
        morphs[17] = dom.createElementMorph(element64);
        morphs[18] = dom.createAttrMorph(element65, 'class');
        morphs[19] = dom.createElementMorph(element65);
        morphs[20] = dom.createMorphAt(element59,3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element59, [5, 3]),1,1);
        morphs[22] = dom.createMorphAt(element58,3,3);
        morphs[23] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[4,106],[4,114]]]],"splitted"],[],["loc",[null,[4,102],[4,126]]]],"selected",""],[],["loc",[null,[4,81],[4,142]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[4,14],[4,48]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[5,103],[5,111]]]],"tiles"],[],["loc",[null,[5,99],[5,120]]]],"selected",""],[],["loc",[null,[5,78],[5,136]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[5,14],[5,45]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[6,102],[6,110]]]],"list"],[],["loc",[null,[6,98],[6,118]]]],"selected",""],[],["loc",[null,[6,77],[6,134]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[6,14],[6,44]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[10,7],[10,47]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[16,37],[16,45]]]]]]],
        ["block","if",[["get","showTopTiles",["loc",[null,[17,6],[17,18]]]]],[],0,null,["loc",[null,[17,0],[398,9]]]],
        ["attribute","class",["concat",["site-content card transaction-filter  ",["subexpr","css-bool-evaluator",[["get","showTopTiles",["loc",[null,[400,77],[400,89]]]],"","with-context-bar"],[],["loc",[null,[400,56],[400,113]]]]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[405,61],[405,68]]]],"selected",""],[],["loc",[null,[405,40],[405,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[405,87],[405,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[406,61],[406,72]]]],"selected",""],[],["loc",[null,[406,40],[406,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[406,90],[406,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[407,61],[407,74]]]],"selected",""],[],["loc",[null,[407,40],[407,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[407,92],[407,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[408,61],[408,72]]]],"selected",""],[],["loc",[null,[408,40],[408,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[408,90],[408,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[409,61],[409,72]]]],"selected",""],[],["loc",[null,[409,40],[409,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[409,90],[409,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[411,12],[411,23]]]]],[],1,null,["loc",[null,[411,6],[418,13]]]],
        ["block","each",[["get","users",["loc",[null,[423,20],[423,25]]]]],[],2,null,["loc",[null,[423,12],[435,21]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[441,14],[441,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[442,13],[442,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[443,22],[443,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[444,25],[444,33]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[445,22],[445,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[446,16],[446,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[447,22],[447,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[448,20],[448,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[449,22],[449,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[450,20],[450,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[451,20],[451,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[452,28],[452,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[440,6],[460,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[463,67],[463,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[463,84],[463,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[463,103],[463,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[463,126],[463,144]]]]],[],[]]],["loc",[null,[463,0],[463,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[469,8],[469,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[470,10],[470,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[471,13],[471,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[472,17],[472,36]]]]],[],[]]],["loc",[null,[465,0],[472,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[477,17],[477,35]]]]],[],[]]],["loc",[null,[474,0],[477,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[478,49],[478,62]]]]],[],[]]],["loc",[null,[478,0],[478,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
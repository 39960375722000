define('jason-frontend/routes/customers/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      var self = this;
      return this.store.fetch('customer', params.id).then(function (data) {
        if (data.get('reductions').length == 0) {
          data.get('reductions').pushObject(self.store.createRecord("customerReduction"));
        }
        return data;
      });
    },
    reloadMedias: function reloadMedias() {
      this.get('controller').set('medias', this.store.find('media', { customerId: this.get('controller').get('model').id }));
    },
    afterModel: function afterModel(model) {
      var self = this;
      if (!modelHookRun) {
        model.reload();
        model.get('reductions').forEach(function (item) {
          var stateName = item.currentState.stateName;
          if (stateName === 'root.loaded.created.uncommitted') {
            self.store.deleteRecord(item);
          }
        });
        if (model.get('reductions').length == 0) {
          model.get('reductions').pushObject(this.store.createRecord("customerReduction"));
        }

        return model;
      }
      return model;
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller'); //.get('controller')
      controller.set('selectTab', selected);
      self = this;

      if (selected === 'correspondance') {
        this.send('applyCorrespondanceFilter');
      }

      if (selected === 'saldo' || selected === 'patients' || selected === 'correspondance') {
        if (Object.keys(controller.get('model').changedAttributes()).length > 0) {
          $('form#customerEdit').submit();
          controller.get('model').rollback();
        }
      }

      if (selected === 'medias') {
        this.reloadMedias();
      }

      if (selected === 'appointments') {

        this.findPaged('patient-appointment', {
          filterCustomer: controller.get('model').get('id'),
          filterCategory: controller.get('filterCategory'),
          perPage: 10
        }).then(function (entries) {
          controller.set('appointments', entries);
        });
      }

      if (selected === 'documents') {
        var deferred = $.ajax({
          url: "/api/documentTags",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('tags', data.documentTag);
        }, function (data) {
          controller.set('tags', data.documentTag);
        });

        setTimeout(function () {
          if (controller.get('documentsPanel')) {
            controller.get('documentsPanel').send('loadForCustomer', self, controller.get('model').get('id'), controller.get('model').get('emails'));
          }
        }, 1000);
      }
      if (selected === 'patients') {

        var showDeleted = controller.get('quickFilter') === 'deleted';

        $.ajax({
          url: "/api/patients/forcustomer/" + controller.get('model').get('id') + "?showDeleted=" + showDeleted,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('patientList', data.patient);
        }, function (data) {});
      }
    },
    actions: {
      undeletePatient: function undeletePatient(id) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + id + "/undelete",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'wiederhergestellt');
          self.selectTab('patients');
        });
      },
      sendEmail: function sendEmail(id, data) {
        Ember['default'].$.ajax({
          url: "/api/patients/mailto/" + id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'Laborbericht wurde übermittelt');
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Laborbericht wurde übermittelt');
        });
      },
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      selectSearchTag: function selectSearchTag(tag) {
        if (tag.selected === true) {
          Ember['default'].set(tag, 'selected', false);
        } else {
          Ember['default'].set(tag, 'selected', true);
        }
        var self = this;
        setTimeout(function () {
          var tags = new Array();
          $('#tagFilters label.info-badge.selected').each(function () {
            tags.push($(this).attr('data-id'));
          });
          self.get('controller').get('documentsPanel').send('applyFilterWithTags', tags.join(","));
        }, 300);
      },
      downloadFolder: function downloadFolder() {
        this.get('controller').get('documentsPanel').send('downloadFolder');
      },
      updateSearch: function updateSearch(enabled, term) {
        this.get('controller').set('filterName', term);
      },
      gotoCustomer: function gotoCustomer(id) {
        this.transitionTo('customers.edit', id);
      },
      searchDocument: function searchDocument() {
        this.get('controller').get('documentsPanel').send('externalSearch', this.get('controller').get('filterName'));
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          customerId: customerId,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.category) {
          newPatient.category = {
            id: patientData.category.id
          };
        }
        if (patientData.digitalRace) {
          newPatient.digitalRace = {
            id: patientData.digitalRace.id
          };
        }
        if (patientData.gender) {
          newPatient.gender = {
            id: patientData.gender.id
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('patients.edit', data.patient.id);
        }, function (error) {});
      },
      openMergeCustomer: function openMergeCustomer() {

        this.get('controller').get('mergeCustomerPanel').send('load', this.get('controller').get('model'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-merge-customer-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showCreatePatient: function showCreatePatient() {
        $('#createPatientForm').attr('data-customer-id', this.get('controller').get('model').get('id'));

        this.get('controller').get('createPatientPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDocumentUpload: function openDocumentUpload() {
        this.get('controller').get('documentsPanel').send('openUploadPanel', 'customers', this.get('controller').get('model').id);
      },
      deleteMedia: function deleteMedia(id) {
        var self = this;

        $.ajax({
          url: "/api/media/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          self.reloadMedias();
        });
      },
      sendDocumentMail: function sendDocumentMail(id, data) {
        Ember['default'].$.ajax({
          url: "/api/patients/mailtodocument/" + id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');
        });
      },
      reloadMedias: function reloadMedias() {
        this.reloadMedias();
      },
      deleteAppointment: function deleteAppointment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/patientAppointments/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        }, function (error) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        }, function () {});
      },
      setInputDirty: function setInputDirty() {
        this.get('controller').set('inputDirty', true);
      },
      deletePatient: function deletePatient(id) {
        var controller = this.get('controller'),
            self = this;

        $.ajax({
          url: "/api/patients/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.selectTab('patients');
        }, function (data) {
          self.selectTab('patients');
        });
      },
      payout: function payout(data) {
        var self = this;

        data.customerId = this.get('controller').get('model').id;

        $.ajax({
          url: "/api/customers/payoutDeposit",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('transactions.show', data);
        });
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      applyCorrespondanceFilter: function applyCorrespondanceFilter() {
        var self = this;
        this.findPaged('customer-logbook-entry', {
          perPage: 10,
          paged: true,
          customerId: self.get('controller').get('model').get('id')
        }).then(function (entries) {
          self.get('controller').set('logbookEntries', entries);
        });
      },
      reload: function reload() {
        window.location.reload();
      },
      refresh: function refresh() {
        this.get('target.router').refresh();
      },
      toggleDead: function toggleDead(show) {
        this.get('controller').set('showDead', show);
      },
      toggleQuickFilter: function toggleQuickFilter(filter) {
        var controller = this.get('controller');
        controller.set('quickFilter', filter);
        this.selectTab('patients');
      },
      addEmail: function addEmail() {
        var model = this.get('controller').get('model');
        model.get("emails").pushObject(this.store.createRecord("customerContactInfo"));
      },
      removeEmail: function removeEmail(row) {
        var model = this.get('controller').get('model');
        model.get("emails").removeObject(row);
      },
      addPhone: function addPhone() {
        var model = this.get('controller').get('model');
        model.get("phones").pushObject(this.store.createRecord("customerContactInfo"));
      },
      addReduction: function addReduction() {
        var model = this.get('controller').get('model');
        model.get("reductions").pushObject(this.store.createRecord("customerReduction"));
      },
      removePhone: function removePhone(row) {
        var model = this.get('controller').get('model');
        model.get("phones").removeObject(row);
      },
      removeReduction: function removeReduction(row) {
        var model = this.get('controller').get('model');
        model.get("reductions").removeObject(row);
      },
      addFax: function addFax() {
        var model = this.get('controller').get('model');
        model.get("faxes").pushObject(this.store.createRecord("customerContactInfo"));
      },
      removeFax: function removeFax(row) {
        var model = this.get('controller').get('model');
        model.get("faxes").removeObject(row);
      },
      print: function print(customer) {
        window.open('/api/customers/balance/' + customer.id);
      },
      'delete': function _delete() {
        var self = this;

        $.ajax({
          url: "/api/customers/" + self.controller.get('model').id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('KundeIn', 'erfolgreich gelöscht');
          self.transitionTo('customers');
        }, function (error) {
          jason.notifiction.notifiy('KundeIn', 'erfolgreich gelöscht');
          self.transitionTo('customers');
        });
      }
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      controller.set('genders', this.store.find('gender'));
      controller.set('customerReductionTypes', this.store.find('customerReductionType'));
      controller.set('titles', this.store.find('title'));
      controller.set('productCategories', this.store.find('productCategory', { suggest: true }));
      controller.set('serviceCategories', this.store.find('serviceCategory', { suggest: true }));
      controller.set('countries', this.store.find('country'));
      controller.set('customerSalutations', this.store.find('customerSalutation'));

      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));

      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category', { includeNull: true }));

      controller.set('mediaCategories', this.store.find('media-category'));

      controller.set('uploadedAssets', null);
      controller.set('resources', this.store.find('appointment-resource'));
      controller.set("banktransferEnabled", API['default'].hasPermission('c_banktransfer'));

      controller.set('patientAppointmentCategories', this.store.find('patient-appointment-category'));

      if (model.get('birthday')) {
        model.set('birthdayInput', moment(model.get('birthday')).format('DD. MM. YYYY'));
      }

      controller.set("banktransferEnabled", API['default'].hasPermission('c_banktransfer'));
      controller.set('users', this.store.find('user', { per_page: 100 }));

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      $.ajax({
        url: "/api/paymentMorals",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('paymentMorals', data.paymentMoral);
        data.paymentMoral.forEach(function (item) {
          if (model.get('paymentMoral') && item.id == model.get('paymentMoral').get('id')) {
            controller.set('selectedPaymentMoral', item);
          }
        });
      });

      this.store.find('cashRegister', { hideInactive: true }).then(function (data) {
        controller.set('cashRegisterList', data);
        controller.set('selectedCashRegister', data.get('firstObject'));

        if (API['default'].getPrimaryCashRegisterId()) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });

      $.ajax({
        url: "/api/customerDeposits/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('deposits', data.customerDeposit);
        controller.set('depositSum', data.meta.depositSum);
        controller.set('balanceSum', data.meta.sum);
      });

      var selectedTab = params.queryParams.selectTab;
      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        this.selectTab('masterdata');
      }
    }
  });

});
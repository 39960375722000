define('jason-frontend/components/private-treatment-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    data: null,
    form: null,
    acceptData: false,
    loading: false,
    treatment: true,
    allowUnlinking: true,
    showLoading: false,
    enforceReadOnline: false,
    load: function load(treatmentPatientId, entryId, data, patient, customer, patientCategories, orderId, product, priceInfoId) {
      this.set('treatmentPatientId', treatmentPatientId);
      this.set('entryId', entryId);
      this.set('showLoading', false);
      this.set('product', product);
      this.set('patient', patient);

      if (data) {
        var formData = data.purchaseOrderLaboklinForm;
        this.set('form', formData);
      } else {
        this.set('form', null);
      }
    },
    actions: {
      getIdexxViewUrl: function getIdexxViewUrl(id) {

        var deferred = $.ajax({
          url: "/api/vetconnect/webView/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data) {
            window.open(data.responseText);
          } else {
            jason.notifiction.error("Fehler", "Idexx Ergebnislink konnte nicht gefunden werden");
          }
        }, function (data) {
          if (data) {
            window.open(data.responseText, "Idexx", "width=1024,height=768,status=no,scrollbars=yes,resizable=yes");
          } else {
            jason.notifiction.error("Fehler", "Idexx Ergebnislink konnte nicht gefunden werden");
          }
        });
      },
      openUrl: function openUrl(url, popup) {
        if (popup) {
          window.open(url, "Idexx", "width=1024,height=768,status=no,scrollbars=yes,resizable=yes");
        } else {
          window.open(url);
        }
      },
      load: function load(treatmentPatientId, entryId, data, patient, customer, patientCategories, orderId, product, priceInfoId) {
        this.load(treatmentPatientId, entryId, data, patient, customer, patientCategories, orderId, product, priceInfoId);
        this.set('article', null);
      },
      nextFreeNumber: function nextFreeNumber(nr) {
        this.set('orderNumber1', nr);
      },
      setVetconnectTest: function setVetconnectTest(test, devices) {
        this.set('vetconnectTest', test);
        this.set('devices', devices);
        if (devices) {
          this.set('device', devices[0]);
        }
        this.set('labProfile', null);
        this.set('type', 'vetconnect');
      },
      setVetconnectConnection: function setVetconnectConnection(status) {
        this.set('vetconnectConnectionStatus', status);
      },
      setLabProfile: function setLabProfile(labProfile) {
        this.set('labProfile', labProfile);
        this.set('vetconnectTest', null);
        this.set('type', 'scilvip');
      },
      downloadBarcode: function downloadBarcode(formId) {
        this.sendAction('downloadBarcode', formId);
      },
      downloadResult: function downloadResult(formId) {
        this.sendAction('downloadResult', formId);
      },
      downloadMedia: function downloadMedia(formId) {
        this.sendAction('downloadMedia', formId);
      },
      openLabResult: function openLabResult(formId) {
        this.sendAction('openLabResult', formId);
        $.magnificPopup.close();
      },
      downloadPdf: function downloadPdf(formId) {
        this.sendAction('downloadPdf', formId);
      },
      openForwardResult: function openForwardResult(formId) {
        this.sendAction('openForwardResult', formId);
      },
      submitLaboklin: function submitLaboklin(treatmentPatientId, formId) {
        this.set('showLoading', true);
        this.sendAction('submitLaboklin', treatmentPatientId, formId);
      },
      unlink: function unlink(id, formId) {
        this.sendAction('removeEntry', id, formId);
        $('#' + id + '_entry').hide();
      },
      confirm: function confirm(treatmentPatientId, entryId) {
        var _this = this;

        var self = this;

        var form = $('#privateTreatmentForm');

        var id = form.find('input[name=private-treatment-id]').val();

        var data;
        if (this.get('type') === 'scilvip') {
          data = {
            id: id,
            orderDescription: form.find('input[name=orderDescription]').val(),
            orderNumber1: form.find('input[name=orderNumber1]').val(),
            orderNumber2: form.find('input[name=orderNumber2]').val(),
            labEquipmentId: this.get('labProfile').labEquipment.id,
            labProfileId: this.get('labProfile').id,
            entryIds: this.get('entryId'),
            privateLab: true,
            type: 'scilvip'
          };
        } else if (this.get('type') === 'vetconnect') {
          (function () {

            data = {
              id: id,
              vetconnectTestId: _this.get('vetconnectTest').id,
              entryId: _this.get('entryId'),
              privateLab: true,
              deviceId: _this.get('device') ? _this.get('device').id : null,
              type: 'vetconnect'
            };

            var selectedIds = new Array();
            selectedIds.push(_this.get('entryId'));

            if (_this.get('vetconnectConnectionStatus').hasMore) {
              $(_this.get('vetconnectConnectionStatus').connectable).each(function () {
                if ($(this)[0].selected) {
                  selectedIds.push($(this)[0].entryId);
                }
              });
            }

            if (selectedIds.length > 0) {
              data.entryIds = selectedIds.join(',');
            }
          })();
        }

        if (this.get('treatment')) {
          this.set('loading', true);
          var deferred = $.ajax({
            url: "/api/treatmentPatients/" + treatmentPatientId + "/laboklinForm",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (formId) {

            self.sendAction('confirm', treatmentPatientId, data.entryIds, formId, id > 0);

            $.ajax({
              url: "/api/treatmentPatients/" + treatmentPatientId + "/laboklinForms/" + formId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.set('loading', false);
              self.load(treatmentPatientId, entryId, data, null, null, null, null, null);
            });
          }, function (error) {
            self.set('loading', false);
            if (error.responseText) {
              jason.notifiction.errorLong("Fehler", 'Unerwarteter Fehler');
            } else {
              jason.notifiction.errorLong("Achtung", 'Unbekannter Fehler');
            }
          });
        }
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});
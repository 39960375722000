define('jason-frontend/templates/components/patient-activities', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 6
            },
            "end": {
              "line": 84,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element38 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element38, 'class');
          morphs[1] = dom.createElementMorph(element38);
          morphs[2] = dom.createMorphAt(element38,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[82,61],[82,73]]]],"selected",""],[],["loc",[null,[82,40],[82,89]]]]]]],
          ["element","action",["toggleTag",["get","tag",["loc",[null,[83,100],[83,103]]]]],[],["loc",[null,[82,91],[83,105]]]],
          ["content","tag.name",["loc",[null,[83,106],[83,118]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 16
              },
              "end": {
                "line": 108,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element29, 'class');
            morphs[1] = dom.createMorphAt(element29,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["info-badge info-badge-very-small mr5 tag-",["get","tag.name",["loc",[null,[107,75],[107,83]]]]]]],
            ["content","tag.name",["loc",[null,[107,87],[107,99]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 18
                },
                "end": {
                  "line": 112,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-idexx");
              var el2 = dom.createTextNode("IDEXX");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 18
                  },
                  "end": {
                    "line": 114,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-laboklin");
                var el2 = dom.createTextNode("Laboklin");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 18
                    },
                    "end": {
                      "line": 116,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-intern");
                  var el2 = dom.createTextNode("Labor Intern");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 18
                  },
                  "end": {
                    "line": 116,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.privateLab",["loc",[null,[114,28],[114,51]]]]],[],0,null,["loc",[null,[114,18],[116,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 18
                },
                "end": {
                  "line": 116,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[112,32],[112,49]]]],"laboklin"],[],["loc",[null,[112,28],[112,61]]]]],[],0,1,["loc",[null,[112,18],[116,18]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 16
              },
              "end": {
                "line": 117,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[110,28],[110,45]]]],"vetconnect"],[],["loc",[null,[110,24],[110,59]]]]],[],0,1,["loc",[null,[110,18],[116,25]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 126,
                    "column": 20
                  },
                  "end": {
                    "line": 128,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("spann");
                dom.setAttribute(el1,"class","is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (TA)\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element28 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element28,0,0);
                morphs[1] = dom.createMorphAt(element28,2,2);
                return morphs;
              },
              statements: [
                ["content","item.payload.doctor.lastname",["loc",[null,[127,52],[127,84]]]],
                ["content","item.payload.doctor.firstname",["loc",[null,[127,85],[127,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 129,
                    "column": 18
                  },
                  "end": {
                    "line": 129,
                    "column": 79
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" und ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 130,
                    "column": 20
                  },
                  "end": {
                    "line": 132,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("spann");
                dom.setAttribute(el1,"class","is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (AS)\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element27 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element27,0,0);
                morphs[1] = dom.createMorphAt(element27,2,2);
                return morphs;
              },
              statements: [
                ["content","item.payload.assistent.lastname",["loc",[null,[131,52],[131,87]]]],
                ["content","item.payload.assistent.firstname",["loc",[null,[131,88],[131,124]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 16
                },
                "end": {
                  "line": 133,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  durch\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.doctor",["loc",[null,[126,26],[126,45]]]]],[],0,null,["loc",[null,[126,20],[128,27]]]],
              ["block","if",[["subexpr","and",[["get","item.payload.assistent",["loc",[null,[129,29],[129,51]]]],["get","item.payload.doctor",["loc",[null,[129,52],[129,71]]]]],[],["loc",[null,[129,24],[129,72]]]]],[],1,null,["loc",[null,[129,18],[129,86]]]],
              ["block","if",[["get","item.payload.assistent",["loc",[null,[130,26],[130,48]]]]],[],2,null,["loc",[null,[130,20],[132,27]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 122,
                "column": 14
              },
              "end": {
                "line": 134,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                Behandlung\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","item.payload.assistent",["loc",[null,[124,26],[124,48]]]],["get","item.payload.doctor",["loc",[null,[124,49],[124,68]]]]],[],["loc",[null,[124,22],[124,69]]]]],[],0,null,["loc",[null,[124,16],[133,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 134,
                  "column": 14
                },
                "end": {
                  "line": 136,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Erinnerung\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 136,
                    "column": 14
                  },
                  "end": {
                    "line": 138,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Notiz\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 138,
                      "column": 14
                    },
                    "end": {
                      "line": 140,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                Labor\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 140,
                        "column": 14
                      },
                      "end": {
                        "line": 142,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                Bildgebung\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 142,
                          "column": 14
                        },
                        "end": {
                          "line": 144,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                Rechnung\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 144,
                            "column": 14
                          },
                          "end": {
                            "line": 146,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                Impfung\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 146,
                              "column": 14
                            },
                            "end": {
                              "line": 148,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                E-Mail Weiterleitung\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 148,
                                "column": 14
                              },
                              "end": {
                                "line": 150,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                eltiga Nachricht\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 150,
                                  "column": 14
                                },
                                "end": {
                                  "line": 152,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                Dokument\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 152,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 154,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                Termin\n              ");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 152,
                                  "column": 14
                                },
                                "end": {
                                  "line": 154,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[152,28],[152,37]]]],"app"],[],["loc",[null,[152,24],[152,44]]]]],[],0,null,["loc",[null,[152,14],[154,14]]]]
                            ],
                            locals: [],
                            templates: [child0]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 150,
                                "column": 14
                              },
                              "end": {
                                "line": 154,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[150,28],[150,37]]]],"doc"],[],["loc",[null,[150,24],[150,44]]]]],[],0,1,["loc",[null,[150,14],[154,14]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 148,
                              "column": 14
                            },
                            "end": {
                              "line": 154,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[148,28],[148,37]]]],"com"],[],["loc",[null,[148,24],[148,44]]]]],[],0,1,["loc",[null,[148,14],[154,14]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 146,
                            "column": 14
                          },
                          "end": {
                            "line": 154,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[146,28],[146,37]]]],"mail"],[],["loc",[null,[146,24],[146,45]]]]],[],0,1,["loc",[null,[146,14],[154,14]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 144,
                          "column": 14
                        },
                        "end": {
                          "line": 154,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[144,28],[144,37]]]],"vacc"],[],["loc",[null,[144,24],[144,45]]]]],[],0,1,["loc",[null,[144,14],[154,14]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 142,
                        "column": 14
                      },
                      "end": {
                        "line": 154,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[142,28],[142,37]]]],"invoice"],[],["loc",[null,[142,24],[142,48]]]]],[],0,1,["loc",[null,[142,14],[154,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 140,
                      "column": 14
                    },
                    "end": {
                      "line": 154,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[140,28],[140,37]]]],"img"],[],["loc",[null,[140,24],[140,44]]]]],[],0,1,["loc",[null,[140,14],[154,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 138,
                    "column": 14
                  },
                  "end": {
                    "line": 154,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[138,28],[138,37]]]],"lab"],[],["loc",[null,[138,24],[138,44]]]]],[],0,1,["loc",[null,[138,14],[154,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 136,
                  "column": 14
                },
                "end": {
                  "line": 154,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[136,28],[136,37]]]],"not"],[],["loc",[null,[136,24],[136,44]]]]],[],0,1,["loc",[null,[136,14],[154,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 14
              },
              "end": {
                "line": 154,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[134,28],[134,37]]]],"rem"],[],["loc",[null,[134,24],[134,44]]]]],[],0,1,["loc",[null,[134,14],[154,14]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 160,
                  "column": 14
                },
                "end": {
                  "line": 162,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Kund:in\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 163,
                  "column": 14
                },
                "end": {
                  "line": 165,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Praxis\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 166,
                  "column": 14
                },
                "end": {
                  "line": 168,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Praxis\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 10
              },
              "end": {
                "line": 173,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [1]);
            var element26 = dom.childAt(fragment, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element25, 'class');
            morphs[1] = dom.createMorphAt(element25,1,1);
            morphs[2] = dom.createMorphAt(element25,2,2);
            morphs[3] = dom.createMorphAt(element25,3,3);
            morphs[4] = dom.createAttrMorph(element26, 'class');
            morphs[5] = dom.createUnsafeMorphAt(element26,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["message-sender mt20 ",["get","item.title",["loc",[null,[159,46],[159,56]]]]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[160,24],[160,34]]]],"msg_incoming"],[],["loc",[null,[160,20],[160,50]]]]],[],0,null,["loc",[null,[160,14],[162,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[163,24],[163,34]]]],"reminder"],[],["loc",[null,[163,20],[163,46]]]]],[],1,null,["loc",[null,[163,14],[165,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[166,24],[166,34]]]],"msg_outgoing"],[],["loc",[null,[166,20],[166,50]]]]],[],2,null,["loc",[null,[166,14],[168,21]]]],
            ["attribute","class",["concat",["message ",["get","item.title",["loc",[null,[170,34],[170,44]]]]]]],
            ["content","item.payload.text",["loc",[null,[171,14],[171,37]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 174,
                "column": 10
              },
              "end": {
                "line": 184,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var element24 = dom.childAt(element23, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element23, [1]),1,1);
            morphs[1] = dom.createElementMorph(element24);
            morphs[2] = dom.createMorphAt(element24,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[177,16],[177,39]]]],
            ["element","action",["openApp",["get","item.referenceId",["loc",[null,[179,36],[179,52]]]]],[],["loc",[null,[179,17],[179,54]]]],
            ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[181,16],[181,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 185,
                "column": 10
              },
              "end": {
                "line": 195,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var element22 = dom.childAt(element21, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element21, [1]),1,1);
            morphs[1] = dom.createElementMorph(element22);
            morphs[2] = dom.createMorphAt(element22,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[188,16],[188,39]]]],
            ["element","action",["editNote",["get","item.referenceId",["loc",[null,[190,37],[190,53]]]]],[],["loc",[null,[190,17],[190,55]]]],
            ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[192,16],[192,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 196,
                "column": 10
              },
              "end": {
                "line": 202,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[199,16],[199,39]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 203,
                "column": 10
              },
              "end": {
                "line": 209,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[206,16],[206,39]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 220,
                      "column": 45
                    },
                    "end": {
                      "line": 221,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[221,85],[221,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 219,
                    "column": 20
                  },
                  "end": {
                    "line": 223,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[220,22],[220,42]]]],
                ["block","link-to",["services.edit",["get","article.id",["loc",[null,[220,72],[220,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[220,45],[221,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[222,48],[222,61]]]]],[],[]]],["loc",[null,[221,131],[222,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 217,
                  "column": 18
                },
                "end": {
                  "line": 224,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Leistungen:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.services",["loc",[null,[219,28],[219,49]]]]],[],0,null,["loc",[null,[219,20],[223,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 228,
                      "column": 45
                    },
                    "end": {
                      "line": 229,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[229,85],[229,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 227,
                    "column": 20
                  },
                  "end": {
                    "line": 231,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[228,22],[228,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[228,72],[228,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[228,45],[229,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[230,48],[230,61]]]]],[],[]]],["loc",[null,[229,131],[230,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 225,
                  "column": 18
                },
                "end": {
                  "line": 232,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Produkte:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.products",["loc",[null,[227,28],[227,49]]]]],[],0,null,["loc",[null,[227,20],[231,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 236,
                      "column": 45
                    },
                    "end": {
                      "line": 237,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[237,85],[237,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 235,
                    "column": 20
                  },
                  "end": {
                    "line": 239,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[236,22],[236,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[236,72],[236,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[236,45],[237,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[238,48],[238,61]]]]],[],[]]],["loc",[null,[237,131],[238,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 233,
                  "column": 18
                },
                "end": {
                  "line": 240,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Medikamente:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.meds",["loc",[null,[235,28],[235,45]]]]],[],0,null,["loc",[null,[235,20],[239,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 244,
                      "column": 45
                    },
                    "end": {
                      "line": 245,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[245,85],[245,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 243,
                    "column": 20
                  },
                  "end": {
                    "line": 247,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[244,22],[244,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[244,72],[244,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[244,45],[245,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[246,48],[246,61]]]]],[],[]]],["loc",[null,[245,131],[246,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 241,
                  "column": 18
                },
                "end": {
                  "line": 248,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Ernährung:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.feeding",["loc",[null,[243,28],[243,48]]]]],[],0,null,["loc",[null,[243,20],[247,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 252,
                  "column": 14
                },
                "end": {
                  "line": 255,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[254,16],[254,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 210,
                "column": 10
              },
              "end": {
                "line": 257,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("b");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(", Gesamtsumme: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" EUR\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var element19 = dom.childAt(element18, [1]);
            var element20 = dom.childAt(element19, [5]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
            morphs[1] = dom.createMorphAt(element19,3,3);
            morphs[2] = dom.createMorphAt(element20,1,1);
            morphs[3] = dom.createMorphAt(element20,2,2);
            morphs[4] = dom.createMorphAt(element20,3,3);
            morphs[5] = dom.createMorphAt(element20,4,4);
            morphs[6] = dom.createMorphAt(element18,3,3);
            return morphs;
          },
          statements: [
            ["content","item.payload.code",["loc",[null,[213,19],[213,40]]]],
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.payload.total",["loc",[null,[214,83],[214,101]]]]],[],[]]],["loc",[null,[213,59],[214,103]]]],
            ["block","if",[["get","item.payload.services",["loc",[null,[217,24],[217,45]]]]],[],0,null,["loc",[null,[217,18],[224,25]]]],
            ["block","if",[["get","item.payload.products",["loc",[null,[225,24],[225,45]]]]],[],1,null,["loc",[null,[225,18],[232,25]]]],
            ["block","if",[["get","item.payload.meds",["loc",[null,[233,24],[233,41]]]]],[],2,null,["loc",[null,[233,18],[240,25]]]],
            ["block","if",[["get","item.payload.feeding",["loc",[null,[241,24],[241,44]]]]],[],3,null,["loc",[null,[241,18],[248,25]]]],
            ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[252,41],[252,57]]]]],["classNames","icon-button icon-button--default list-action-square exp line-action align-items-center"],4,null,["loc",[null,[252,14],[255,26]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 262,
                    "column": 18
                  },
                  "end": {
                    "line": 264,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Eine eltiga App Benutzer:in hat das Dokument ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","is-highlighted cursor");
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" geteilt.\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element15);
                morphs[1] = dom.createMorphAt(dom.childAt(element15, [0]),0,0);
                return morphs;
              },
              statements: [
                ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[263,122],[263,138]]]]],[],["loc",[null,[263,98],[263,140]]]],
                ["content","item.payload.filename",["loc",[null,[263,144],[263,169]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 264,
                    "column": 18
                  },
                  "end": {
                    "line": 266,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Die Praxis hat das Dokument ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","is-highlighted cursor");
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" hinzugefügt.\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element14);
                morphs[1] = dom.createMorphAt(dom.childAt(element14, [0]),0,0);
                return morphs;
              },
              statements: [
                ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[265,105],[265,121]]]]],[],["loc",[null,[265,81],[265,123]]]],
                ["content","item.payload.filename",["loc",[null,[265,127],[265,152]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 261,
                  "column": 16
                },
                "end": {
                  "line": 267,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.ctx",["loc",[null,[262,28],[262,44]]]],"eltiga"],[],["loc",[null,[262,24],[262,54]]]]],[],0,1,["loc",[null,[262,18],[266,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 267,
                  "column": 16
                },
                "end": {
                  "line": 269,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[268,18],[268,41]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 258,
                "column": 10
              },
              "end": {
                "line": 276,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action align-items-center");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
            morphs[1] = dom.createElementMorph(element17);
            morphs[2] = dom.createMorphAt(element17,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.payload.filename",["loc",[null,[261,22],[261,43]]]]],[],0,1,["loc",[null,[261,16],[269,23]]]],
            ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[271,41],[271,57]]]]],[],["loc",[null,[271,17],[271,59]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[273,16],[273,71]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 277,
                "column": 10
              },
              "end": {
                "line": 285,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 22px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/vacc.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[282,16],[282,37]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 292,
                  "column": 16
                },
                "end": {
                  "line": 293,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 293,
                    "column": 16
                  },
                  "end": {
                    "line": 294,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 294,
                      "column": 16
                    },
                    "end": {
                      "line": 295,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 295,
                      "column": 16
                    },
                    "end": {
                      "line": 297,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.orderTypeString",["loc",[null,[296,18],[296,50]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 294,
                    "column": 16
                  },
                  "end": {
                    "line": 297,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.privateLab",["loc",[null,[294,26],[294,49]]]]],[],0,1,["loc",[null,[294,16],[297,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 293,
                  "column": 16
                },
                "end": {
                  "line": 297,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[293,30],[293,47]]]],"laboklin"],[],["loc",[null,[293,26],[293,59]]]]],[],0,1,["loc",[null,[293,16],[297,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 297,
                  "column": 24
                },
                "end": {
                  "line": 297,
                  "column": 132
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block small");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["inline","laboklin-reporttype",[],["type",["subexpr","@mut",[["get","form.reportType",["loc",[null,[297,108],[297,123]]]]],[],[]]],["loc",[null,[297,81],[297,125]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 10
              },
              "end": {
                "line": 307,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 30px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/lab.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var element13 = dom.childAt(element12, [9]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(element12,3,3);
            morphs[1] = dom.createMorphAt(element12,5,5);
            morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element12, [7]),1,1);
            morphs[3] = dom.createElementMorph(element13);
            morphs[4] = dom.createMorphAt(element13,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[292,26],[292,43]]]],"vetconnect"],[],["loc",[null,[292,22],[292,57]]]]],[],0,1,["loc",[null,[292,16],[297,23]]]],
            ["block","if",[["get","item.payload.reportType",["loc",[null,[297,30],[297,53]]]]],[],2,null,["loc",[null,[297,24],[297,139]]]],
            ["content","item.payload.text",["loc",[null,[300,16],[300,39]]]],
            ["element","action",["openLab",["get","item.referenceId",["loc",[null,[302,36],[302,52]]]]],[],["loc",[null,[302,17],[302,54]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[304,16],[304,71]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 308,
                "column": 10
              },
              "end": {
                "line": 320,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center img detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 30px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/img.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [5]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
            morphs[1] = dom.createElementMorph(element11);
            morphs[2] = dom.createMorphAt(element11,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[313,16],[313,37]]]],
            ["element","action",["openLab",["get","item.referenceId",["loc",[null,[315,36],[315,52]]]]],[],["loc",[null,[315,17],[315,54]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[317,16],[317,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 324,
                  "column": 16
                },
                "end": {
                  "line": 326,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Anamnese:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.medicalHistory",["loc",[null,[325,65],[325,98]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 327,
                  "column": 16
                },
                "end": {
                  "line": 329,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Symptome:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.sypmtoms",["loc",[null,[328,65],[328,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 330,
                  "column": 16
                },
                "end": {
                  "line": 332,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Diagnose:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.diagnosis",["loc",[null,[331,65],[331,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 333,
                  "column": 16
                },
                "end": {
                  "line": 335,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Therapie:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.therapy",["loc",[null,[334,65],[334,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 336,
                  "column": 16
                },
                "end": {
                  "line": 338,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Beratung:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.report",["loc",[null,[337,65],[337,90]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 339,
                  "column": 16
                },
                "end": {
                  "line": 341,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Tierärztliche Leistungen:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.manualTreatment",["loc",[null,[340,81],[340,115]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 345,
                      "column": 43
                    },
                    "end": {
                      "line": 346,
                      "column": 99
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[346,83],[346,99]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 344,
                    "column": 18
                  },
                  "end": {
                    "line": 347,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[345,20],[345,40]]]],
                ["block","link-to",["services.edit",["get","article.id",["loc",[null,[345,70],[345,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[345,43],[346,111]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 342,
                  "column": 16
                },
                "end": {
                  "line": 348,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Leistungen:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.services",["loc",[null,[344,26],[344,47]]]]],[],0,null,["loc",[null,[344,18],[347,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 352,
                      "column": 43
                    },
                    "end": {
                      "line": 353,
                      "column": 99
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[353,83],[353,99]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 351,
                    "column": 18
                  },
                  "end": {
                    "line": 354,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[352,20],[352,40]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[352,70],[352,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[352,43],[353,111]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 349,
                  "column": 16
                },
                "end": {
                  "line": 355,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Produkte:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.products",["loc",[null,[351,26],[351,47]]]]],[],0,null,["loc",[null,[351,18],[354,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 359,
                      "column": 43
                    },
                    "end": {
                      "line": 360,
                      "column": 99
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[360,83],[360,99]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 358,
                    "column": 18
                  },
                  "end": {
                    "line": 361,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[359,20],[359,40]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[359,70],[359,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[359,43],[360,111]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 356,
                  "column": 16
                },
                "end": {
                  "line": 362,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Medikamente:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.meds",["loc",[null,[358,26],[358,43]]]]],[],0,null,["loc",[null,[358,18],[361,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 366,
                      "column": 43
                    },
                    "end": {
                      "line": 367,
                      "column": 99
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[367,83],[367,99]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 365,
                    "column": 18
                  },
                  "end": {
                    "line": 368,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[366,20],[366,40]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[366,70],[366,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[366,43],[367,111]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 363,
                  "column": 16
                },
                "end": {
                  "line": 369,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Ernährung:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.feeding",["loc",[null,[365,26],[365,46]]]]],[],0,null,["loc",[null,[365,18],[368,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 373,
                      "column": 20
                    },
                    "end": {
                      "line": 379,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                  dom.setAttribute(el2,"class","mr5");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","is-highlighted cursor");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element7);
                  morphs[1] = dom.createMorphAt(element7,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["gotoReport",["get","item.referenceId",["loc",[null,[376,79],[376,95]]]],["get","report.id",["loc",[null,[377,66],[377,75]]]]],[],["loc",[null,[376,57],[377,77]]]],
                  ["content","report.name",["loc",[null,[377,78],[377,93]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 372,
                    "column": 18
                  },
                  "end": {
                    "line": 380,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","report.name",["loc",[null,[373,26],[373,37]]]]],[],0,null,["loc",[null,[373,20],[379,27]]]]
              ],
              locals: ["report"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 370,
                  "column": 16
                },
                "end": {
                  "line": 381,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Berichte:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.reports",["loc",[null,[372,26],[372,46]]]]],[],0,null,["loc",[null,[372,18],[380,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 385,
                    "column": 16
                  },
                  "end": {
                    "line": 392,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"style","margin-right: 45px");
                dom.setAttribute(el1,"class","icon-button list-action-square line-action");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-hide-app-with-confirm",[],["confirmed","hideTreatment","size","28","id",["subexpr","@mut",[["get","item.referenceId",["loc",[null,[388,54],[388,70]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App Benutzer:innen wirklich ausblenden?"],["loc",[null,[387,20],[390,138]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 392,
                    "column": 16
                  },
                  "end": {
                    "line": 398,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"style","margin-right: 45px");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element5);
                morphs[1] = dom.createMorphAt(element5,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["unhideTreatment",["get","item.referenceId",["loc",[null,[393,75],[393,91]]]]],[],["loc",[null,[393,48],[393,93]]]],
                ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[396,68],[396,79]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[396,48],[396,143]]]]],["loc",[null,[395,20],[396,145]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 384,
                  "column": 14
                },
                "end": {
                  "line": 405,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createElementMorph(element6);
              morphs[2] = dom.createMorphAt(element6,1,1);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.eltigaShared",["loc",[null,[385,22],[385,47]]]]],[],0,1,["loc",[null,[385,16],[398,23]]]],
              ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[401,44],[401,60]]]]],[],["loc",[null,[401,19],[401,62]]]],
              ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[403,18],[403,73]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child12 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 417,
                    "column": 20
                  },
                  "end": {
                    "line": 421,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","dropdown-item");
                var el2 = dom.createTextNode("\n                        Bericht erstellen\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [
                ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[418,54],[418,70]]]]],[],["loc",[null,[418,25],[418,72]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 421,
                      "column": 20
                    },
                    "end": {
                      "line": 425,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","dropdown-item");
                  var el2 = dom.createTextNode("\n                        Bericht(e) bearbeiten\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[422,54],[422,70]]]]],[],["loc",[null,[422,25],[422,72]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 421,
                    "column": 20
                  },
                  "end": {
                    "line": 425,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","item.payload.reportCount",["loc",[null,[421,34],[421,58]]]],0],[],["loc",[null,[421,30],[421,61]]]]],[],0,null,["loc",[null,[421,20],[425,20]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 407,
                  "column": 14
                },
                "end": {
                  "line": 428,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","dropdown");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
              dom.setAttribute(el2,"data-toggle","dropdown");
              dom.setAttribute(el2,"aria-haspopup","true");
              dom.setAttribute(el2,"aria-expanded","false");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("Mehr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown-menu");
              dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","dropdown-item");
              var el4 = dom.createTextNode("\n                      Bearbeiten\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
              morphs[1] = dom.createElementMorph(element4);
              morphs[2] = dom.createMorphAt(element3,3,3);
              return morphs;
            },
            statements: [
              ["inline","button-more-hor",[],["showStroke",false,"color","#fff","size",40,"content",""],["loc",[null,[411,20],[411,88]]]],
              ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[414,48],[414,64]]]]],[],["loc",[null,[414,23],[414,66]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.reportCount",["loc",[null,[417,30],[417,54]]]],0],[],["loc",[null,[417,26],[417,57]]]]],[],0,1,["loc",[null,[417,20],[425,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 321,
                "column": 10
              },
              "end": {
                "line": 433,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var morphs = new Array(13);
            morphs[0] = dom.createMorphAt(element9,1,1);
            morphs[1] = dom.createMorphAt(element9,2,2);
            morphs[2] = dom.createMorphAt(element9,3,3);
            morphs[3] = dom.createMorphAt(element9,4,4);
            morphs[4] = dom.createMorphAt(element9,5,5);
            morphs[5] = dom.createMorphAt(element9,6,6);
            morphs[6] = dom.createMorphAt(element9,7,7);
            morphs[7] = dom.createMorphAt(element9,8,8);
            morphs[8] = dom.createMorphAt(element9,9,9);
            morphs[9] = dom.createMorphAt(element9,10,10);
            morphs[10] = dom.createMorphAt(element9,11,11);
            morphs[11] = dom.createMorphAt(element8,3,3);
            morphs[12] = dom.createMorphAt(element8,5,5);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.payload.medicalHistory",["loc",[null,[324,22],[324,49]]]]],[],0,null,["loc",[null,[324,16],[326,23]]]],
            ["block","if",[["get","item.payload.sypmtoms",["loc",[null,[327,22],[327,43]]]]],[],1,null,["loc",[null,[327,16],[329,23]]]],
            ["block","if",[["get","item.payload.diagnosis",["loc",[null,[330,22],[330,44]]]]],[],2,null,["loc",[null,[330,16],[332,23]]]],
            ["block","if",[["get","item.payload.therapy",["loc",[null,[333,22],[333,42]]]]],[],3,null,["loc",[null,[333,16],[335,23]]]],
            ["block","if",[["get","item.payload.report",["loc",[null,[336,22],[336,41]]]]],[],4,null,["loc",[null,[336,16],[338,23]]]],
            ["block","if",[["get","item.payload.manualTreatment",["loc",[null,[339,22],[339,50]]]]],[],5,null,["loc",[null,[339,16],[341,23]]]],
            ["block","if",[["get","item.payload.services",["loc",[null,[342,22],[342,43]]]]],[],6,null,["loc",[null,[342,16],[348,23]]]],
            ["block","if",[["get","item.payload.products",["loc",[null,[349,22],[349,43]]]]],[],7,null,["loc",[null,[349,16],[355,23]]]],
            ["block","if",[["get","item.payload.meds",["loc",[null,[356,22],[356,39]]]]],[],8,null,["loc",[null,[356,16],[362,23]]]],
            ["block","if",[["get","item.payload.feeding",["loc",[null,[363,22],[363,42]]]]],[],9,null,["loc",[null,[363,16],[369,23]]]],
            ["block","if",[["get","item.payload.reports",["loc",[null,[370,22],[370,42]]]]],[],10,null,["loc",[null,[370,16],[381,23]]]],
            ["block","unless",[["get","item.payload.eltigaRecord",["loc",[null,[384,24],[384,49]]]]],[],11,null,["loc",[null,[384,14],[405,25]]]],
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[407,20],[407,28]]]]],[],12,null,["loc",[null,[407,14],[428,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 4
            },
            "end": {
              "line": 437,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","timeline-item");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","timeline-line");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pe-3 mb10");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex align-items-center mt-1 fs-6");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","me-2 fs-7");
          var el6 = dom.createElement("b");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(", ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                um ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                Uhr");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","tags ml20");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","headline");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element30 = dom.childAt(fragment, [1]);
          var element31 = dom.childAt(element30, [3]);
          var element32 = dom.childAt(element31, [1, 1]);
          var element33 = dom.childAt(element30, [5]);
          var element34 = dom.childAt(element33, [1]);
          var element35 = dom.childAt(element34, [1]);
          var element36 = dom.childAt(element35, [1, 0]);
          var element37 = dom.childAt(element35, [3]);
          var morphs = new Array(20);
          morphs[0] = dom.createAttrMorph(element31, 'class');
          morphs[1] = dom.createAttrMorph(element32, 'src');
          morphs[2] = dom.createAttrMorph(element33, 'class');
          morphs[3] = dom.createMorphAt(element36,0,0);
          morphs[4] = dom.createMorphAt(element36,2,2);
          morphs[5] = dom.createMorphAt(element36,4,4);
          morphs[6] = dom.createMorphAt(element37,1,1);
          morphs[7] = dom.createMorphAt(element37,2,2);
          morphs[8] = dom.createMorphAt(dom.childAt(element34, [3]),1,1);
          morphs[9] = dom.createMorphAt(element33,3,3);
          morphs[10] = dom.createMorphAt(element33,4,4);
          morphs[11] = dom.createMorphAt(element33,5,5);
          morphs[12] = dom.createMorphAt(element33,6,6);
          morphs[13] = dom.createMorphAt(element33,7,7);
          morphs[14] = dom.createMorphAt(element33,8,8);
          morphs[15] = dom.createMorphAt(element33,9,9);
          morphs[16] = dom.createMorphAt(element33,10,10);
          morphs[17] = dom.createMorphAt(element33,11,11);
          morphs[18] = dom.createMorphAt(element33,12,12);
          morphs[19] = dom.createMorphAt(element33,13,13);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["timeline-icon color-",["get","item.type",["loc",[null,[94,42],[94,51]]]]]]],
          ["attribute","src",["concat",["/assets/images/icons/history/",["get","item.type",["loc",[null,[96,53],[96,62]]]],".svg"]]],
          ["attribute","class",["concat",["timeline-content ",["get","item.type",["loc",[null,[99,39],[99,48]]]]," mb-10 mt-n1"]]],
          ["inline","get-weekday",[["get","item.date",["loc",[null,[102,54],[102,63]]]]],[],["loc",[null,[102,40],[102,65]]]],
          ["inline","time-format",[["get","item.date",["loc",[null,[102,81],[102,90]]]],"DD. MM. YYYY"],[],["loc",[null,[102,67],[102,107]]]],
          ["inline","time-format",[["get","item.date",["loc",[null,[103,33],[103,42]]]],"HH:mm"],[],["loc",[null,[103,19],[103,52]]]],
          ["block","each",[["get","item.tags",["loc",[null,[106,24],[106,33]]]]],[],0,null,["loc",[null,[106,16],[108,25]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[109,26],[109,35]]]],"lab"],[],["loc",[null,[109,22],[109,42]]]]],[],1,null,["loc",[null,[109,16],[117,23]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[122,24],[122,33]]]],"treat"],[],["loc",[null,[122,20],[122,42]]]]],[],2,3,["loc",[null,[122,14],[154,21]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[158,20],[158,29]]]],"com"],[],["loc",[null,[158,16],[158,36]]]]],[],4,null,["loc",[null,[158,10],[173,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[174,20],[174,29]]]],"app"],[],["loc",[null,[174,16],[174,36]]]]],[],5,null,["loc",[null,[174,10],[184,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[185,20],[185,29]]]],"not"],[],["loc",[null,[185,16],[185,36]]]]],[],6,null,["loc",[null,[185,10],[195,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[196,20],[196,29]]]],"rem"],[],["loc",[null,[196,16],[196,36]]]]],[],7,null,["loc",[null,[196,10],[202,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[203,20],[203,29]]]],"mail"],[],["loc",[null,[203,16],[203,37]]]]],[],8,null,["loc",[null,[203,10],[209,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[210,20],[210,29]]]],"invoice"],[],["loc",[null,[210,16],[210,40]]]]],[],9,null,["loc",[null,[210,10],[257,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[258,20],[258,29]]]],"doc"],[],["loc",[null,[258,16],[258,36]]]]],[],10,null,["loc",[null,[258,10],[276,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[277,20],[277,29]]]],"vacc"],[],["loc",[null,[277,16],[277,37]]]]],[],11,null,["loc",[null,[277,10],[285,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[286,20],[286,29]]]],"lab"],[],["loc",[null,[286,16],[286,36]]]]],[],12,null,["loc",[null,[286,10],[307,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[308,20],[308,29]]]],"img"],[],["loc",[null,[308,16],[308,36]]]]],[],13,null,["loc",[null,[308,10],[320,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[321,20],[321,29]]]],"treat"],[],["loc",[null,[321,16],[321,38]]]]],[],14,null,["loc",[null,[321,10],[433,17]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 502,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","patientActivityWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter standalone col l-24/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Aktivitäten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"style","padding-right: 9px");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"style","line-height: 30px");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/treat.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Behandlungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/vacc.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Impfungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/img.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Bildgebung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/lab.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Labor");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/app.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Termine");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/com.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        eltiga Nachrichten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/mail.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        E-Mail");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/invoice.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Rechnungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/doc.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Dokumente");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/not.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Notizen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/rem.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Erinnerungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-5/24@desk l-24/24 mt10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","pickDate");
        dom.setAttribute(el4,"class","date-picker input-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title mt0");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","field-icon");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6,"class","fa fa-calendar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-1/24@desk u-hide@phone u-hide@tablet");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter mt10 col l-18/24@desk  l-24/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title mb10");
        var el5 = dom.createTextNode("Filter für Behandlungen und Rechnungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","timeline");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateJournal').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Alle\": [moment('2016-01-01T00:00:00.000'), moment()],\n        \"Letzten 30 Tage\": [moment().subtract(30, 'days'), moment()],\n        \"Letzten 6 Monate\": [moment().subtract(6, 'months'), moment()],\n        \"Dieses Jahr\": [moment().startOf('year'), moment()],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element39 = dom.childAt(fragment, [0]);
        var element40 = dom.childAt(element39, [1]);
        var element41 = dom.childAt(element40, [1]);
        var element42 = dom.childAt(element41, [3]);
        var element43 = dom.childAt(element41, [5]);
        var element44 = dom.childAt(element41, [7]);
        var element45 = dom.childAt(element41, [9]);
        var element46 = dom.childAt(element41, [11]);
        var element47 = dom.childAt(element41, [13]);
        var element48 = dom.childAt(element41, [15]);
        var element49 = dom.childAt(element41, [17]);
        var element50 = dom.childAt(element41, [19]);
        var element51 = dom.childAt(element41, [21]);
        var element52 = dom.childAt(element41, [23]);
        var element53 = dom.childAt(element41, [25]);
        var element54 = dom.childAt(element39, [3]);
        var morphs = new Array(30);
        morphs[0] = dom.createAttrMorph(element42, 'class');
        morphs[1] = dom.createElementMorph(element42);
        morphs[2] = dom.createAttrMorph(element43, 'class');
        morphs[3] = dom.createElementMorph(element43);
        morphs[4] = dom.createAttrMorph(element44, 'class');
        morphs[5] = dom.createElementMorph(element44);
        morphs[6] = dom.createAttrMorph(element45, 'class');
        morphs[7] = dom.createElementMorph(element45);
        morphs[8] = dom.createAttrMorph(element46, 'class');
        morphs[9] = dom.createElementMorph(element46);
        morphs[10] = dom.createAttrMorph(element47, 'class');
        morphs[11] = dom.createElementMorph(element47);
        morphs[12] = dom.createAttrMorph(element48, 'class');
        morphs[13] = dom.createElementMorph(element48);
        morphs[14] = dom.createAttrMorph(element49, 'class');
        morphs[15] = dom.createElementMorph(element49);
        morphs[16] = dom.createAttrMorph(element50, 'class');
        morphs[17] = dom.createElementMorph(element50);
        morphs[18] = dom.createAttrMorph(element51, 'class');
        morphs[19] = dom.createElementMorph(element51);
        morphs[20] = dom.createAttrMorph(element52, 'class');
        morphs[21] = dom.createElementMorph(element52);
        morphs[22] = dom.createAttrMorph(element53, 'class');
        morphs[23] = dom.createElementMorph(element53);
        morphs[24] = dom.createMorphAt(dom.childAt(element40, [3, 1]),3,3);
        morphs[25] = dom.createMorphAt(dom.childAt(element40, [7]),3,3);
        morphs[26] = dom.createMorphAt(element54,1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element54, [3]),1,1);
        morphs[28] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[29] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item align-items-center color-all ",["subexpr","css-bool-evaluator",[["get","typeAllSelected",["loc",[null,[6,86],[6,101]]]],"selected-style",""],[],["loc",[null,[6,65],[7,91]]]]]]],
        ["element","action",["toggleTypeFilter","all"],[],["loc",[null,[7,93],[8,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-treat ",["subexpr","css-bool-evaluator",[["get","typeTreatmentSelected",["loc",[null,[9,88],[9,109]]]],"selected-style",""],[],["loc",[null,[9,67],[10,110]]]]]]],
        ["element","action",["toggleTypeFilter","treat"],[],["loc",[null,[10,112],[11,36]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-vacc ",["subexpr","css-bool-evaluator",[["get","typeVaccSelected",["loc",[null,[14,87],[14,103]]]],"selected-style",""],[],["loc",[null,[14,66],[15,92]]]]]]],
        ["element","action",["toggleTypeFilter","vacc"],[],["loc",[null,[15,94],[16,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-img ",["subexpr","css-bool-evaluator",[["get","typeImgSelected",["loc",[null,[19,86],[19,101]]]],"selected-style",""],[],["loc",[null,[19,65],[20,91]]]]]]],
        ["element","action",["toggleTypeFilter","img"],[],["loc",[null,[20,93],[21,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-lab ",["subexpr","css-bool-evaluator",[["get","typeLabSelected",["loc",[null,[24,86],[24,101]]]],"selected-style",""],[],["loc",[null,[24,65],[25,91]]]]]]],
        ["element","action",["toggleTypeFilter","lab"],[],["loc",[null,[25,93],[26,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-app ",["subexpr","css-bool-evaluator",[["get","typeAppSelected",["loc",[null,[29,86],[29,101]]]],"selected-style",""],[],["loc",[null,[29,65],[30,91]]]]]]],
        ["element","action",["toggleTypeFilter","app"],[],["loc",[null,[30,93],[31,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-com ",["subexpr","css-bool-evaluator",[["get","typeComSelected",["loc",[null,[34,86],[34,101]]]],"selected-style",""],[],["loc",[null,[34,65],[35,91]]]]]]],
        ["element","action",["toggleTypeFilter","com"],[],["loc",[null,[35,93],[36,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-mail ",["subexpr","css-bool-evaluator",[["get","typeMailSelected",["loc",[null,[39,87],[39,103]]]],"selected-style",""],[],["loc",[null,[39,66],[40,92]]]]]]],
        ["element","action",["toggleTypeFilter","mail"],[],["loc",[null,[40,94],[41,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-invoice ",["subexpr","css-bool-evaluator",[["get","typeInvoiceSelected",["loc",[null,[44,90],[44,109]]]],"selected-style",""],[],["loc",[null,[44,69],[46,95]]]]]]],
        ["element","action",["toggleTypeFilter","invoice"],[],["loc",[null,[46,97],[47,38]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-doc ",["subexpr","css-bool-evaluator",[["get","typeDocSelected",["loc",[null,[50,86],[50,101]]]],"selected-style",""],[],["loc",[null,[50,65],[51,91]]]]]]],
        ["element","action",["toggleTypeFilter","doc"],[],["loc",[null,[51,93],[52,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-not ",["subexpr","css-bool-evaluator",[["get","typeNotSelected",["loc",[null,[55,86],[55,101]]]],"selected-style",""],[],["loc",[null,[55,65],[56,91]]]]]]],
        ["element","action",["toggleTypeFilter","not"],[],["loc",[null,[56,93],[57,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-rem ",["subexpr","css-bool-evaluator",[["get","typeRemSelected",["loc",[null,[60,86],[60,101]]]],"selected-style",""],[],["loc",[null,[60,65],[61,91]]]]]]],
        ["element","action",["toggleTypeFilter","rem"],[],["loc",[null,[61,93],[62,34]]]],
        ["inline","input",[],["id","pickDateJournal","value",["subexpr","@mut",[["get","pickDateJournal",["loc",[null,[69,43],[69,58]]]]],[],[]],"class","new-style input input--date-picker ember-view ember-text-field","name","pickDateJournal"],["loc",[null,[69,8],[70,112]]]],
        ["block","each",[["get","availableTags",["loc",[null,[81,14],[81,27]]]]],[],0,null,["loc",[null,[81,6],[84,15]]]],
        ["block","each",[["get","items",["loc",[null,[90,12],[90,17]]]]],[],1,null,["loc",[null,[90,4],[437,13]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[439,29],[439,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[439,6],[439,53]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","openWizard","openReportWizard","delete","deleteReport","prefix","-journal","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooserActivities",["loc",[null,[449,17],[449,56]]]]],[],[]]],["loc",[null,[444,0],[449,58]]]],
        ["inline","treatment-report-wizzard-journal",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzardJournal",["loc",[null,[453,17],[453,46]]]]],[],[]]],["loc",[null,[451,0],[453,48]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
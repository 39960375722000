define('jason-frontend/helpers/is-treatment-editable', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isTreatmentEditable = isTreatmentEditable;

  function isTreatmentEditable(params) {
    var state = params[0];

    if (state && state.get('state') && state.get('state').id === 'closed') {
      return false;
    }

    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isTreatmentEditable);

});
define('jason-frontend/models/user', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    active: DS['default'].attr('boolean'),
    firstname: DS['default'].attr('string'),
    fullId: DS['default'].attr('string'),
    shortName: DS['default'].attr('string'),
    lastname: DS['default'].attr('string'),
    username: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    selected: DS['default'].attr('boolean'),
    password: DS['default'].attr('string'),
    color: DS['default'].attr('string'),
    colorHex: DS['default'].attr(''),
    capitals: DS['default'].attr(''),
    phone: DS['default'].attr('string'),
    picture: DS['default'].belongsTo('media'),
    useRoleInCashDesk: DS['default'].attr('boolean'),
    primaryCashRegister: DS['default'].belongsTo('cashRegister'),
    primaryInventoryWarehouse: DS['default'].belongsTo('inventory-warehouse'),
    uploadedPicture: DS['default'].attr('string'),
    gender: DS['default'].belongsTo('gender', { async: true }),
    title: DS['default'].belongsTo('title', { async: true }),
    userRole: DS['default'].belongsTo('userRole', { async: true }),
    maxInactivityTime: DS['default'].attr('number'),
    showInactivityLabel: DS['default'].attr('boolean')
  });

});
define('jason-frontend/routes/practicemgmt/treatment-future-action', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var refererId = params.params['practicemgmt.treatment-future-action'].refererId,
          futureId = params.params['practicemgmt.treatment-future-action'].id,
          type = params.params['practicemgmt.treatment-future-action'].type,
          self = this;

      controller.set('refererType', type);
      controller.set('refererId', refererId);
      if (type === 'treatment' || type === 'patient') {
        controller.set('apiType', 'treatmentFutureActions');
        controller.set('modelDef', 'treatment-future-action');
      } else {
        controller.set('apiType', 'futureActionBlueprints');
        controller.set('modelDef', 'future-action-blueprint');
      }

      this.store.fetch(controller.get('modelDef'), futureId).then(function (data) {
        controller.set('future', data);
        if (data.get('nextExecution')) {
          controller.set('nextExecution', moment(data.get('nextExecution')).format('DD. MM. YYYY'));
          controller.set('nextExecutionTime', moment(data.get('nextExecution')).format('HH:mm'));
        }
      });

      this.store.find('future-action-type').then(function (items) {
        controller.set('futureTypes', items.sortBy('position'));
      });

      controller.set('timeUnits', this.store.find('time-unit'));
      controller.set('futureTriggerTypes', this.store.find('future-action-trigger-type'));
      controller.set('users', this.store.find('user', { per_page: 100, addAll: true }));

      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('patientAppointmentCategories', this.store.find('patient-appointment-category'));

      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
    },
    reloadModel: function reloadModel() {
      var controller = this.get('controller');
      this.store.fetch(controller.get('modelDef'), controller.get('future').id).then(function (data) {
        controller.set('future', data);
      });
    },
    save: function save(model, refererId) {

      var self = this;

      var data = {
        id: model.get('id'),
        timeUnitInterval: model.get('timeUnitInterval'),
        timeUnitIterations: model.get('timeUnitIterations'),
        reminderText: model.get('reminderText'),
        reminderSubject: model.get('reminderSubject'),
        reminderHeadline: model.get('reminderHeadline'),
        reminderSalutation: model.get('reminderSalutation'),
        reminderClosing: model.get('reminderClosing'),
        messageText: model.get('messageText'),
        messageTitle: model.get('messageTitle'),
        messageUsers: model.get('messageUsers'),
        taskTitle: model.get('taskTitle'),
        taskComment: model.get('taskComment'),
        appTitle: model.get('appTitle'),
        appDateFrom: model.get('appDateFrom'),
        appDateTo: model.get('appDateTo'),
        appLocation: model.get('appLocation'),
        appPersons: model.get('appPersons'),
        appDescription: model.get('appDescription'),
        appWholeDay: model.get('appWholeDay'),
        appMessageTitle: model.get('appMessageTitle'),
        appMessageText: model.get('appMessageText'),
        treatmentDied: model.get('treatmentDied'),
        treatmentCastrate: model.get('treatmentCastrate')
      };

      if (model.get('triggerType')) {
        data.triggerType = {
          id: model.get('triggerType').id
        };
      }
      if (model.get('taskResponsible')) {
        data.taskResponsible = {
          id: model.get('taskResponsible').id
        };
      }

      if (model.get('type')) {
        data.type = {
          id: model.get('type').id
        };
      }

      if (model.get('timeUnit')) {
        data.timeUnit = {
          id: model.get('timeUnit').id
        };
      }

      if (model.get('appCategory')) {
        data.appCategory = {
          id: model.get('appCategory').id
        };
      }
      if (model.get('appRoom')) {
        data.appRoom = {
          id: model.get('appRoom').id
        };
      }
      if (model.get('appStation')) {
        data.appStation = {
          id: model.get('appStation').id
        };
      }
      if (model.get('appInternalUser1')) {
        data.appInternalUser1 = {
          id: model.get('appInternalUser1').id
        };
      }
      if (model.get('appInternalUser2')) {
        data.appInternalUser2 = {
          id: model.get('appInternalUser2').id
        };
      }
      if (model.get('appInternalUser3')) {
        data.appInternalUser3 = {
          id: model.get('appInternalUser3').id
        };
      }
      if (model.get('appInternalAssistant1')) {
        data.appInternalAssistant1 = {
          id: model.get('appInternalAssistant1').id
        };
      }
      if (model.get('appInternalAssistant2')) {
        data.appInternalAssistant2 = {
          id: model.get('appInternalAssistant2').id
        };
      }
      if (model.get('appInternalAssistant3')) {
        data.appInternalAssistant3 = {
          id: model.get('appInternalAssistant3').id
        };
      }

      if (this.get('controller').get('nextExecution')) {
        data.nextExecution = moment(this.get('controller').get('nextExecution'), 'DD. MM. YYYY').format('YYYY-MM-DD');
      }
      if (this.get('controller').get('nextExecutionTime')) {

        var execTime = this.get('controller').get('nextExecutionTime');

        if (!execTime.indexOf(":") != -1 && execTime.length < 3) {
          execTime += ":00";
        }
        console.log(execTime);
        data.nextExecutionTime = execTime;
      }

      if (!data.type) {
        jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Maßnahme');
        return;
      }
      if (!data.triggerType) {
        jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Fälligkeit');
        return;
      }
      if (data.triggerType.id !== 'asap' && data.type.id === 'treatment') {
        jason.notifiction.errorLong('Fehler', 'Die Maßnahme "Behandlung" kann nur mit der Fälligkeit "sofort" gespeichert werden');
        return;
      }

      $.ajax({
        url: "/api/" + this.get('controller').get('apiType') + "/" + model.get('id'),
        method: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (responseData) {
        jason.notifiction.notifiy('Erfolgreich gespeichert', '');
        if (refererId > 0) {
          self.clickBack(refererId);
        }
      });
    },
    clickBack: function clickBack(id) {
      var controller = this.get('controller');

      if (controller.get('refererType') === 'treatment') {
        this.transitionTo('practicemgmt.treatment-details', id, {
          queryParams: {
            selectTab: 'future'
          }
        });
      }
      if (controller.get('refererType') === 'patient') {
        this.transitionTo('patients.edit', id, {
          queryParams: {
            selectTab: 'future'
          }
        });
      }
      if (controller.get('refererType') === 'compositeproducts') {
        this.transitionTo('compositeproducts.edit', id, {
          queryParams: {
            selectTab: 'future'
          }
        });
      }
      if (controller.get('refererType') === 'services') {
        this.transitionTo('services.edit', id, {
          queryParams: {
            selectTab: 'future'
          }
        });
      }
      if (controller.get('refererType') === 'products') {
        this.transitionTo('products.edit', id, {
          queryParams: {
            selectTab: 'future'
          }
        });
      }
    },
    actions: {
      setFutureType: function setFutureType(type, id) {
        this.get('controller').set('selectedFuture', type);
        this.get('controller').set('selectedFutureId', id);
      },
      clickBack: function clickBack(id) {
        this.clickBack(id);
      },
      addArticle: function addArticle(id) {
        var self = this;
        this.save(this.get('controller').get('future'));
        self.transitionTo('cash.add_article', this.get('controller').get('refererType'), id, this.get('controller').get('refererId'));
      },
      addTemplate: function addTemplate(id) {
        var self = this;
        this.save(this.get('controller').get('future'));

        var referer = this.get('controller').get('refererType');

        var to = '';
        if (referer === 'products') {
          to = 'templateProduct';
        } else if (referer === 'services') {
          to = 'templateService';
        } else if (referer === 'compositeproducts') {
          to = 'templateComposite';
        } else if (referer === 'treatment') {
          to = 'templateTreatment';
        }

        self.transitionTo('cash.add_article', to, id, this.get('controller').get('refererId'));
      },
      changeQuantity: function changeQuantity(quantity, entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/" + this.get('controller').get('apiType') + "/" + offerId + "/changeQuantity/" + entryId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Menge aktualisiert', '');
          self.reloadModel();
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteProduct: function deleteProduct(entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/" + this.get('controller').get('apiType') + "/" + offerId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Produkt entfernt', productName);
          self.reloadModel();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      changeGrossPrice: function changeGrossPrice(price, entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/" + this.get('controller').get('apiType') + "/" + offerId + "/changePrice/" + entryId + "/" + price,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Preis aktualisiert', '');
          self.reloadModel();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      changeApplied: function changeApplied(applied, entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/" + this.get('controller').get('apiType') + "/" + offerId + "/changeApplied/" + entryId + "/" + applied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aktualisiert', '');
          self.reloadModel();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      save: function save(model, refererId) {
        this.save(model, refererId);
      }
    }
  });

});
define('jason-frontend/templates/components/notifications-timeline', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 12
                },
                "end": {
                  "line": 8,
                  "column": 140
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-myjason",[],["content","","color","#008EC6","cssClass","inverted","classNames","inline-icon"],["loc",[null,[8,49],[8,140]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 12
                },
                "end": {
                  "line": 9,
                  "column": 103
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-ehapo2",[],["content","","classNames","inline-icon"],["loc",[null,[9,49],[9,103]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 14
                  },
                  "end": {
                    "line": 13,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-lab",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[12,16],[12,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 14
                  },
                  "end": {
                    "line": 15,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-lab",[],["content","","fill","#fff","cssClass","invertedLaboklin","classNames","invertedLaboklin inline-icon"],["loc",[null,[14,16],[14,124]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 12
                },
                "end": {
                  "line": 16,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[11,24],[11,34]]]],"Interner Untersuchungsauftrag verfügbar"],[],["loc",[null,[11,20],[11,77]]]]],[],0,1,["loc",[null,[11,14],[15,21]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 12
                },
                "end": {
                  "line": 17,
                  "column": 127
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-invoice",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[17,51],[17,127]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 12
                },
                "end": {
                  "line": 18,
                  "column": 126
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[18,52],[18,126]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 12
                },
                "end": {
                  "line": 19,
                  "column": 126
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[19,52],[19,126]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 12
                },
                "end": {
                  "line": 20,
                  "column": 251
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","inline-icon","cssClass",""],["loc",[null,[20,181],[20,251]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 16
                },
                "end": {
                  "line": 27,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","img-responsive mw50 ib");
              dom.setAttribute(el1,"title","user");
              dom.setAttribute(el1,"src","assets/images/vetconnect.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 16
                  },
                  "end": {
                    "line": 29,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"class","img-responsive mw50 ib");
                dom.setAttribute(el1,"title","user");
                dom.setAttribute(el1,"src","assets/images/scilvip.png");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 16
                    },
                    "end": {
                      "line": 31,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"class","img-responsive mw50 ib");
                  dom.setAttribute(el1,"title","user");
                  dom.setAttribute(el1,"src","assets/images/laboklin.png");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 16
                      },
                      "end": {
                        "line": 33,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","cloudinary-tag",[["get","item.sender.picture.cdnId",["loc",[null,[32,35],[32,60]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[32,18],[32,104]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 16
                      },
                      "end": {
                        "line": 35,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"class","img-responsive mw50 ib");
                    dom.setAttribute(el1,"title","user");
                    dom.setAttribute(el1,"src","assets/images/users/default.png");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 31,
                      "column": 16
                    },
                    "end": {
                      "line": 35,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.sender.picture",["loc",[null,[31,26],[31,45]]]]],[],0,1,["loc",[null,[31,16],[35,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 16
                  },
                  "end": {
                    "line": 35,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[29,30],[29,40]]]],"Untersuchungsauftrag verfügbar"],[],["loc",[null,[29,26],[29,74]]]]],[],0,1,["loc",[null,[29,16],[35,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 16
                },
                "end": {
                  "line": 35,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[27,30],[27,40]]]],"Interner Untersuchungsauftrag verfügbar"],[],["loc",[null,[27,26],[27,83]]]]],[],0,1,["loc",[null,[27,16],[35,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 18
                  },
                  "end": {
                    "line": 41,
                    "column": 86
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("von: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element22 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element22,1,1);
                morphs[1] = dom.createMorphAt(element22,3,3);
                return morphs;
              },
              statements: [
                ["content","item.sender.firstname",["loc",[null,[41,30],[41,55]]]],
                ["content","item.sender.lastname",["loc",[null,[41,56],[41,80]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 16
                },
                "end": {
                  "line": 42,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.sender",["loc",[null,[40,24],[40,35]]]]],[],0,null,["loc",[null,[40,18],[41,93]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 18
                  },
                  "end": {
                    "line": 46,
                    "column": 117
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                      an: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-text",[],["text",["subexpr","truncate",[["get","item.receiversString",["loc",[null,[46,55],[46,75]]]],200],[],["loc",[null,[46,45],[46,80]]]],"content",["subexpr","@mut",[["get","item.receiversString",["loc",[null,[46,89],[46,109]]]]],[],[]]],["loc",[null,[46,26],[46,111]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 16
                },
                "end": {
                  "line": 47,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.receiversString",["loc",[null,[44,24],[44,44]]]]],[],0,null,["loc",[null,[44,18],[46,124]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 52,
                        "column": 33
                      },
                      "end": {
                        "line": 52,
                        "column": 122
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.patient.name",["loc",[null,[52,101],[52,122]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 20
                    },
                    "end": {
                      "line": 55,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                        Patient: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ,\n                        BesitzerIn: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element21,1,1);
                  morphs[1] = dom.createMorphAt(element21,3,3);
                  morphs[2] = dom.createMorphAt(element21,5,5);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["patients.etiga",["get","item.patient.id",["loc",[null,[52,61],[52,76]]]]],["class","is-highlighted"],0,null,["loc",[null,[52,33],[52,134]]]],
                  ["content","item.customer.firstname",["loc",[null,[53,36],[53,63]]]],
                  ["content","item.customer.lastname",["loc",[null,[53,64],[53,90]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 57,
                        "column": 31
                      },
                      "end": {
                        "line": 57,
                        "column": 119
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.patient.name",["loc",[null,[57,98],[57,119]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 59,
                        "column": 34
                      },
                      "end": {
                        "line": 59,
                        "column": 157
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.customer.firstname",["loc",[null,[59,103],[59,130]]]],
                    ["content","item.customer.lastname",["loc",[null,[59,131],[59,157]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 55,
                      "column": 20
                    },
                    "end": {
                      "line": 61,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                      Patient: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ,\n                      BesitzerIn: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element20 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element20,1,1);
                  morphs[1] = dom.createMorphAt(element20,3,3);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[57,58],[57,73]]]]],["class","is-highlighted"],0,null,["loc",[null,[57,31],[57,131]]]],
                  ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[59,62],[59,78]]]]],["class","is-highlighted"],1,null,["loc",[null,[59,34],[59,169]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 18
                  },
                  "end": {
                    "line": 62,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","item.patient.etigaActive",["loc",[null,[50,31],[50,55]]]],["get","item.patient.etigaMaster",["loc",[null,[50,56],[50,80]]]]],[],["loc",[null,[50,26],[50,81]]]]],[],0,1,["loc",[null,[50,20],[61,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 16
                },
                "end": {
                  "line": 63,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.patient",["loc",[null,[49,24],[49,36]]]]],[],0,null,["loc",[null,[49,18],[62,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 14
                },
                "end": {
                  "line": 75,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 76,
                    "column": 16
                  },
                  "end": {
                    "line": 76,
                    "column": 205
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","reply-button pt2");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","cursor");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element18 = dom.childAt(fragment, [0, 0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element18);
                morphs[1] = dom.createMorphAt(element18,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["reply",["get","item",["loc",[null,[76,133],[76,137]]]]],[],["loc",[null,[76,116],[76,139]]]],
                ["inline","button-reply",[],["color","#3B6182","size",30],["loc",[null,[76,155],[76,195]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 14
                },
                "end": {
                  "line": 82,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","forward pt2");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element19 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createElementMorph(element19);
              morphs[2] = dom.createMorphAt(element19,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.withinPractice",["loc",[null,[76,27],[76,46]]]],["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[76,56],[76,72]]]],"app"],[],["loc",[null,[76,52],[76,79]]]]],[],["loc",[null,[76,47],[76,80]]]]],[],["loc",[null,[76,22],[76,81]]]]],[],0,null,["loc",[null,[76,16],[76,212]]]],
              ["element","action",["forward",["get","item",["loc",[null,[78,40],[78,44]]]]],[],["loc",[null,[78,21],[78,46]]]],
              ["inline","button-forward",[],["color","#3B6182","size",30],["loc",[null,[79,20],[79,62]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 14
                },
                "end": {
                  "line": 94,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","unreadIcon");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","unreadForAllIcon");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element16 = dom.childAt(fragment, [1, 1]);
              var element17 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element16);
              morphs[1] = dom.createMorphAt(element16,1,1);
              morphs[2] = dom.createElementMorph(element17);
              morphs[3] = dom.createMorphAt(element17,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["markAsRead",["get","item",["loc",[null,[85,43],[85,47]]]]],[],["loc",[null,[85,21],[85,49]]]],
              ["inline","button-checked",[],["cssClasses","checked-button","size",30,"content","Als gelesen markieren"],["loc",[null,[86,20],[86,106]]]],
              ["element","action",["markAsReadForAll",["get","item",["loc",[null,[90,49],[90,53]]]]],[],["loc",[null,[90,21],[90,55]]]],
              ["inline","button-all-checked",[],["classNames","checked-button inline-icon","color","#3B6182","size",30,"content","Für alle (anderen MitarbeiterInnen) als gelesen markieren"],["loc",[null,[91,20],[91,174]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 16
                  },
                  "end": {
                    "line": 101,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","delete pt2");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","cursor");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm-full",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[98,61],[98,65]]]]],[],[]],"confirmed","delete","size",30,"text","Wollen Sie diese Benachrichtigung wirklich löschen?"],["loc",[null,[98,22],[98,153]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 14
                },
                "end": {
                  "line": 102,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[95,30],[95,34]]]],"in"],[],["loc",[null,[95,26],[95,40]]]],["subexpr","eq",[["get","type",["loc",[null,[95,45],[95,49]]]],"unread"],[],["loc",[null,[95,41],[95,59]]]]],[],["loc",[null,[95,22],[95,60]]]]],[],0,null,["loc",[null,[95,16],[101,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child16 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 106,
                      "column": 58
                    },
                    "end": {
                      "line": 106,
                      "column": 96
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("von ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.readBy",["loc",[null,[106,81],[106,96]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 106,
                      "column": 104
                    },
                    "end": {
                      "line": 106,
                      "column": 174
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","time-format",[["get","item.readOn",["loc",[null,[106,140],[106,151]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[106,126],[106,174]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 14
                  },
                  "end": {
                    "line": 106,
                    "column": 181
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("gelesen ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.readby",["loc",[null,[106,64],[106,75]]]]],[],0,null,["loc",[null,[106,58],[106,103]]]],
                ["block","if",[["get","item.readOn",["loc",[null,[106,110],[106,121]]]]],[],1,null,["loc",[null,[106,104],[106,181]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 12
                },
                "end": {
                  "line": 108,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","readInfo small italic");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.readby",["loc",[null,[106,24],[106,35]]]],["get","item.readOn",["loc",[null,[106,36],[106,47]]]]],[],["loc",[null,[106,20],[106,48]]]]],[],0,null,["loc",[null,[106,14],[106,188]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child17 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 115,
                      "column": 16
                    },
                    "end": {
                      "line": 116,
                      "column": 39
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  Mit eltiga verknüpfen");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 113,
                    "column": 14
                  },
                  "end": {
                    "line": 117,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.etiga",["get","item.patient.id",["loc",[null,[115,44],[115,59]]]]],["class","mt10 mb10 label inline-block button purple-style p5"],0,null,["loc",[null,[115,16],[116,51]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 12
                },
                "end": {
                  "line": 118,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","not",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[113,29],[113,47]]]],"patientetiga"],[],["loc",[null,[113,25],[113,63]]]]],[],["loc",[null,[113,20],[113,64]]]]],[],0,null,["loc",[null,[113,14],[117,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child18 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 122,
                      "column": 35
                    },
                    "end": {
                      "line": 122,
                      "column": 210
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.referenceData.reactedUser.firstname",["loc",[null,[122,122],[122,166]]]],
                  ["content","item.referenceData.reactedUser.lastname",["loc",[null,[122,167],[122,210]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 16
                  },
                  "end": {
                    "line": 123,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  Termin wurde von ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" am ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                dom.setAttribute(el1,"class","is-success");
                var el2 = dom.createTextNode("bestätigt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["users.edit",["get","item.referenceData.reactedUser.id",["loc",[null,[122,59],[122,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[122,35],[122,222]]]],
                ["inline","time-format",[["get","item.referenceData.reactedDate",["loc",[null,[122,240],[122,270]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[122,226],[122,293]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 124,
                        "column": 35
                      },
                      "end": {
                        "line": 124,
                        "column": 210
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.referenceData.reactedUser.firstname",["loc",[null,[124,122],[124,166]]]],
                    ["content","item.referenceData.reactedUser.lastname",["loc",[null,[124,167],[124,210]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 123,
                      "column": 16
                    },
                    "end": {
                      "line": 125,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  Termin wurde von ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  dom.setAttribute(el1,"class","is-warning");
                  var el2 = dom.createTextNode("abgelehnt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["users.edit",["get","item.referenceData.reactedUser.id",["loc",[null,[124,59],[124,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[124,35],[124,222]]]],
                  ["inline","time-format",[["get","item.referenceData.reactedDate",["loc",[null,[124,240],[124,270]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[124,226],[124,293]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 125,
                        "column": 16
                      },
                      "end": {
                        "line": 131,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","addAdmission");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element15 = dom.childAt(fragment, [1, 1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element15);
                    morphs[1] = dom.createMorphAt(element15,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openAppRequestConfirmation",["get","item",["loc",[null,[127,61],[127,65]]]]],[],["loc",[null,[127,23],[127,67]]]],
                    ["inline","button-calendar",[],["showStroke",false,"content","Terminanfrage beantworten","color","#FFFFFF","size","48"],["loc",[null,[128,22],[128,120]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 125,
                      "column": 16
                    },
                    "end": {
                      "line": 131,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[125,30],[125,55]]]],"requested"],[],["loc",[null,[125,26],[125,68]]]]],[],0,null,["loc",[null,[125,16],[131,16]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 123,
                    "column": 16
                  },
                  "end": {
                    "line": 131,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[123,30],[123,55]]]],"aborted"],[],["loc",[null,[123,26],[123,66]]]]],[],0,1,["loc",[null,[123,16],[131,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 14
                },
                "end": {
                  "line": 133,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[121,26],[121,51]]]],"booked"],[],["loc",[null,[121,22],[121,61]]]]],[],0,1,["loc",[null,[121,16],[131,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child19 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 138,
                    "column": 36
                  },
                  "end": {
                    "line": 138,
                    "column": 157
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.patient.name",["loc",[null,[138,122],[138,157]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 142,
                    "column": 35
                  },
                  "end": {
                    "line": 142,
                    "column": 205
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.customer.firstname",["loc",[null,[142,123],[142,164]]]],
                ["content","item.referenceData.customer.lastname",["loc",[null,[142,165],[142,205]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 149,
                    "column": 16
                  },
                  "end": {
                    "line": 149,
                    "column": 123
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("VPE: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.unitQuantity",["loc",[null,[149,60],[149,95]]]],
                ["content","item.referenceData.unit",["loc",[null,[149,96],[149,123]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 150,
                    "column": 16
                  },
                  "end": {
                    "line": 155,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  Lieferant: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Diese Menge wurde automatisch in den ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","is-highlighted cursor");
                var el3 = dom.createTextNode("Praxis Warenkorb");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" gelegt.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [7, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[1] = dom.createElementMorph(element12);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.reorderSupplier",["loc",[null,[152,29],[152,67]]]],
                ["element","action",["gotoPracticeCart"],[],["loc",[null,[154,91],[154,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 155,
                    "column": 16
                  },
                  "end": {
                    "line": 157,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("<p>Für das Produkt konnte keine eindeutige Nachbestellungsinformation gefunden werden. Bitte führen Sie eine manuelle Bestellung durch.</p>");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 20
                  },
                  "end": {
                    "line": 158,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Anmerkung: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),2,2);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.notice",["loc",[null,[158,85],[158,114]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 135,
                  "column": 14
                },
                "end": {
                  "line": 160,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","p10 mt10");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Patient:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Spezies: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Geschlecht: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("BesitzerIn:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Name: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Adresse: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(", ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Telefon: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                 Produkt: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted cursor");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                 Menge: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var element14 = dom.childAt(element13, [37]);
              var morphs = new Array(16);
              morphs[0] = dom.createMorphAt(element13,3,3);
              morphs[1] = dom.createMorphAt(element13,6,6);
              morphs[2] = dom.createMorphAt(element13,9,9);
              morphs[3] = dom.createMorphAt(element13,16,16);
              morphs[4] = dom.createMorphAt(element13,21,21);
              morphs[5] = dom.createMorphAt(element13,23,23);
              morphs[6] = dom.createMorphAt(element13,26,26);
              morphs[7] = dom.createMorphAt(element13,28,28);
              morphs[8] = dom.createMorphAt(element13,30,30);
              morphs[9] = dom.createMorphAt(element13,33,33);
              morphs[10] = dom.createElementMorph(element14);
              morphs[11] = dom.createMorphAt(element14,1,1);
              morphs[12] = dom.createMorphAt(element13,40,40);
              morphs[13] = dom.createMorphAt(element13,43,43);
              morphs[14] = dom.createMorphAt(element13,45,45);
              morphs[15] = dom.createMorphAt(element13,47,47);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.referenceData.patient.id",["loc",[null,[138,63],[138,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[138,36],[138,169]]]],
              ["content","item.referenceData.patient.category.name",["loc",[null,[139,29],[139,73]]]],
              ["content","item.referenceData.patient.gender.name",["loc",[null,[140,32],[140,74]]]],
              ["block","link-to",["customers.edit",["get","item.referenceData.customer.id",["loc",[null,[142,63],[142,93]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[142,35],[142,217]]]],
              ["content","item.referenceData.customer.firstname",["loc",[null,[144,26],[144,67]]]],
              ["content","item.referenceData.customer.lastname",["loc",[null,[144,68],[144,108]]]],
              ["content","item.referenceData.customer.street",["loc",[null,[145,29],[145,67]]]],
              ["content","item.referenceData.customer.postalCode",["loc",[null,[145,69],[145,111]]]],
              ["content","item.referenceData.customer.town",["loc",[null,[145,112],[145,148]]]],
              ["content","item.referenceData.customer.phone",["loc",[null,[146,29],[146,66]]]],
              ["element","action",["gotoPurchaseProduct",["get","item.referenceData.articleId",["loc",[null,[147,90],[147,118]]]]],[],["loc",[null,[147,59],[147,120]]]],
              ["content","item.referenceData.articleName",["loc",[null,[147,122],[147,156]]]],
              ["content","item.referenceData.amount",["loc",[null,[148,24],[148,53]]]],
              ["block","if",[["get","item.referenceData.unitQuantity",["loc",[null,[149,22],[149,53]]]]],[],2,null,["loc",[null,[149,16],[149,130]]]],
              ["block","if",[["get","item.referenceData.added",["loc",[null,[150,22],[150,46]]]]],[],3,4,["loc",[null,[150,16],[157,23]]]],
              ["block","if",[["get","item.referenceData.notice",["loc",[null,[158,26],[158,51]]]]],[],5,null,["loc",[null,[158,20],[158,125]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        }());
        var child20 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 162,
                  "column": 14
                },
                "end": {
                  "line": 167,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","inline-block button purple-style");
              var el2 = dom.createTextNode("\n                    zur ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [4]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element11);
              morphs[1] = dom.createMorphAt(element11,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["gotoPatient",["get","item.patient.id",["loc",[null,[164,42],[164,57]]]]],[],["loc",[null,[164,19],[164,59]]]],
              ["inline","icon-etiga",[],["color","ffffff","content","","classNames","inline-icon middle","size","22","width",50],["loc",[null,[165,24],[165,115]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child21 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 171,
                    "column": 18
                  },
                  "end": {
                    "line": 173,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Zum Dokument");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[172,20],[172,102]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 169,
                  "column": 16
                },
                "end": {
                  "line": 175,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[171,45],[171,60]]]],["subexpr","query-params",[],["selectTab","documents","rootFolderId",["get","item.referenceId",["loc",[null,[171,110],[171,126]]]]],["loc",[null,[171,61],[171,127]]]]],[],0,null,["loc",[null,[171,18],[173,30]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child22 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 179,
                      "column": 18
                    },
                    "end": {
                      "line": 182,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Untersuchungsauftrag");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[180,20],[180,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 177,
                    "column": 16
                  },
                  "end": {
                    "line": 184,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[179,45],[179,61]]]],["subexpr","query-params",[],["selectTab","lab"],["loc",[null,[179,62],[179,92]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[179,18],[182,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 187,
                      "column": 18
                    },
                    "end": {
                      "line": 190,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("\n                    Zur Behandlung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[188,20],[188,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 185,
                    "column": 16
                  },
                  "end": {
                    "line": 192,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-details",["get","item.referenceId",["loc",[null,[187,62],[187,78]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[187,79],[187,106]]]]],[],0,null,["loc",[null,[187,18],[190,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 195,
                      "column": 18
                    },
                    "end": {
                      "line": 198,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Lieferschein");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[196,20],[196,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 193,
                    "column": 16
                  },
                  "end": {
                    "line": 200,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["inventory.delivery-note-details",["get","item.referenceId",["loc",[null,[195,63],[195,79]]]]],["title","Lieferschein anzeigen"],0,null,["loc",[null,[195,18],[198,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 203,
                      "column": 18
                    },
                    "end": {
                      "line": 206,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Patient");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[204,20],[204,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 201,
                    "column": 16
                  },
                  "end": {
                    "line": 208,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[203,45],[203,61]]]],["subexpr","query-params",[],["selectTab","history"],["loc",[null,[203,62],[203,96]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[203,18],[206,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 211,
                      "column": 18
                    },
                    "end": {
                      "line": 213,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    zur eltiga Akte\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 209,
                    "column": 16
                  },
                  "end": {
                    "line": 215,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[211,45],[211,61]]]],["subexpr","query-params",[],["selectTab","etiga"],["loc",[null,[211,62],[211,94]]]]],["title","Patient anzeigen","class","button purple-style"],0,null,["loc",[null,[211,18],[213,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 218,
                      "column": 18
                    },
                    "end": {
                      "line": 221,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                    Rechnung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[219,20],[219,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 216,
                    "column": 16
                  },
                  "end": {
                    "line": 223,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[218,45],[218,61]]]]],["title","Rechnung anzeigen"],0,null,["loc",[null,[218,18],[221,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 226,
                      "column": 18
                    },
                    "end": {
                      "line": 229,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                    Bestellung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[227,20],[227,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 224,
                    "column": 16
                  },
                  "end": {
                    "line": 231,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["purchases.show",["get","item.referenceId",["loc",[null,[226,46],[226,62]]]]],["title","Bestellung anzeigen"],0,null,["loc",[null,[226,18],[229,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 176,
                  "column": 12
                },
                "end": {
                  "line": 232,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,6,6,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[177,26],[177,44]]]],"treatment"],[],["loc",[null,[177,22],[177,57]]]]],[],0,null,["loc",[null,[177,16],[184,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[185,26],[185,44]]]],"treatmentPatient"],[],["loc",[null,[185,22],[185,64]]]]],[],1,null,["loc",[null,[185,16],[192,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[193,26],[193,44]]]],"deliveryNote"],[],["loc",[null,[193,22],[193,60]]]]],[],2,null,["loc",[null,[193,16],[200,23]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[201,36],[201,52]]]],"app"],[],["loc",[null,[201,32],[201,59]]]]],[],["loc",[null,[201,27],[201,60]]]],["subexpr","eq",[["get","item.referenceType",["loc",[null,[201,65],[201,83]]]],"patient"],[],["loc",[null,[201,61],[201,94]]]]],[],["loc",[null,[201,22],[201,95]]]]],[],3,null,["loc",[null,[201,16],[208,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[209,26],[209,44]]]],"patientetiga"],[],["loc",[null,[209,22],[209,60]]]]],[],4,null,["loc",[null,[209,16],[215,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[216,26],[216,44]]]],"invoice"],[],["loc",[null,[216,22],[216,55]]]]],[],5,null,["loc",[null,[216,16],[223,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[224,26],[224,44]]]],"purchase"],[],["loc",[null,[224,22],[224,56]]]]],[],6,null,["loc",[null,[224,16],[231,23]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        var child23 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 236,
                      "column": 18
                    },
                    "end": {
                      "line": 236,
                      "column": 180
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon vertical-middle"],["loc",[null,[236,58],[236,156]]]],
                  ["content","patient.name",["loc",[null,[236,160],[236,176]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 235,
                    "column": 16
                  },
                  "end": {
                    "line": 237,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[236,45],[236,55]]]]],[],0,null,["loc",[null,[236,18],[236,192]]]]
              ],
              locals: ["patient"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 233,
                  "column": 12
                },
                "end": {
                  "line": 239,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.referenceData.patients",["loc",[null,[235,24],[235,51]]]]],[],0,null,["loc",[null,[235,16],[237,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child24 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 242,
                    "column": 16
                  },
                  "end": {
                    "line": 244,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Termin im Kalender anzeigen");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[243,18],[243,100]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 240,
                  "column": 12
                },
                "end": {
                  "line": 246,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference mb0");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["appointments.calendar"],["title","Termin im Kalender anzeigen"],0,null,["loc",[null,[242,16],[244,28]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child25 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 249,
                    "column": 16
                  },
                  "end": {
                    "line": 252,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("zur\n                  Korrespondenz");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[250,18],[250,100]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 247,
                  "column": 14
                },
                "end": {
                  "line": 254,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[249,43],[249,58]]]],["subexpr","query-params",[],["selectTab","correspondance"],["loc",[null,[249,59],[249,100]]]]],["title","Korrespondenz anzeigen"],0,null,["loc",[null,[249,16],[252,28]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child26 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 258,
                      "column": 263
                    },
                    "end": {
                      "line": 258,
                      "column": 353
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","time-format",[["get","task.doneAt",["loc",[null,[258,319],[258,330]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[258,305],[258,353]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 258,
                        "column": 443
                      },
                      "end": {
                        "line": 258,
                        "column": 522
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("seit ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(",");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","time-format",[["get","task.startedAt",["loc",[null,[258,484],[258,498]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[258,470],[258,521]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 258,
                      "column": 360
                    },
                    "end": {
                      "line": 258,
                      "column": 585
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(", ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" fällig am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","task.startedAt",["loc",[null,[258,449],[258,463]]]]],[],0,null,["loc",[null,[258,443],[258,529]]]],
                  ["inline","time-format",[["get","task.due",["loc",[null,[258,554],[258,562]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[258,540],[258,585]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 258,
                        "column": 627
                      },
                      "end": {
                        "line": 258,
                        "column": 734
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","task.user.firstname",["loc",[null,[258,688],[258,711]]]],
                    ["content","task.user.lastname",["loc",[null,[258,712],[258,734]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 258,
                      "column": 610
                    },
                    "end": {
                      "line": 258,
                      "column": 746
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["users.edit",["get","task.user.id",["loc",[null,[258,651],[258,663]]]]],["class","is-highlighted"],0,null,["loc",[null,[258,627],[258,746]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 258,
                      "column": 746
                    },
                    "end": {
                      "line": 258,
                      "column": 758
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Alle");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 257,
                    "column": 16
                  },
                  "end": {
                    "line": 259,
                    "column": 17
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Aufgabe:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(", Verantwortlich: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [7]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[1] = dom.createElementMorph(element10);
                morphs[2] = dom.createMorphAt(element10,0,0);
                morphs[3] = dom.createMorphAt(dom.childAt(fragment, [9]),0,0);
                morphs[4] = dom.createMorphAt(fragment,10,10,contextualElement);
                morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
                morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon","content","Aufgabe öffnen"],["loc",[null,[258,28],[258,135]]]],
                ["element","action",["showTask",["get","task",["loc",[null,[258,205],[258,209]]]]],[],["loc",[null,[258,185],[258,211]]]],
                ["content","task.title",["loc",[null,[258,212],[258,226]]]],
                ["content","task.taskStatus.name",["loc",[null,[258,235],[258,259]]]],
                ["block","if",[["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[258,273],[258,291]]]],"done"],[],["loc",[null,[258,269],[258,299]]]]],[],0,null,["loc",[null,[258,263],[258,360]]]],
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[258,374],[258,392]]]],"in_progress"],[],["loc",[null,[258,370],[258,407]]]],["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[258,412],[258,430]]]],"open"],[],["loc",[null,[258,408],[258,438]]]]],[],["loc",[null,[258,366],[258,439]]]]],[],1,null,["loc",[null,[258,360],[258,592]]]],
                ["block","if",[["get","task.user",["loc",[null,[258,616],[258,625]]]]],[],2,3,["loc",[null,[258,610],[258,765]]]]
              ],
              locals: ["task"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 255,
                  "column": 14
                },
                "end": {
                  "line": 261,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.tasks",["loc",[null,[257,24],[257,34]]]]],[],0,null,["loc",[null,[257,16],[259,26]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child27 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 264,
                  "column": 10
                },
                "end": {
                  "line": 271,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor etiga-send-msg");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","icon-button icon-button--small");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createElementMorph(element9);
              morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["directEtigaMessage ",["subexpr","css-bool-evaluator",[["subexpr","or",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[265,82],[265,107]]]],"requested"],[],["loc",[null,[265,78],[265,120]]]]],[],["loc",[null,[265,73],[265,121]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[265,126],[265,142]]]],"appapp"],[],["loc",[null,[265,122],[265,152]]]]],[],["loc",[null,[265,68],[265,153]]]],["subexpr","and",[["get","item.app",["loc",[null,[265,159],[265,167]]]],["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[265,176],[265,192]]]],"app"],[],["loc",[null,[265,172],[265,199]]]],["subexpr","and",[["subexpr","eq",[["get","item.status",["loc",[null,[265,209],[265,220]]]],"done"],[],["loc",[null,[265,205],[265,228]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[265,233],[265,249]]]],"apporder"],[],["loc",[null,[265,229],[265,261]]]]],[],["loc",[null,[265,200],[265,262]]]]],[],["loc",[null,[265,168],[265,263]]]]],[],["loc",[null,[265,154],[265,264]]]]],[],["loc",[null,[265,64],[265,265]]]],"right",""],[],["loc",[null,[265,43],[265,279]]]]]]],
              ["element","action",["openDirectMessage",["get","item.id",["loc",[null,[266,46],[266,53]]]]],[],["loc",[null,[266,17],[266,55]]]],
              ["inline","button-chat",[],["content","Nachricht an eltiga app BenutzerIn verfassen","color","#ffffff","size","40","showStroke",false],["loc",[null,[268,24],[268,137]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child28 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 272,
                  "column": 10
                },
                "end": {
                  "line": 278,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              dom.setAttribute(el1,"style","right: 58px;");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["startAdmissionFromMessage",["get","item",["loc",[null,[274,56],[274,60]]]]],[],["loc",[null,[274,19],[274,62]]]],
              ["inline","button-add-admission",[],["content","Nachricht in Behandlung übernehmen","color","#FFFFFF","size","40"],["loc",[null,[275,18],[275,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child29 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 279,
                  "column": 10
                },
                "end": {
                  "line": 285,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["startAdmissionFromReorder",["get","item",["loc",[null,[281,54],[281,58]]]]],[],["loc",[null,[281,17],[281,60]]]],
              ["inline","button-add-admission",[],["content","Bestellung in Behandlung übernehmen","color","#FFFFFF","size","40"],["loc",[null,[282,16],[282,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child30 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 286,
                  "column": 10
                },
                "end": {
                  "line": 292,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(element5,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openDirectMessage",["get","item",["loc",[null,[288,50],[288,54]]]],"finished"],[],["loc",[null,[288,21],[288,67]]]],
              ["inline","button-pickup",[],["content","eltiga App BenutzerIn benachrichtigen: Bestellung abholbereit","color","#FFFFFF","size","40"],["loc",[null,[289,20],[289,135]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child31 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 302,
                    "column": 18
                  },
                  "end": {
                    "line": 304,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","cloudinary-tag",[["get","reply.sender.picture.cdnId",["loc",[null,[303,37],[303,63]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[303,20],[303,107]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 304,
                    "column": 18
                  },
                  "end": {
                    "line": 306,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"class","img-responsive mw50 ib");
                dom.setAttribute(el1,"title","user");
                dom.setAttribute(el1,"src","assets/images/users/default.png");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 309,
                    "column": 41
                  },
                  "end": {
                    "line": 309,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","reply.sender.shortName",["loc",[null,[309,61],[309,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 309,
                    "column": 87
                  },
                  "end": {
                    "line": 309,
                    "column": 109
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("App-Benutzerin");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 313,
                      "column": 22
                    },
                    "end": {
                      "line": 315,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur Behandlung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[314,24],[314,106]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 311,
                    "column": 18
                  },
                  "end": {
                    "line": 317,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-details",["get","item.referenceData.treatmentPatientId",["loc",[null,[313,66],[313,103]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[313,104],[313,131]]]]],["title","Zur Behandlung"],0,null,["loc",[null,[313,22],[315,34]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 321,
                    "column": 40
                  },
                  "end": {
                    "line": 321,
                    "column": 148
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["reply",["get","item",["loc",[null,[321,82],[321,86]]]]],[],["loc",[null,[321,65],[321,88]]]],
                ["inline","button-reply",[],["color","#3B6182","size",31],["loc",[null,[321,104],[321,144]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 296,
                  "column": 6
                },
                "end": {
                  "line": 325,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","message");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","innerWrapper");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-24/24 mt10 mb10");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","image");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","headline");
              var el6 = dom.createTextNode("\n        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6,"class","like-h3");
              var el7 = dom.createTextNode("Antwort von ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                  ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","date");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","reply-button");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1, 1]);
              var element3 = dom.childAt(element2, [1]);
              var element4 = dom.childAt(element3, [3]);
              var morphs = new Array(7);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
              morphs[3] = dom.createUnsafeMorphAt(element4,3,3);
              morphs[4] = dom.createMorphAt(element4,5,5);
              morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["messageContainer reply ",["subexpr","css-bool-evaluator",[["get","item.tmpHide",["loc",[null,[297,64],[297,76]]]],"hidden",""],[],["loc",[null,[297,43],[297,90]]]]]]],
              ["block","if",[["get","reply.sender.picture",["loc",[null,[302,24],[302,44]]]]],[],0,1,["loc",[null,[302,18],[306,25]]]],
              ["block","if",[["get","reply.sender",["loc",[null,[309,47],[309,59]]]]],[],2,3,["loc",[null,[309,41],[309,116]]]],
              ["content","reply.comment",["loc",[null,[310,18],[310,37]]]],
              ["block","if",[["subexpr","and",[["get","item.referenceData",["loc",[null,[311,29],[311,47]]]],["get","item.referenceData.treatmentPatientId",["loc",[null,[311,48],[311,85]]]]],[],["loc",[null,[311,24],[311,86]]]]],[],4,null,["loc",[null,[311,18],[317,25]]]],
              ["inline","time-format",[["get","reply.created",["loc",[null,[320,46],[320,59]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[320,32],[320,82]]]],
              ["block","if",[["subexpr","not",[["get","item.app",["loc",[null,[321,51],[321,59]]]]],[],["loc",[null,[321,46],[321,60]]]]],[],5,null,["loc",[null,[321,40],[321,155]]]]
            ],
            locals: ["reply"],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 327,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","category flex-center");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","ml10");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","innerWrapper");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-24/24 mt20 mb10");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","image");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","headline");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","like-h2 mt0 mb5");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","addTask pt2");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"class","cursor");
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","date");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4,"class","mt30 mb0");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var element24 = dom.childAt(element23, [1]);
            var element25 = dom.childAt(element24, [1]);
            var element26 = dom.childAt(element24, [3]);
            var element27 = dom.childAt(element26, [1]);
            var element28 = dom.childAt(element27, [3]);
            var element29 = dom.childAt(element26, [3]);
            var element30 = dom.childAt(element29, [1, 1]);
            var element31 = dom.childAt(element26, [9]);
            var morphs = new Array(38);
            morphs[0] = dom.createAttrMorph(element23, 'class');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createMorphAt(element25,1,1);
            morphs[3] = dom.createMorphAt(element25,3,3);
            morphs[4] = dom.createMorphAt(element25,5,5);
            morphs[5] = dom.createMorphAt(element25,7,7);
            morphs[6] = dom.createMorphAt(element25,9,9);
            morphs[7] = dom.createMorphAt(element25,11,11);
            morphs[8] = dom.createMorphAt(element25,13,13);
            morphs[9] = dom.createMorphAt(dom.childAt(element25, [15]),0,0);
            morphs[10] = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
            morphs[12] = dom.createMorphAt(element28,3,3);
            morphs[13] = dom.createMorphAt(element28,4,4);
            morphs[14] = dom.createMorphAt(element28,5,5);
            morphs[15] = dom.createAttrMorph(element29, 'class');
            morphs[16] = dom.createElementMorph(element30);
            morphs[17] = dom.createMorphAt(element30,1,1);
            morphs[18] = dom.createMorphAt(element29,3,3);
            morphs[19] = dom.createMorphAt(element29,4,4);
            morphs[20] = dom.createMorphAt(element26,5,5);
            morphs[21] = dom.createMorphAt(dom.childAt(element26, [7]),0,0);
            morphs[22] = dom.createUnsafeMorphAt(element31,1,1);
            morphs[23] = dom.createMorphAt(element31,3,3);
            morphs[24] = dom.createMorphAt(element31,4,4);
            morphs[25] = dom.createMorphAt(element31,6,6);
            morphs[26] = dom.createMorphAt(element31,8,8);
            morphs[27] = dom.createMorphAt(element31,10,10);
            morphs[28] = dom.createMorphAt(element31,11,11);
            morphs[29] = dom.createMorphAt(element31,12,12);
            morphs[30] = dom.createMorphAt(element31,13,13);
            morphs[31] = dom.createMorphAt(element31,14,14);
            morphs[32] = dom.createMorphAt(element31,15,15);
            morphs[33] = dom.createMorphAt(element26,11,11);
            morphs[34] = dom.createMorphAt(element26,12,12);
            morphs[35] = dom.createMorphAt(element26,13,13);
            morphs[36] = dom.createMorphAt(element26,14,14);
            morphs[37] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["messageContainer ",["subexpr","css-bool-evaluator",[["get","item.tmpHide",["loc",[null,[5,56],[5,68]]]],"hidden",""],[],["loc",[null,[5,35],[5,82]]]]]]],
            ["attribute","class",["concat",["message ",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[6,62],[6,66]]]],"in"],[],["loc",[null,[6,58],[6,72]]]],["subexpr","eq",[["get","type",["loc",[null,[6,77],[6,81]]]],"unread"],[],["loc",[null,[6,73],[6,91]]]]],[],["loc",[null,[6,54],[6,92]]]],["get","item.unread",["loc",[null,[6,93],[6,104]]]]],[],["loc",[null,[6,49],[6,105]]]],"unread",""],[],["loc",[null,[6,28],[6,119]]]]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[8,22],[8,38]]]],"jason"],[],["loc",[null,[8,18],[8,47]]]]],[],0,null,["loc",[null,[8,12],[8,147]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[9,22],[9,38]]]],"ehapo"],[],["loc",[null,[9,18],[9,47]]]]],[],1,null,["loc",[null,[9,12],[9,110]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[10,22],[10,38]]]],"lab"],[],["loc",[null,[10,18],[10,45]]]]],[],2,null,["loc",[null,[10,12],[16,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[17,22],[17,38]]]],"invoice"],[],["loc",[null,[17,18],[17,49]]]]],[],3,null,["loc",[null,[17,12],[17,134]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[18,22],[18,38]]]],"practice"],[],["loc",[null,[18,18],[18,50]]]]],[],4,null,["loc",[null,[18,12],[18,133]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[19,22],[19,38]]]],"calendar"],[],["loc",[null,[19,18],[19,50]]]]],[],5,null,["loc",[null,[19,12],[19,133]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[20,26],[20,42]]]],"etigashare"],[],["loc",[null,[20,22],[20,56]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[20,61],[20,77]]]],"app"],[],["loc",[null,[20,57],[20,84]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[20,89],[20,105]]]],"apporder"],[],["loc",[null,[20,85],[20,117]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[20,122],[20,138]]]],"appapp"],[],["loc",[null,[20,118],[20,148]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[20,153],[20,169]]]],"etiga"],[],["loc",[null,[20,149],[20,178]]]]],[],["loc",[null,[20,18],[20,179]]]]],[],6,null,["loc",[null,[20,12],[20,258]]]],
            ["content","item.category.name",["loc",[null,[21,31],[21,53]]]],
            ["block","if",[["subexpr","eq",[["get","item.cssClass",["loc",[null,[25,26],[25,39]]]],"vetconnect"],[],["loc",[null,[25,22],[25,53]]]]],[],7,8,["loc",[null,[25,16],[35,23]]]],
            ["content","item.title",["loc",[null,[38,45],[38,59]]]],
            ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[39,26],[39,30]]]],"in"],[],["loc",[null,[39,22],[39,36]]]]],[],9,null,["loc",[null,[39,16],[42,23]]]],
            ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[43,26],[43,30]]]],"out"],[],["loc",[null,[43,22],[43,37]]]]],[],10,null,["loc",[null,[43,16],[47,23]]]],
            ["block","if",[["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[48,31],[48,47]]]],"etiga"],[],["loc",[null,[48,27],[48,56]]]]],[],["loc",[null,[48,22],[48,57]]]]],[],11,null,["loc",[null,[48,16],[63,23]]]],
            ["attribute","class",["concat",["iconLine ",["subexpr","css-bool-evaluator",[["get","item.unread",["loc",[null,[67,54],[67,65]]]],"","read"],[],["loc",[null,[67,33],[67,77]]]]]]],
            ["element","action",["addTask",["get","item",["loc",[null,[69,38],[69,42]]]]],[],["loc",[null,[69,19],[69,44]]]],
            ["inline","button-add-task",[],["content","Neue verknüpfte Aufgabe","color","#3B6182","size","30","showStroke",true],["loc",[null,[70,18],[70,113]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[73,28],[73,44]]]],"app"],[],["loc",[null,[73,24],[73,51]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[73,56],[73,72]]]],"apporder"],[],["loc",[null,[73,52],[73,84]]]]],[],["loc",[null,[73,20],[73,85]]]]],[],12,13,["loc",[null,[73,14],[82,21]]]],
            ["block","if",[["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[83,33],[83,37]]]],"in"],[],["loc",[null,[83,29],[83,43]]]],["subexpr","eq",[["get","type",["loc",[null,[83,48],[83,52]]]],"unread"],[],["loc",[null,[83,44],[83,62]]]]],[],["loc",[null,[83,25],[83,63]]]],["get","item.unread",["loc",[null,[83,64],[83,75]]]]],[],["loc",[null,[83,20],[83,76]]]]],[],14,15,["loc",[null,[83,14],[102,21]]]],
            ["block","if",[["subexpr","not",[["get","item.unread",["loc",[null,[104,23],[104,34]]]]],[],["loc",[null,[104,18],[104,35]]]]],[],16,null,["loc",[null,[104,12],[108,19]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[109,44],[109,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[109,30],[109,79]]]],
            ["content","item.message",["loc",[null,[111,14],[111,32]]]],
            ["block","if",[["subexpr","and",[["get","item.patient",["loc",[null,[112,23],[112,35]]]],["get","item.patient.etigaActive",["loc",[null,[112,36],[112,60]]]],["get","item.patient.etigaMaster",["loc",[null,[112,61],[112,85]]]],["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[112,95],[112,111]]]],"etiga"],[],["loc",[null,[112,91],[112,120]]]]],[],["loc",[null,[112,86],[112,121]]]]],[],["loc",[null,[112,18],[112,122]]]]],[],17,null,["loc",[null,[112,12],[118,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[119,24],[119,42]]]],"appAppointmentRequest"],[],["loc",[null,[119,20],[119,67]]]]],[],18,null,["loc",[null,[119,14],[133,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[135,24],[135,42]]]],"reorderApp"],[],["loc",[null,[135,20],[135,56]]]]],[],19,null,["loc",[null,[135,14],[160,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[162,24],[162,40]]]],"etiga"],[],["loc",[null,[162,20],[162,49]]]]],[],20,null,["loc",[null,[162,14],[167,23]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[169,26],[169,44]]]],"document"],[],["loc",[null,[169,22],[169,56]]]]],[],21,null,["loc",[null,[169,16],[175,23]]]],
            ["block","if",[["get","item.referenceId",["loc",[null,[176,18],[176,34]]]]],[],22,null,["loc",[null,[176,12],[232,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[233,22],[233,40]]]],"patients"],[],["loc",[null,[233,18],[233,52]]]]],[],23,null,["loc",[null,[233,12],[239,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[240,22],[240,40]]]],"appAppointmentRequest"],[],["loc",[null,[240,18],[240,65]]]]],[],24,null,["loc",[null,[240,12],[246,19]]]],
            ["block","if",[["subexpr","and",[["get","item.patient.id",["loc",[null,[247,25],[247,40]]]],["subexpr","not",[["get","item.patient.etigaMaster",["loc",[null,[247,46],[247,70]]]]],[],["loc",[null,[247,41],[247,71]]]]],[],["loc",[null,[247,20],[247,72]]]]],[],25,null,["loc",[null,[247,14],[254,21]]]],
            ["block","if",[["get","item.tasks",["loc",[null,[255,20],[255,30]]]]],[],26,null,["loc",[null,[255,14],[261,21]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[264,24],[264,40]]]],"appapp"],[],["loc",[null,[264,20],[264,50]]]],["subexpr","and",[["get","item.app",["loc",[null,[264,56],[264,64]]]],["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[264,73],[264,89]]]],"app"],[],["loc",[null,[264,69],[264,96]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[264,101],[264,117]]]],"apporder"],[],["loc",[null,[264,97],[264,129]]]]],[],["loc",[null,[264,65],[264,130]]]]],[],["loc",[null,[264,51],[264,131]]]]],[],["loc",[null,[264,16],[264,132]]]]],[],27,null,["loc",[null,[264,10],[271,17]]]],
            ["block","if",[["subexpr","and",[["get","item.patient.id",["loc",[null,[272,21],[272,36]]]],["subexpr","not",[["get","item.patient.etigaMaster",["loc",[null,[272,42],[272,66]]]]],[],["loc",[null,[272,37],[272,67]]]],["subexpr","not",[["get","item.referenceType",["loc",[null,[272,73],[272,91]]]]],[],["loc",[null,[272,68],[272,92]]]]],[],["loc",[null,[272,16],[272,93]]]]],[],28,null,["loc",[null,[272,10],[278,17]]]],
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","item.category.id",["loc",[null,[279,25],[279,41]]]],"apporder"],[],["loc",[null,[279,21],[279,53]]]],["subexpr","not",[["get","item.referenceData.admissionExecuted",["loc",[null,[279,59],[279,95]]]]],[],["loc",[null,[279,54],[279,96]]]]],[],["loc",[null,[279,16],[279,97]]]]],[],29,null,["loc",[null,[279,10],[285,17]]]],
            ["block","if",[["subexpr","and",[["get","item.referenceData.admissionExecuted",["loc",[null,[286,21],[286,57]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[286,62],[286,78]]]],"apporder"],[],["loc",[null,[286,58],[286,90]]]],["subexpr","eq",[["get","item.status",["loc",[null,[286,95],[286,106]]]],"open"],[],["loc",[null,[286,91],[286,114]]]]],[],["loc",[null,[286,16],[286,115]]]]],[],30,null,["loc",[null,[286,10],[292,17]]]],
            ["block","each",[["get","item.replies",["loc",[null,[296,14],[296,26]]]]],[],31,null,["loc",[null,[296,6],[325,15]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 328,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","items",["loc",[null,[4,12],[4,17]]]]],[],0,null,["loc",[null,[4,4],[327,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 328,
              "column": 2
            },
            "end": {
              "line": 330,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    Zurzeit nichts Neues\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 332,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table no-box@phone");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","items",["loc",[null,[3,8],[3,13]]]]],[],0,1,["loc",[null,[3,2],[330,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('jason-frontend/templates/appointments/calendar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 14
              },
              "end": {
                "line": 56,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element24, 'title');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createAttrMorph(element24, 'style');
            morphs[3] = dom.createElementMorph(element24);
            morphs[4] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[52,30],[52,44]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[52,123],[52,136]]]],"selected",""],[],["loc",[null,[52,102],[52,152]]]]," symbol symbol-circle symbol-40px"]]],
            ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[52,214],[52,224]]]]," !important;"]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[52,74],[52,78]]]]],[],["loc",[null,[52,48],[52,80]]]],
            ["content","user.capitals",["loc",[null,[53,44],[53,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 14
              },
              "end": {
                "line": 61,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element23, 'title');
            morphs[1] = dom.createAttrMorph(element23, 'class');
            morphs[2] = dom.createElementMorph(element23);
            morphs[3] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[57,30],[57,44]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[57,123],[57,136]]]],"selected",""],[],["loc",[null,[57,102],[57,152]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[57,188],[57,198]]]]]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[57,74],[57,78]]]]],[],["loc",[null,[57,48],[57,80]]]],
            ["content","user.capitals",["loc",[null,[58,44],[58,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 12
            },
            "end": {
              "line": 62,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[51,20],[51,33]]]]],[],0,1,["loc",[null,[51,14],[61,21]]]]
        ],
        locals: ["user"],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 88,
              "column": 8
            },
            "end": {
              "line": 92,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 92,
              "column": 8
            },
            "end": {
              "line": 96,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 8
            },
            "end": {
              "line": 104,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 8
            },
            "end": {
              "line": 108,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 113,
              "column": 10
            },
            "end": {
              "line": 117,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 10
            },
            "end": {
              "line": 121,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 8
            },
            "end": {
              "line": 130,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 130,
              "column": 8
            },
            "end": {
              "line": 134,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 10
              },
              "end": {
                "line": 152,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n              label[for=cat-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n             }\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element20,1,1);
            morphs[1] = dom.createMorphAt(element20,3,3);
            return morphs;
          },
          statements: [
            ["content","cat.id",["loc",[null,[148,28],[148,38]]]],
            ["content","cat.color",["loc",[null,[149,34],[149,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 154,
                "column": 12
              },
              "end": {
                "line": 158,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element19,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element19, 'value');
            morphs[1] = dom.createAttrMorph(element19, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[155,30],[155,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[156,31],[156,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 12
              },
              "end": {
                "line": 162,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'value');
            morphs[1] = dom.createAttrMorph(element18, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[159,30],[159,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[160,31],[160,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 163,
                "column": 41
              },
              "end": {
                "line": 163,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" App Termin");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[163,81],[163,162]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 163,
                "column": 173
              },
              "end": {
                "line": 163,
                "column": 193
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","cat.name",["loc",[null,[163,181],[163,193]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 145,
              "column": 8
            },
            "end": {
              "line": 165,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [2]);
          var element22 = dom.childAt(element21, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element21, 'class');
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(element21,1,1);
          morphs[4] = dom.createAttrMorph(element22, 'for');
          morphs[5] = dom.createMorphAt(element22,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","cat.colorHex",["loc",[null,[146,16],[146,28]]]]],[],0,null,["loc",[null,[146,10],[152,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[153,78],[153,87]]]]]]],
          ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[153,91],[153,151]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.cats",["loc",[null,[154,36],[154,48]]]],["get","cat.fullId",["loc",[null,[154,49],[154,59]]]]],[],["loc",[null,[154,18],[154,60]]]]],[],1,2,["loc",[null,[154,12],[162,19]]]],
          ["attribute","for",["concat",["cat-",["get","cat.id",["loc",[null,[163,30],[163,36]]]]]]],
          ["block","if",[["subexpr","eq",[["get","cat.name",["loc",[null,[163,51],[163,59]]]],"etiga App Termin"],[],["loc",[null,[163,47],[163,79]]]]],[],3,4,["loc",[null,[163,41],[163,200]]]]
        ],
        locals: ["cat"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 176,
              "column": 10
            },
            "end": {
              "line": 180,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 180,
              "column": 10
            },
            "end": {
              "line": 184,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 188,
                "column": 10
              },
              "end": {
                "line": 194,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n                label[for=user-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n              }\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element15,1,1);
            morphs[1] = dom.createMorphAt(element15,3,3);
            return morphs;
          },
          statements: [
            ["content","user.id",["loc",[null,[190,31],[190,42]]]],
            ["content","user.color",["loc",[null,[191,34],[191,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 196,
                "column": 12
              },
              "end": {
                "line": 200,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element14,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element14, 'value');
            morphs[1] = dom.createAttrMorph(element14, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[197,30],[197,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[198,32],[198,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 200,
                "column": 12
              },
              "end": {
                "line": 204,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'value');
            morphs[1] = dom.createAttrMorph(element13, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[201,30],[201,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[202,32],[202,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 187,
              "column": 8
            },
            "end": {
              "line": 207,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [2]);
          var element17 = dom.childAt(element16, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element16, 'class');
          morphs[2] = dom.createElementMorph(element16);
          morphs[3] = dom.createMorphAt(element16,1,1);
          morphs[4] = dom.createAttrMorph(element17, 'for');
          morphs[5] = dom.createMorphAt(element17,0,0);
          morphs[6] = dom.createMorphAt(element17,2,2);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[188,16],[188,29]]]]],[],0,null,["loc",[null,[188,10],[194,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","user.color",["loc",[null,[195,78],[195,88]]]]]]],
          ["element","action",["triggerRefreshUser",""],["on","change","preventDefault",false],["loc",[null,[195,92],[195,159]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[196,36],[196,49]]]],["get","user.fullId",["loc",[null,[196,50],[196,61]]]]],[],["loc",[null,[196,18],[196,62]]]]],[],1,2,["loc",[null,[196,12],[204,19]]]],
          ["attribute","for",["concat",["user-",["get","user.id",["loc",[null,[205,31],[205,38]]]]]]],
          ["content","user.firstname",["loc",[null,[205,43],[205,61]]]],
          ["content","user.lastname",["loc",[null,[205,62],[205,79]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 223,
                "column": 10
              },
              "end": {
                "line": 230,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","checkResource");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createAttrMorph(element9, 'value');
            morphs[2] = dom.createAttrMorph(element9, 'id');
            morphs[3] = dom.createAttrMorph(element10, 'for');
            morphs[4] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerRefreshResource",""],["on","change","preventDefault",false],["loc",[null,[224,68],[224,139]]]],
            ["attribute","value",["concat",[["get","resource.id",["loc",[null,[225,30],[225,41]]]]]]],
            ["attribute","id",["concat",["resource-",["get","resource.id",["loc",[null,[226,36],[226,47]]]]]]],
            ["attribute","for",["concat",["resource-",["get","resource.id",["loc",[null,[228,37],[228,48]]]]]]],
            ["content","resource.name",["loc",[null,[228,52],[228,69]]]]
          ],
          locals: ["resource"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 210,
              "column": 6
            },
            "end": {
              "line": 232,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","header");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2,"class","mb20 float-left mt20 ml20");
          var el3 = dom.createTextNode("Resourcen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","upper checkbox-custom checkbox-primary mb5");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"value","9999");
          dom.setAttribute(el3,"class","checkResource");
          dom.setAttribute(el3,"id","resource-9999");
          dom.setAttribute(el3,"type","checkbox");
          dom.setAttribute(el3,"checked","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","resource-9999");
          var el4 = dom.createTextNode("Alle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [1]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element12, [1]),[],true); }
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          morphs[1] = dom.createAttrMorph(element11, 'class');
          morphs[2] = dom.createElementMorph(element12);
          morphs[3] = dom.createMorphAt(element11,3,3);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","resourcesExpanded",["loc",[null,[214,107],[214,124]]]]],[],[]],"toggleAll","toggleResources","content","ein/ausblenden"],["loc",[null,[214,10],[214,179]]]],
          ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","resourcesExpanded",["loc",[null,[216,58],[216,75]]]],"","hidden"],[],["loc",[null,[216,37],[216,89]]]]]]],
          ["element","action",["triggerRefreshResource","all"],["on","change","preventDefault",false],["loc",[null,[217,66],[217,140]]]],
          ["block","each",[["get","resources",["loc",[null,[223,18],[223,27]]]]],[],0,null,["loc",[null,[223,10],[230,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 239,
              "column": 10
            },
            "end": {
              "line": 243,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 243,
              "column": 10
            },
            "end": {
              "line": 247,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 252,
                "column": 12
              },
              "end": {
                "line": 256,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element5,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'value');
            morphs[1] = dom.createAttrMorph(element5, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[253,30],[253,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[254,32],[254,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 256,
                "column": 12
              },
              "end": {
                "line": 260,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createAttrMorph(element4, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[257,30],[257,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[258,32],[258,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 250,
              "column": 8
            },
            "end": {
              "line": 263,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          morphs[2] = dom.createAttrMorph(element7, 'for');
          morphs[3] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshLocation",""],["on","change","preventDefault",false],["loc",[null,[251,66],[251,137]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[252,36],[252,49]]]],["get","room.fullId",["loc",[null,[252,50],[252,61]]]]],[],["loc",[null,[252,18],[252,62]]]]],[],0,1,["loc",[null,[252,12],[260,19]]]],
          ["attribute","for",["concat",["room-",["get","room.id",["loc",[null,[261,31],[261,38]]]]]]],
          ["content","room.name",["loc",[null,[261,42],[261,55]]]]
        ],
        locals: ["room"],
        templates: [child0, child1]
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 10
            },
            "end": {
              "line": 276,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 276,
              "column": 10
            },
            "end": {
              "line": 280,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 12
              },
              "end": {
                "line": 290,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[287,30],[287,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[288,35],[288,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 290,
                "column": 12
              },
              "end": {
                "line": 294,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[291,30],[291,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[292,35],[292,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 284,
              "column": 8
            },
            "end": {
              "line": 297,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createAttrMorph(element3, 'for');
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshStation",""],["on","change","preventDefault",false],["loc",[null,[285,66],[285,136]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[286,36],[286,52]]]],["get","station.fullId",["loc",[null,[286,53],[286,67]]]]],[],["loc",[null,[286,18],[286,68]]]]],[],0,1,["loc",[null,[286,12],[294,19]]]],
          ["attribute","for",["concat",["station-",["get","station.id",["loc",[null,[295,34],[295,44]]]]]]],
          ["content","station.name",["loc",[null,[295,48],[295,64]]]]
        ],
        locals: ["station"],
        templates: [child0, child1]
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 302,
              "column": 2
            },
            "end": {
              "line": 305,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[303,61],[303,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 374,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","calendarWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fc fc-ltr fc-unthemed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendarStickyArea");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center mb20");
        dom.setAttribute(el4,"id","calendarDateToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("Heute\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-prev-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon ml5 btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-next-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","current-date fs-2 fw-bold");
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 fs-7 text-gray-500 fw-bold");
        var el8 = dom.createTextNode("KW ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex justify-content-end");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","flex-center mr20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","mr5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Terminart");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","colorMode");
        dom.setAttribute(el8,"class","switch neutral");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Mitarbeiter:in");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select mr10");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn btn-icon btn-light text-center");
        var el7 = dom.createTextNode("Ansicht\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","calendarFilterToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","headline");
        var el7 = dom.createTextNode("Geteilte Ansicht je Mitarbeiter:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendar");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","tooltipTest");
        dom.setAttribute(el2,"class","tooltip hidden");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","head");
        var el4 = dom.createTextNode("Details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/clock.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","time");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line category");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","category");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line customer");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/customer-info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","info");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line appStatus");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","appStatus");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("aside");
        dom.setAttribute(el1,"id","calendarSidebar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"type","button");
        dom.setAttribute(el2,"class","mfp-close");
        var el3 = dom.createTextNode("X");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box scrollable");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 mt20");
        var el5 = dom.createTextNode("Typ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calApp");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-app");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calApp");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Kund:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppMa");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appUser");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppMa");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Mitarbeiter:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppEtiga");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appEtiga");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppEtiga mr0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" App Termine");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calTask");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-task");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calTask");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Aufgaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left ml20 mt20");
        var el5 = dom.createTextNode("Terminarten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Personen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Lokationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","room-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Stationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","station-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar ').css('min-height', $(window).height()-100);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-100);\n      $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());\n      $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n    });\n    $('aside.side-bar').css('min-height', $(window).height() - 150);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 150);\n    $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [0]);
        var element26 = dom.childAt(element25, [1, 1]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element28, [3]);
        var element31 = dom.childAt(element30, [1]);
        var element32 = dom.childAt(element30, [3]);
        var element33 = dom.childAt(element27, [3, 1]);
        var element34 = dom.childAt(element27, [5]);
        var element35 = dom.childAt(element34, [1, 3]);
        var element36 = dom.childAt(element34, [5]);
        var element37 = dom.childAt(element26, [3]);
        var element38 = dom.childAt(fragment, [2]);
        var element39 = dom.childAt(element38, [1]);
        var element40 = dom.childAt(element38, [3]);
        var element41 = dom.childAt(element40, [1]);
        var element42 = dom.childAt(element40, [3]);
        var element43 = dom.childAt(element40, [7]);
        var element44 = dom.childAt(element40, [9]);
        var element45 = dom.childAt(element40, [12]);
        var element46 = dom.childAt(element40, [15]);
        var element47 = dom.childAt(element40, [19]);
        var element48 = dom.childAt(element40, [23]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element40, [29]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element40, [33]);
        var element53 = dom.childAt(element52, [1]);
        var morphs = new Array(55);
        morphs[0] = dom.createAttrMorph(element25, 'class');
        morphs[1] = dom.createAttrMorph(element26, 'class');
        morphs[2] = dom.createElementMorph(element29);
        morphs[3] = dom.createElementMorph(element31);
        morphs[4] = dom.createElementMorph(element32);
        morphs[5] = dom.createMorphAt(element33,1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element33, [2]),1,1);
        morphs[7] = dom.createElementMorph(element35);
        morphs[8] = dom.createMorphAt(element35,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element34, [3]),1,1);
        morphs[10] = dom.createElementMorph(element36);
        morphs[11] = dom.createAttrMorph(element37, 'class');
        morphs[12] = dom.createMorphAt(dom.childAt(element37, [1, 3]),1,1);
        morphs[13] = dom.createAttrMorph(element38, 'class');
        morphs[14] = dom.createElementMorph(element39);
        morphs[15] = dom.createElementMorph(element41);
        morphs[16] = dom.createMorphAt(element41,0,0);
        morphs[17] = dom.createElementMorph(element42);
        morphs[18] = dom.createMorphAt(element42,0,0);
        morphs[19] = dom.createElementMorph(element43);
        morphs[20] = dom.createMorphAt(element43,1,1);
        morphs[21] = dom.createElementMorph(element44);
        morphs[22] = dom.createMorphAt(element44,1,1);
        morphs[23] = dom.createElementMorph(element45);
        morphs[24] = dom.createMorphAt(element45,1,1);
        morphs[25] = dom.createMorphAt(dom.childAt(element45, [3]),2,2);
        morphs[26] = dom.createElementMorph(element46);
        morphs[27] = dom.createMorphAt(element46,1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element40, [17]),3,3);
        morphs[29] = dom.createAttrMorph(element47, 'class');
        morphs[30] = dom.createMorphAt(element47,1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element40, [21]),3,3);
        morphs[32] = dom.createAttrMorph(element48, 'class');
        morphs[33] = dom.createElementMorph(element49);
        morphs[34] = dom.createMorphAt(element49,1,1);
        morphs[35] = dom.createMorphAt(element48,3,3);
        morphs[36] = dom.createMorphAt(element40,25,25);
        morphs[37] = dom.createMorphAt(dom.childAt(element40, [27]),3,3);
        morphs[38] = dom.createAttrMorph(element50, 'class');
        morphs[39] = dom.createElementMorph(element51);
        morphs[40] = dom.createMorphAt(element51,1,1);
        morphs[41] = dom.createMorphAt(element50,3,3);
        morphs[42] = dom.createMorphAt(dom.childAt(element40, [31]),3,3);
        morphs[43] = dom.createAttrMorph(element52, 'class');
        morphs[44] = dom.createElementMorph(element53);
        morphs[45] = dom.createMorphAt(element53,1,1);
        morphs[46] = dom.createMorphAt(element52,3,3);
        morphs[47] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[48] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[49] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[50] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[51] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[52] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[53] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[54] = dom.createMorphAt(fragment,18,18,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","hideBgEvents",["loc",[null,[1,58],[1,70]]]],"hideBgEvents",""],[],["loc",[null,[1,37],[1,90]]]]," ",["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[1,112],[1,122]]]],"withFilter",""],[],["loc",[null,[1,91],[1,140]]]]," col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[1,173],[1,186]]]]],[],["loc",[null,[1,168],[1,187]]]],"24","19"],[],["loc",[null,[1,147],[1,199]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,258],[1,271]]]],"sidebar-active",""],[],["loc",[null,[1,237],[1,293]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[3,72],[3,85]]]]],[],["loc",[null,[3,67],[3,86]]]],"24","20"],[],["loc",[null,[3,46],[3,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["today"],[],["loc",[null,[6,18],[6,36]]]],
        ["element","action",["prev"],[],["loc",[null,[9,34],[9,51]]]],
        ["element","action",["next"],[],["loc",[null,[11,34],[11,51]]]],
        ["content","currentDateFormatted",["loc",[null,[16,51],[16,75]]]],
        ["content","weekNumber",["loc",[null,[17,54],[17,68]]]],
        ["element","action",["toggleColorMode"],[],["loc",[null,[23,39],[23,67]]]],
        ["inline","input",[],["type","checkbox","id","colorMode","classNames","switch","name","colorMode","checked",["subexpr","@mut",[["get","colorModeUserEnabled",["loc",[null,[24,98],[24,118]]]]],[],[]]],["loc",[null,[24,14],[24,120]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","views",["loc",[null,[32,22],[32,27]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedView",["loc",[null,[33,20],[33,32]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown custom-calendar"],["loc",[null,[31,12],[39,14]]]],
        ["element","action",["toggleFilter"],[],["loc",[null,[42,32],[42,57]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[46,39],[46,49]]]],"","hidden"],[],["loc",[null,[46,18],[46,63]]]]," mb10"]]],
        ["block","each",[["get","users",["loc",[null,[50,20],[50,25]]]]],[],0,null,["loc",[null,[50,12],[62,21]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[78,38],[78,51]]]],"","hidden"],[],["loc",[null,[78,17],[78,65]]]]," side-bar card col l-5/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["toggleSidebar",true],[],["loc",[null,[79,42],[79,73]]]],
        ["element","action",["toggleMe"],[],["loc",[null,[81,18],[81,39]]]],
        ["inline","button-toggle-me",[],["size",40,"strokeWidth",1,"classNames","toggleMe","active",["subexpr","@mut",[["get","onlyMine",["loc",[null,[81,111],[81,119]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","onlyMine",["loc",[null,[81,148],[81,156]]]],"Persönliche Ansicht","Praxis Ansicht"],[],["loc",[null,[81,128],[81,196]]]]],["loc",[null,[81,41],[81,198]]]],
        ["element","action",["ical"],[],["loc",[null,[82,18],[82,35]]]],
        ["inline","button-ical",[],["size",32,"classNames","ical"],["loc",[null,[82,37],[82,78]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[87,79],[87,139]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[88,32],[88,45]]]],"type-app"],[],["loc",[null,[88,14],[88,57]]]]],[],1,2,["loc",[null,[88,8],[96,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[99,81],[99,141]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[100,32],[100,45]]]],"type-appUser"],[],["loc",[null,[100,14],[100,61]]]]],[],3,4,["loc",[null,[100,8],[108,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[112,86],[112,146]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[113,34],[113,47]]]],"type-appEtiga"],[],["loc",[null,[113,16],[113,64]]]]],[],5,6,["loc",[null,[113,10],[121,17]]]],
        ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","16","width",28],["loc",[null,[122,75],[122,152]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[125,80],[125,140]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[126,32],[126,45]]]],"type-task"],[],["loc",[null,[126,14],[126,58]]]]],[],7,8,["loc",[null,[126,8],[134,15]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","catsExpanded",["loc",[null,[141,104],[141,116]]]]],[],[]],"toggleAll","toggleCats","content","ein/ausblenden"],["loc",[null,[141,8],[141,166]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","catsExpanded",["loc",[null,[144,56],[144,68]]]],"","hidden"],[],["loc",[null,[144,35],[144,82]]]]]]],
        ["block","each",[["get","categories",["loc",[null,[145,16],[145,26]]]]],[],9,null,["loc",[null,[145,8],[165,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","usersExpanded",["loc",[null,[170,104],[170,117]]]]],[],[]],"toggleAll","toggleUsers","content","ein/ausblenden"],["loc",[null,[170,8],[170,168]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","usersExpanded",["loc",[null,[173,56],[173,69]]]],"","hidden"],[],["loc",[null,[173,35],[173,83]]]]]]],
        ["element","action",["triggerRefreshUser","all"],["on","change","preventDefault",false],["loc",[null,[175,64],[175,134]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[176,34],[176,47]]]],"user-9999"],[],["loc",[null,[176,16],[176,60]]]]],[],10,11,["loc",[null,[176,10],[184,17]]]],
        ["block","each",[["get","users",["loc",[null,[187,16],[187,21]]]]],[],12,null,["loc",[null,[187,8],[207,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[210,12],[210,20]]]]],[],13,null,["loc",[null,[210,6],[232,13]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","roomsExpanded",["loc",[null,[235,105],[235,118]]]]],[],[]],"toggleAll","toggleRooms","content","ein/ausblenden"],["loc",[null,[235,8],[235,169]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","roomsExpanded",["loc",[null,[237,56],[237,69]]]],"","hidden"],[],["loc",[null,[237,35],[237,83]]]]]]],
        ["element","action",["triggerRefreshLocation","all"],["on","change","preventDefault",false],["loc",[null,[238,64],[238,138]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[239,34],[239,47]]]],"room-9999"],[],["loc",[null,[239,16],[239,60]]]]],[],14,15,["loc",[null,[239,10],[247,17]]]],
        ["block","each",[["get","rooms",["loc",[null,[250,16],[250,21]]]]],[],16,null,["loc",[null,[250,8],[263,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","stationsExpanded",["loc",[null,[268,105],[268,121]]]]],[],[]],"toggleAll","toggleStations","content","ein/ausblenden"],["loc",[null,[268,8],[268,175]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","stationsExpanded",["loc",[null,[270,56],[270,72]]]],"","hidden"],[],["loc",[null,[270,35],[270,86]]]]]]],
        ["element","action",["triggerRefreshStation","all"],["on","change","preventDefault",false],["loc",[null,[271,64],[271,137]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[272,34],[272,50]]]],"station-9999"],[],["loc",[null,[272,16],[272,66]]]]],[],17,18,["loc",[null,[272,10],[280,17]]]],
        ["block","each",[["get","stations",["loc",[null,[284,16],[284,24]]]]],[],19,null,["loc",[null,[284,8],[297,17]]]],
        ["block","unless",[["get","sidebarActive",["loc",[null,[302,12],[302,25]]]]],[],20,null,["loc",[null,[302,2],[305,13]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[307,33],[307,46]]]]],[],[]],"goto","goto","startTask","startTask","linkTo","linkTo","create","createNotification","addReply","addReply","finishTask","finishTaskSend","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[307,0],[315,45]]]],
        ["inline","add-appointment-panel",[],["categories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[317,35],[317,52]]]]],[],[]],"etigaCategories",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[318,40],[318,55]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[319,30],[319,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[320,32],[320,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[321,35],[321,45]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[322,34],[322,43]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[323,30],[323,35]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[324,30],[324,35]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[325,33],[325,41]]]]],[],[]],"showDelete",true,"showSeries",true,"supportUserApps",true,"showAdmission",true,"gotoPatient","gotoPatient","gotoEtigaMaster","gotoPatientEtiga","reloadPatients","reloadPatients","startAdmission","admissionPatient","gotoTreatment","gotoTreatment","create","createAppointment","delete","deleteAppointmentCheckEtiga","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[337,39],[337,58]]]]],[],[]]],["loc",[null,[317,0],[337,60]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[343,8],[343,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[344,10],[344,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[345,13],[345,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[346,17],[346,36]]]]],[],[]]],["loc",[null,[339,0],[346,38]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[351,95],[351,117]]]]],[],[]]],["loc",[null,[348,0],[351,119]]]],
        ["inline","app-denial-panel",[],["confirm","deleteAppointment","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","appDenialPanel",["loc",[null,[357,43],[357,57]]]]],[],[]]],["loc",[null,[353,0],[357,59]]]],
        ["inline","ical-link-panel",[],["actionReceiver",["subexpr","@mut",[["get","icalLinkPanel",["loc",[null,[359,33],[359,46]]]]],[],[]]],["loc",[null,[359,0],[359,48]]]],
        ["inline","choose-appointment-edit-type-panel",[],["editEvent","editEvent","actionReceiver",["subexpr","@mut",[["get","chooseAppointmentEditTypePanel",["loc",[null,[360,74],[360,104]]]]],[],[]]],["loc",[null,[360,0],[360,106]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20]
    };
  }()));

});
define('jason-frontend/components/services-edit-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      addTag: function addTag(tag) {
        if (!this.get('model').tags) {
          this.get('model').tags = [];
        }
        Ember['default'].set(tag, 'selected', true);
        this.get('model').tags.addObject(tag);
      },
      removeTag: function removeTag(tag) {
        if (tag.global == true) {
          return;
        }
        model.tags.removeObject(tag);

        this.get('articleTags').forEach(function (item) {
          var id = item.id;
          if (id == tag.id) {
            Ember['default'].set(item, 'selected', false);
          }
        });
      },
      toggleFavorite: function toggleFavorite(model) {
        if (model.favorite) {
          model.favorite = false;
        } else {
          model.favorite = true;
        }
      }
    }

  });

});
define('jason-frontend/templates/components/stock-details', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 12
              },
              "end": {
                "line": 7,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element20, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[6,87],[6,105]]]]],[],["loc",[null,[6,71],[6,107]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 12
              },
              "end": {
                "line": 9,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 100
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","title title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.partnerCategory",["loc",[null,[13,63],[13,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 17
              },
              "end": {
                "line": 22,
                "column": 41
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Ja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 41
              },
              "end": {
                "line": 22,
                "column": 53
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Nein");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 6
            },
            "end": {
              "line": 29,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-8/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","u-text--center pr30");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-16/24");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","product-description");
          dom.setAttribute(el2,"class","");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dataList grid");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Verpackungsmenge");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Kategorie");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","");
          var el6 = dom.createTextNode("Suchtgiftmittel");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","");
          var el6 = dom.createTextNode("Wirkstoffe / Zusatzstoffe");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [3]);
          var element22 = dom.childAt(element21, [3, 1, 1]);
          var element23 = dom.childAt(element22, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(element21,1,1);
          morphs[2] = dom.createMorphAt(element23,0,0);
          morphs[3] = dom.createMorphAt(element23,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(element22, [7]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element22, [11]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element22, [15]),0,0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.picture",["loc",[null,[5,18],[5,31]]]]],[],0,1,["loc",[null,[5,12],[9,19]]]],
          ["block","if",[["get","model.partnerCategory",["loc",[null,[13,14],[13,35]]]]],[],2,null,["loc",[null,[13,8],[13,107]]]],
          ["content","purchaseProduct.packageQuantity",["loc",[null,[18,17],[18,52]]]],
          ["content","model.unit.name",["loc",[null,[18,53],[18,72]]]],
          ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[20,43],[20,62]]]],"-"],[],["loc",[null,[20,17],[20,68]]]],
          ["block","if",[["get","model.narcotic",["loc",[null,[22,23],[22,37]]]]],[],3,4,["loc",[null,[22,17],[22,60]]]],
          ["inline","get-value-or-empty-sign",[["get","model.ingredients",["loc",[null,[24,43],[24,60]]]],["get","-",["loc",[null,[24,61],[24,62]]]]],[],["loc",[null,[24,17],[24,65]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 13
              },
              "end": {
                "line": 37,
                "column": 143
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell l-3/24 table__cell--center");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","table__title title");
            var el3 = dom.createTextNode("Lager");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 12
                },
                "end": {
                  "line": 53,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell l-8/24 l-3/24@desk table__cell--center");
              var el2 = dom.createTextNode("\n               ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.warehouseName",["loc",[null,[52,15],[52,37]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 16
                },
                "end": {
                  "line": 71,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Korrektur");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openCorrection",["get","item.id",["loc",[null,[68,47],[68,54]]]]],[],["loc",[null,[68,21],[68,56]]]],
              ["inline","button-correct",[],["classNames","ml5","color","#fff","size","32","showStroke",false],["loc",[null,[69,20],[69,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 16
                },
                "end": {
                  "line": 76,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openOutgoing",["get","item.id",["loc",[null,[73,45],[73,52]]]]],[],["loc",[null,[73,21],[73,54]]]],
              ["inline","button-outgoing",[],["showStroke",false,"size",32,"classNames","","color","#fff"],["loc",[null,[74,20],[74,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 16
                },
                "end": {
                  "line": 84,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(element5,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["showStock",["get","item.id",["loc",[null,[81,109],[81,116]]]]],[],["loc",[null,[81,88],[81,118]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false,"content","Inventar-Details anzeigen"],["loc",[null,[82,20],[82,111]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 59
                  },
                  "end": {
                    "line": 103,
                    "column": 98
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" / ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","history.type",["loc",[null,[103,79],[103,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 105
                  },
                  "end": {
                    "line": 103,
                    "column": 147
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","history.subType",["loc",[null,[103,128],[103,147]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 105,
                    "column": 62
                  },
                  "end": {
                    "line": 105,
                    "column": 92
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("+");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 62
                  },
                  "end": {
                    "line": 106,
                    "column": 153
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","history.user.shortName",["loc",[null,[106,127],[106,153]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 70
                    },
                    "end": {
                      "line": 108,
                      "column": 209
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","history.referenceText",["loc",[null,[108,177],[108,202]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 22
                  },
                  "end": {
                    "line": 109,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","link-to",["inventory.stocktaking-detail",["get","history.referenceId",["loc",[null,[108,112],[108,131]]]]],["class","is-highlighted"],0,null,["loc",[null,[108,70],[108,221]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 109,
                      "column": 22
                    },
                    "end": {
                      "line": 110,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","cursor is-highlighted");
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","small");
                  var el3 = dom.createTextNode("Patient ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]),1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["showPatient",["get","history.referenceId",["loc",[null,[109,140],[109,159]]]]],[],["loc",[null,[109,117],[109,161]]]],
                  ["content","history.referenceText",["loc",[null,[109,220],[109,245]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 110,
                          "column": 68
                        },
                        "end": {
                          "line": 110,
                          "column": 193
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","history.referenceText",["loc",[null,[110,161],[110,186]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 110,
                        "column": 22
                      },
                      "end": {
                        "line": 111,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["purchases.show",["get","history.referenceId",["loc",[null,[110,96],[110,115]]]]],["class","is-highlighted"],0,null,["loc",[null,[110,68],[110,205]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 111,
                          "column": 22
                        },
                        "end": {
                          "line": 112,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","cursor is-highlighted");
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2,"class","small");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("2");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [0]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element0);
                      morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["showInvoice",["get","history.referenceId",["loc",[null,[111,93],[111,112]]]]],[],["loc",[null,[111,70],[111,114]]]],
                      ["content","history.referenceText",["loc",[null,[111,165],[111,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 112,
                            "column": 22
                          },
                          "end": {
                            "line": 113,
                            "column": 22
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","small");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                      ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["content","history.notice",["loc",[null,[112,68],[112,86]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 112,
                          "column": 22
                        },
                        "end": {
                          "line": 113,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","history.notice",["loc",[null,[112,32],[112,46]]]]],[],0,null,["loc",[null,[112,22],[113,22]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 111,
                        "column": 22
                      },
                      "end": {
                        "line": 113,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[111,36],[111,57]]]],"sale"],[],["loc",[null,[111,32],[111,65]]]]],[],0,1,["loc",[null,[111,22],[113,22]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 22
                    },
                    "end": {
                      "line": 113,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[110,36],[110,57]]]],"order"],[],["loc",[null,[110,32],[110,66]]]]],[],0,1,["loc",[null,[110,22],[113,22]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 22
                  },
                  "end": {
                    "line": 113,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[109,40],[109,61]]]],"treatment"],[],["loc",[null,[109,36],[109,74]]]],["subexpr","eq",[["get","history.referenceType",["loc",[null,[109,79],[109,100]]]],"patient"],[],["loc",[null,[109,75],[109,111]]]]],[],["loc",[null,[109,32],[109,112]]]]],[],0,1,["loc",[null,[109,22],[113,22]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 116,
                    "column": 20
                  },
                  "end": {
                    "line": 120,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["size","28","item",["subexpr","@mut",[["get","history",["loc",[null,[118,70],[118,77]]]]],[],[]],"confirmed","bookoutMovement","text","Wollen Sie diese Lagerbewegung wirklich stornieren?","content","Lagerbewegung stornieren"],["loc",[null,[118,26],[118,201]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 18
                },
                "end": {
                  "line": 123,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--action");
              dom.setAttribute(el2,"style","padding-top: 5px !important;");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element2, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
              morphs[1] = dom.createMorphAt(element3,0,0);
              morphs[2] = dom.createMorphAt(element3,1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              morphs[4] = dom.createMorphAt(element4,0,0);
              morphs[5] = dom.createMorphAt(element4,2,2);
              morphs[6] = dom.createMorphAt(element4,4,4);
              morphs[7] = dom.createMorphAt(dom.childAt(element2, [9]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element2, [11]),1,1);
              morphs[9] = dom.createMorphAt(dom.childAt(element2, [13]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","history.created",["loc",[null,[101,38],[101,53]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[101,24],[101,76]]]],
              ["block","if",[["get","history.type",["loc",[null,[103,65],[103,77]]]]],[],0,null,["loc",[null,[103,59],[103,105]]]],
              ["block","if",[["get","history.subType",["loc",[null,[103,111],[103,126]]]]],[],1,null,["loc",[null,[103,105],[103,154]]]],
              ["inline","show-movement-type",[],["value",["subexpr","@mut",[["get","history.amount",["loc",[null,[104,89],[104,103]]]]],[],[]],"classNames","movement--type","size",26],["loc",[null,[104,62],[104,141]]]],
              ["block","if",[["subexpr","gt",[["get","history.amount",["loc",[null,[105,72],[105,86]]]],0],[],["loc",[null,[105,68],[105,89]]]]],[],2,null,["loc",[null,[105,62],[105,99]]]],
              ["content","history.amount",["loc",[null,[105,100],[105,118]]]],
              ["content","item.unit.name",["loc",[null,[105,119],[105,137]]]],
              ["block","link-to",["users.edit",["get","history.user.id",["loc",[null,[106,86],[106,101]]]]],["class","is-highlighted"],3,null,["loc",[null,[106,62],[106,165]]]],
              ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[108,32],[108,53]]]],"stocktaking"],[],["loc",[null,[108,28],[108,68]]]]],[],4,5,["loc",[null,[108,22],[113,29]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","history.cancelled",["loc",[null,[116,36],[116,53]]]]],[],["loc",[null,[116,31],[116,54]]]],["subexpr","not",[["get","history.wasCancelled",["loc",[null,[116,60],[116,80]]]]],[],["loc",[null,[116,55],[116,81]]]],["get","showActionButtons",["loc",[null,[116,82],[116,99]]]]],[],["loc",[null,[116,26],[116,100]]]]],[],6,null,["loc",[null,[116,20],[120,27]]]]
            ],
            locals: ["history"],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 10
              },
              "end": {
                "line": 128,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--toggle l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-2/24@desk table__cell--center");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createElement("b");
            var el4 = dom.createElement("span");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("/");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"style","padding-right: 50px");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","button p1 inline-block");
            dom.setAttribute(el3,"style","position: absolute;right:0");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk pt15");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk pt15");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk table__cell--action buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square cursor");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24@desk table__second-row hidden");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pl20 box no-box@phone table table--invoice pr0");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Datum");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Typ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-1/24");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Bewegte Menge");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-5/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("MitarbeiterIn");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Referenz");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-2/24");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [5]);
            var element10 = dom.childAt(element9, [0, 0]);
            var element11 = dom.childAt(element8, [9]);
            var element12 = dom.childAt(element11, [3]);
            var element13 = dom.childAt(element8, [15]);
            var element14 = dom.childAt(element13, [4]);
            var element15 = dom.childAt(element8, [17]);
            var morphs = new Array(21);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
            morphs[2] = dom.createAttrMorph(element9, 'class');
            morphs[3] = dom.createAttrMorph(element10, 'class');
            morphs[4] = dom.createMorphAt(element10,0,0);
            morphs[5] = dom.createMorphAt(element10,2,2);
            morphs[6] = dom.createMorphAt(element9,2,2);
            morphs[7] = dom.createMorphAt(element8,7,7);
            morphs[8] = dom.createAttrMorph(element11, 'class');
            morphs[9] = dom.createMorphAt(element11,1,1);
            morphs[10] = dom.createElementMorph(element12);
            morphs[11] = dom.createMorphAt(element12,1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element8, [11]),1,1);
            morphs[13] = dom.createMorphAt(dom.childAt(element8, [13]),1,1);
            morphs[14] = dom.createMorphAt(element13,1,1);
            morphs[15] = dom.createMorphAt(element13,2,2);
            morphs[16] = dom.createElementMorph(element14);
            morphs[17] = dom.createMorphAt(element14,1,1);
            morphs[18] = dom.createMorphAt(element13,6,6);
            morphs[19] = dom.createAttrMorph(element15, 'data-id');
            morphs[20] = dom.createMorphAt(dom.childAt(element15, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","item",["loc",[null,[47,58],[47,62]]]]],[],[]],"toggle","toggle","content","Lagerbewegungen anzeigen"],["loc",[null,[47,36],[47,115]]]],
            ["content","item.id",["loc",[null,[49,78],[49,89]]]],
            ["attribute","class",["concat",["table__cell l-8/24 l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[50,68],[50,87]]]],"3","4"],[],["loc",[null,[50,47],[50,97]]]],"/24@desk"]]],
            ["attribute","class",["concat",[["subexpr","get-stock-color",[["get","item.flatStock",["loc",[null,[50,141],[50,155]]]],["get","item.packageQuantity",["loc",[null,[50,156],[50,176]]]]],[],["loc",[null,[50,123],[50,178]]]]]]],
            ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[50,203],[50,217]]]]],[],["loc",[null,[50,180],[50,219]]]],
            ["content","item.packageQuantity",["loc",[null,[50,220],[50,244]]]],
            ["content","item.unit.name",["loc",[null,[50,256],[50,274]]]],
            ["block","if",[["get","showWarehouseInLine",["loc",[null,[51,18],[51,37]]]]],[],0,null,["loc",[null,[51,12],[53,27]]]],
            ["attribute","class",["concat",["table__cell l-8/24 l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[54,68],[54,87]]]],"4","6"],[],["loc",[null,[54,47],[54,97]]]],"/24@desk pt15"]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.batchNumber",["loc",[null,[55,30],[55,46]]]]],[],[]],"id",["subexpr","concat",["batch",["get","item.id",["loc",[null,[55,66],[55,73]]]]],[],["loc",[null,[55,50],[55,74]]]],"class","gui-input","name","chargeNumber","focus-out",["subexpr","action",["updateBatchNumber",["get","item",["loc",[null,[55,151],[55,155]]]]],[],["loc",[null,[55,123],[55,156]]]]],["loc",[null,[55,16],[55,158]]]],
            ["element","action",["openScan",["get","item",["loc",[null,[56,39],[56,43]]]]],[],["loc",[null,[56,19],[56,45]]]],
            ["inline","button-qr",[],["size","38","showStroke",false,"content","Bar/QR-Code scannen","classNames","scan-icon"],["loc",[null,[57,18],[57,111]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.expiryDate",["loc",[null,[61,30],[61,45]]]]],[],[]],"class","expiry gui-input js-date-picker input","name","expiryDate","focus-out",["subexpr","action",["updateExpiry",["get","item",["loc",[null,[61,143],[61,147]]]]],[],["loc",[null,[61,120],[61,148]]]]],["loc",[null,[61,16],[61,150]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.locationInfo",["loc",[null,[64,30],[64,47]]]]],[],[]],"class","gui-input","name","locationInfo","autocomplete","off","focus-out",["subexpr","action",["updateLocation",["get","item",["loc",[null,[64,140],[64,144]]]]],[],["loc",[null,[64,115],[64,145]]]]],["loc",[null,[64,16],[64,147]]]],
            ["block","if",[["get","showCorrectionButton",["loc",[null,[67,22],[67,42]]]]],[],1,null,["loc",[null,[67,16],[71,23]]]],
            ["block","if",[["get","showActionButtons",["loc",[null,[72,22],[72,39]]]]],[],2,null,["loc",[null,[72,16],[76,23]]]],
            ["element","action",["printBarcode",["get","item.id",["loc",[null,[77,110],[77,117]]]]],[],["loc",[null,[77,86],[77,119]]]],
            ["inline","button-qr",[],["size","30","content","QR-Label herunterladen","showStroke",false,"color","#fff"],["loc",[null,[78,18],[78,104]]]],
            ["block","if",[["get","showInventoryDetailsButton",["loc",[null,[80,22],[80,48]]]]],[],3,null,["loc",[null,[80,16],[84,23]]]],
            ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[86,88],[86,95]]]]]]],
            ["block","each",[["get","item.movements",["loc",[null,[98,26],[98,40]]]]],[],4,null,["loc",[null,[98,18],[123,27]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 6
            },
            "end": {
              "line": 131,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table table--large no-hover mt30");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-1/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24 table__cell--center");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Inv-Nr");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Verfügbar");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Chargen-Nr");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24 ");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Ablaufdatum");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Lagerplatz");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element17, [5]);
          var element19 = dom.childAt(element17, [9]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element18, 'class');
          morphs[1] = dom.createMorphAt(element17,7,7);
          morphs[2] = dom.createAttrMorph(element19, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element16, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[36,60],[36,79]]]],"3","4"],[],["loc",[null,[36,39],[36,89]]]],"/24"]]],
          ["block","if",[["get","showWarehouseInLine",["loc",[null,[37,19],[37,38]]]]],[],0,null,["loc",[null,[37,13],[37,150]]]],
          ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[38,60],[38,79]]]],"4","6"],[],["loc",[null,[38,39],[38,89]]]],"/24"]]],
          ["block","each",[["get","items",["loc",[null,[44,18],[44,23]]]]],[],1,null,["loc",[null,[44,10],[128,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 6
            },
            "end": {
              "line": 133,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        Keine Lagerbestände\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 171,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/stock-details.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","box");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n      $(\"body\").delegate(\".js-date-picker\", \"focusin\", function(){\n        $(this).daterangepicker({\n          singleDatePicker: true,\n          showDropdowns: true,\n          \"locale\": {\n            \"format\": \"DD. MM. YYYY\",\n            \"daysOfWeek\": [\n              \"So\",\n              \"Mo\",\n              \"Di\",\n              \"Mi\",\n              \"Do\",\n              \"Fr\",\n              \"Sa\"\n            ],\n            \"monthNames\": [\n              \"Jänner\",\n              \"Februar\",\n              \"März\",\n              \"April\",\n              \"Mai\",\n              \"Juni\",\n              \"Juli\",\n              \"August\",\n              \"September\",\n              \"Oktober\",\n              \"November\",\n              \"Dezember\"\n            ],\n            \"firstDay\": 1\n          },\n        });\n      });\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element24 = dom.childAt(fragment, [1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element24,1,1);
        morphs[1] = dom.createMorphAt(element24,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showHeader",["loc",[null,[2,12],[2,22]]]]],[],0,null,["loc",[null,[2,6],[29,13]]]],
        ["block","if",[["get","items",["loc",[null,[31,12],[31,17]]]]],[],1,2,["loc",[null,[31,6],[133,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
define('jason-frontend/templates/components/invoice-entry', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"type","text");
          dom.setAttribute(el1,"class","js-datetimepicker input input--date-picker");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element20, 'value');
          morphs[1] = dom.createAttrMorph(element20, 'data-day-entry-id');
          morphs[2] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [
          ["attribute","value",["concat",[["get","entry.day",["loc",[null,[7,36],[7,45]]]]]]],
          ["attribute","data-day-entry-id",["concat",[["get","entry.id",["loc",[null,[7,121],[7,129]]]]]]],
          ["element","action",["changeDay",["get","entry",["loc",[null,[7,154],[7,159]]]],["get","invoiceId",["loc",[null,[7,160],[7,169]]]]],["on","change"],["loc",[null,[7,133],[7,183]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","entry.day",["loc",[null,[9,6],[9,19]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 37,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(element19,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openSlopingInfos",["get","entry",["loc",[null,[34,50],[34,55]]]]],[],["loc",[null,[34,22],[34,57]]]],
          ["inline","button-sloping",[],["color","#999999","size","38","showStroke",true,"content","Dosierungsinformationen","cssClasses",["subexpr","css-bool-evaluator",[["subexpr","is-empty",[["get","entry.slopingInfo",["loc",[null,[35,141],[35,158]]]]],[],["loc",[null,[35,131],[35,159]]]],"","selected"],[],["loc",[null,[35,111],[35,174]]]]],["loc",[null,[35,6],[35,176]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 6
              },
              "end": {
                "line": 43,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","text");
            dom.setAttribute(el1,"class","input input--editable input--full-width");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element18, 'data-article-text-entry-id');
            morphs[1] = dom.createAttrMorph(element18, 'value');
            morphs[2] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","data-article-text-entry-id",["concat",[["get","entry.id",["loc",[null,[42,105],[42,113]]]]]]],
            ["attribute","value",["concat",[["get","entry.articleName",["loc",[null,[42,126],[42,143]]]]]]],
            ["element","action",["changeText",["get","entry",["loc",[null,[42,169],[42,174]]]],["get","invoice.id",["loc",[null,[42,175],[42,185]]]],["get","entry.articleName",["loc",[null,[42,186],[42,203]]]],["get","entry.articleName",["loc",[null,[42,204],[42,221]]]]],["on","focusOut"],["loc",[null,[42,147],[42,237]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 6
              },
              "end": {
                "line": 45,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","entry.articleName",["loc",[null,[44,14],[44,35]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 4
            },
            "end": {
              "line": 46,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-name-editable",[["get","entry.article",["loc",[null,[41,30],[41,43]]]]],[],["loc",[null,[41,12],[41,44]]]]],[],0,1,["loc",[null,[41,6],[45,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 4
            },
            "end": {
              "line": 48,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","entry.articleName",["loc",[null,[47,12],[47,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 6
            },
            "end": {
              "line": 54,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","entry.article.unit.name",["loc",[null,[53,8],[53,35]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 6
            },
            "end": {
              "line": 56,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["content","entry.unitQuantity",["loc",[null,[55,8],[55,30]]]],
          ["content","entry.unit.name",["loc",[null,[55,31],[55,50]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 6
              },
              "end": {
                "line": 67,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","text");
            dom.setAttribute(el2,"class","input input--editable price-input");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element17, 'data-gross-price-entry-id');
            morphs[1] = dom.createAttrMorph(element17, 'value');
            morphs[2] = dom.createElementMorph(element17);
            return morphs;
          },
          statements: [
            ["attribute","data-gross-price-entry-id",["concat",[["get","entry.id",["loc",[null,[64,41],[64,49]]]]]]],
            ["attribute","value",["concat",[["subexpr","format-number-german",[["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[65,63],[65,74]]]],["get","entry.tax.percentage",["loc",[null,[65,75],[65,95]]]]],[],["loc",[null,[65,42],[65,96]]]]],[],["loc",[null,[65,19],[65,98]]]]]]],
            ["element","action",["changeGrossPrice",["get","entry",["loc",[null,[65,128],[65,133]]]],["get","entry.tax.percentage",["loc",[null,[65,134],[65,154]]]],["get","invoice.id",["loc",[null,[65,155],[65,165]]]],["get","entry.article.name",["loc",[null,[65,166],[65,184]]]]],["on","focusOut"],["loc",[null,[65,100],[65,200]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 6
              },
              "end": {
                "line": 69,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[68,59],[68,70]]]],["get","entry.tax.percentage",["loc",[null,[68,71],[68,91]]]]],[],["loc",[null,[68,38],[68,92]]]]],["loc",[null,[68,14],[68,94]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 70,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[61,17],[61,21]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[61,35],[61,40]]]]],[],["loc",[null,[61,27],[61,41]]]]],[],["loc",[null,[61,22],[61,42]]]]],[],["loc",[null,[61,12],[61,43]]]]],[],0,1,["loc",[null,[61,6],[69,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 85,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","input-group discount-spinner-legacy");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","number");
            dom.setAttribute(el2,"class","form-control ui-spinner-input");
            dom.setAttribute(el2,"name","spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element13, [3]);
            var element16 = dom.childAt(element13, [5]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element14, 'data-discount-entry-id');
            morphs[1] = dom.createAttrMorph(element14, 'value');
            morphs[2] = dom.createElementMorph(element14);
            morphs[3] = dom.createAttrMorph(element15, 'class');
            morphs[4] = dom.createAttrMorph(element15, 'data-qty-entry-id');
            morphs[5] = dom.createElementMorph(element15);
            morphs[6] = dom.createAttrMorph(element16, 'class');
            morphs[7] = dom.createAttrMorph(element16, 'data-qty-entry-id');
            morphs[8] = dom.createElementMorph(element16);
            return morphs;
          },
          statements: [
            ["attribute","data-discount-entry-id",["concat",[["get","entry.id",["loc",[null,[78,49],[78,57]]]]]]],
            ["attribute","value",["concat",[["get","entry.discount",["loc",[null,[79,32],[79,46]]]]]]],
            ["element","action",["changeDiscount",["get","entry",["loc",[null,[79,76],[79,81]]]],["get","invoice.id",["loc",[null,[79,82],[79,92]]]],["get","entry.absoluteDiscount",["loc",[null,[79,93],[79,115]]]],["get","entry.article.name",["loc",[null,[79,116],[79,134]]]],["get","entry.discount",["loc",[null,[79,135],[79,149]]]]],["on","focusOut"],["loc",[null,[79,50],[79,165]]]],
            ["attribute","class",["concat",["quantityspinn btn percentage ",["subexpr","get-relative-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[80,85],[80,107]]]]],[],["loc",[null,[80,55],[80,109]]]]]]],
            ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[81,40],[81,48]]]]]]],
            ["element","action",["setRelativeDiscount",["get","entry",["loc",[null,[81,83],[81,88]]]],["get","invoice.id",["loc",[null,[81,89],[81,99]]]],["get","entry.discount",["loc",[null,[81,100],[81,114]]]],["get","entry.absoluteDiscount",["loc",[null,[81,115],[81,137]]]],["get","entry.article.name",["loc",[null,[81,138],[81,156]]]]],[],["loc",[null,[81,52],[81,158]]]],
            ["attribute","class",["concat",["quantityspinn btn absolute ",["subexpr","get-absolute-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[82,83],[82,105]]]]],[],["loc",[null,[82,53],[82,107]]]]]]],
            ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[83,40],[83,48]]]]]]],
            ["element","action",["setAbsoluteDiscount",["get","entry",["loc",[null,[83,83],[83,88]]]],["get","invoice.id",["loc",[null,[83,89],[83,99]]]],["get","entry.discount",["loc",[null,[83,100],[83,114]]]],["get","entry.absoluteDiscount",["loc",[null,[83,115],[83,137]]]],["get","entry.article.name",["loc",[null,[83,138],[83,156]]]]],[],["loc",[null,[83,52],[83,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 10
                },
                "end": {
                  "line": 88,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.discount",["loc",[null,[87,36],[87,50]]]]],[],[]]],["loc",[null,[87,12],[87,52]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 10
                },
                "end": {
                  "line": 90,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" %\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.discount",["loc",[null,[89,12],[89,30]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 8
              },
              "end": {
                "line": 91,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.absoluteDiscount",["loc",[null,[86,16],[86,38]]]]],[],0,1,["loc",[null,[86,10],[90,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 6
            },
            "end": {
              "line": 92,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[75,19],[75,23]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[75,37],[75,42]]]]],[],["loc",[null,[75,29],[75,43]]]]],[],["loc",[null,[75,24],[75,44]]]]],[],["loc",[null,[75,14],[75,45]]]]],[],0,1,["loc",[null,[75,8],[91,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 8
              },
              "end": {
                "line": 103,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(element12,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadBarcode",["get","entry.laboklinFormId",["loc",[null,[100,40],[100,60]]]]],[],["loc",[null,[100,13],[100,62]]]],
            ["inline","button-barcode",[],["size","35","showStroke",true,"content","Dosierungs-Etikett herunterladen"],["loc",[null,[101,12],[101,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 8
              },
              "end": {
                "line": 108,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadSlopingBarcode",["get","entry.id",["loc",[null,[105,47],[105,55]]]]],[],["loc",[null,[105,13],[105,57]]]],
            ["inline","button-barcode",[],["size","35","showStroke",true,"content","Etikett herunterladen"],["loc",[null,[106,12],[106,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 6
            },
            "end": {
              "line": 109,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","gt",[["get","entry.laboklinFormId",["loc",[null,[99,18],[99,38]]]],0],[],["loc",[null,[99,14],[99,41]]]]],[],0,null,["loc",[null,[99,8],[103,15]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.article.lab",["loc",[null,[104,24],[104,41]]]]],[],["loc",[null,[104,19],[104,42]]]],["subexpr","not",[["get","entry.article.privateLab",["loc",[null,[104,48],[104,72]]]]],[],["loc",[null,[104,43],[104,73]]]]],[],["loc",[null,[104,14],[104,74]]]]],[],1,null,["loc",[null,[104,8],[108,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 116,
                    "column": 12
                  },
                  "end": {
                    "line": 120,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"checked","");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                if (this.cachedFragment) { dom.repairClonedNode(element9,[],true); }
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element9, 'value');
                morphs[1] = dom.createAttrMorph(element9, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element9, 'id');
                morphs[3] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[117,32],[117,48]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[117,80],[117,88]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[118,39],[118,47]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[119,67],[119,72]]]],["get","invoice.id",["loc",[null,[119,73],[119,83]]]],["get","entry.article.name",["loc",[null,[119,84],[119,102]]]]],[],["loc",[null,[119,39],[119,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 12
                  },
                  "end": {
                    "line": 124,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element8, 'value');
                morphs[1] = dom.createAttrMorph(element8, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element8, 'id');
                morphs[3] = dom.createElementMorph(element8);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[121,32],[121,48]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[121,80],[121,88]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[122,39],[122,47]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[123,67],[123,72]]]],["get","invoice.id",["loc",[null,[123,73],[123,83]]]],["get","entry.article.name",["loc",[null,[123,84],[123,102]]]]],[],["loc",[null,[123,39],[123,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 115,
                  "column": 10
                },
                "end": {
                  "line": 128,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element10, 'for');
              morphs[2] = dom.createMorphAt(element10,1,1);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","entry.bankadvice",["loc",[null,[116,18],[116,34]]]]],[],0,1,["loc",[null,[116,12],[124,19]]]],
              ["attribute","for",["concat",["bankAdvice",["get","entry.id",["loc",[null,[125,38],[125,46]]]]]]],
              ["inline","button-bankadvice",[],["size","38"],["loc",[null,[126,14],[126,45]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 114,
                "column": 8
              },
              "end": {
                "line": 129,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","is-deposit-invoice",[["get","invoice",["loc",[null,[115,41],[115,48]]]]],[],["loc",[null,[115,21],[115,49]]]]],[],["loc",[null,[115,16],[115,50]]]]],[],0,null,["loc",[null,[115,10],[128,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 130,
                  "column": 10
                },
                "end": {
                  "line": 132,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"checked","true");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(fragment, [1]),[],true); }
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element6, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[131,41],[131,49]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 132,
                  "column": 10
                },
                "end": {
                  "line": 134,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element5, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[133,41],[133,49]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 8
              },
              "end": {
                "line": 138,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element7, 'for');
            morphs[2] = dom.createMorphAt(element7,1,1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.bankadvice",["loc",[null,[130,16],[130,32]]]]],[],0,1,["loc",[null,[130,10],[134,17]]]],
            ["attribute","for",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[135,40],[135,48]]]]]]],
            ["inline","button-bankadvice",[],["size","38"],["loc",[null,[136,14],[136,45]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 113,
              "column": 6
            },
            "end": {
              "line": 139,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","edit",["loc",[null,[114,14],[114,18]]]]],[],0,1,["loc",[null,[114,8],[138,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 12
              },
              "end": {
                "line": 148,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"checked","");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element2,[],true); }
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'value');
            morphs[1] = dom.createAttrMorph(element2, 'data-applied-entry-id');
            morphs[2] = dom.createAttrMorph(element2, 'id');
            morphs[3] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[145,30],[145,43]]]]]]],
            ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[145,72],[145,80]]]]]]],
            ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[146,39],[146,47]]]]]]],
            ["element","action",["changeApplied",["get","entry",["loc",[null,[147,59],[147,64]]]],["get","invoice.id",["loc",[null,[147,65],[147,75]]]],["get","entry.article.name",["loc",[null,[147,76],[147,94]]]]],[],["loc",[null,[147,34],[147,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 148,
                "column": 12
              },
              "end": {
                "line": 152,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'data-applied-entry-id');
            morphs[2] = dom.createAttrMorph(element1, 'id');
            morphs[3] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[149,30],[149,43]]]]]]],
            ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[149,72],[149,80]]]]]]],
            ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[150,39],[150,47]]]]]]],
            ["element","action",["changeApplied",["get","entry",["loc",[null,[151,59],[151,64]]]],["get","invoice.id",["loc",[null,[151,65],[151,75]]]],["get","entry.article.name",["loc",[null,[151,76],[151,94]]]]],[],["loc",[null,[151,34],[151,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 142,
              "column": 4
            },
            "end": {
              "line": 157,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","checkbox-wrapper");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element3,1,1);
          morphs[1] = dom.createAttrMorph(element4, 'for');
          morphs[2] = dom.createMorphAt(element4,1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","entry.applied",["loc",[null,[144,18],[144,31]]]]],[],0,1,["loc",[null,[144,12],[152,19]]]],
          ["attribute","for",["concat",["checkboxExample",["get","entry.id",["loc",[null,[153,41],[153,49]]]]]]],
          ["inline","button-applied",[],["size","38","selected",["subexpr","@mut",[["get","entry.applied",["loc",[null,[154,51],[154,64]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[154,93],[154,120]]]],"Verabreicht","Angewandt"],[],["loc",[null,[154,73],[154,147]]]]],["loc",[null,[154,14],[154,149]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 161,
                "column": 8
              },
              "end": {
                "line": 165,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["openInventoryStockPanel",["get","entry",["loc",[null,[162,63],[162,68]]]]],[],["loc",[null,[162,28],[162,70]]]],
            ["inline","button-inventory",[],["color","#999999","size","34","showStroke",true,"selected",["subexpr","@mut",[["get","entry.fullyBooked",["loc",[null,[163,82],[163,99]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","entry.fullyBooked",["loc",[null,[163,128],[163,145]]]],"Warenentnahme: Vollständig ausgebucht","Warenentnahme: Noch nicht ausgebucht"],[],["loc",[null,[163,108],[163,225]]]],"cssClasses",["subexpr","css-bool-evaluator",[["get","entry.fullyBooked",["loc",[null,[163,257],[163,274]]]],"fullybooked","notbooked"],[],["loc",[null,[163,237],[163,301]]]]],["loc",[null,[163,12],[163,303]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 4
            },
            "end": {
              "line": 167,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","checkbox-wrapper verticaltop pt2");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-ehapo-available",[["get","entry",["loc",[null,[161,34],[161,39]]]],["get","entry.article",["loc",[null,[161,40],[161,53]]]]],[],["loc",[null,[161,14],[161,54]]]]],[],0,null,["loc",[null,[161,8],[165,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 4
            },
            "end": {
              "line": 174,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","small block");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" % Mwst");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[172,28],[172,39]]]]],[],[]]],["loc",[null,[172,4],[172,41]]]],
          ["content","entry.tax.percentage",["loc",[null,[173,30],[173,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 209,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-entry.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--toggle l-3/24 l-1/24@tablet");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode("\n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","cursor");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell l-9/24 l-4/24@tablet l-2/24@desk ");
        dom.setAttribute(el1,"data-label","Tag");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center table__cell--no-padding l-10/24 l-4/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","Menge");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","spinner spinner--arrows");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","spinner__plus");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el4 = dom.createElement("svg");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"width","16");
        dom.setAttribute(el4,"height","16");
        dom.setAttribute(el4,"viewBox","0 0 16 16");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"fill","#ADCCD4");
        dom.setAttribute(el5,"fill-rule","evenodd");
        dom.setAttribute(el5,"d","M2.66375576,7.964 C2.49395855,7.964 2.32389582,7.90120761 2.19445697,7.77549415 C1.93518101,7.52432458 1.93518101,7.11694607 2.19445697,6.8657765 L5.68545581,3.48193566 L2.19445697,0.0982234982 C1.93518101,-0.153074743 1.93518101,-0.560453255 2.19445697,-0.811622824 C2.45346742,-1.06279239 2.8740441,-1.06279239 3.13305455,-0.811622824 L7.56291648,3.48193566 L3.13305455,7.77549415 C3.0036157,7.90120761 2.83355297,7.964 2.66375576,7.964 L2.66375576,7.964 Z");
        dom.setAttribute(el5,"transform","rotate(-90 8.781 4.482)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        dom.setNamespace(null);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3,"type","text");
        dom.setAttribute(el3,"class","input spinner__input");
        dom.setAttribute(el3,"name","spinner");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","spinner__minus");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el4 = dom.createElement("svg");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"width","16");
        dom.setAttribute(el4,"height","16");
        dom.setAttribute(el4,"viewBox","0 0 16 16");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"fill","#ADCCD4");
        dom.setAttribute(el5,"fill-rule","evenodd");
        dom.setAttribute(el5,"d","M2.66375576,7.964 C2.49395855,7.964 2.32389582,7.90120761 2.19445697,7.77549415 C1.93518101,7.52432458 1.93518101,7.11694607 2.19445697,6.8657765 L5.68545581,3.48193566 L2.19445697,0.0982234982 C1.93518101,-0.153074743 1.93518101,-0.560453255 2.19445697,-0.811622824 C2.45346742,-1.06279239 2.8740441,-1.06279239 3.13305455,-0.811622824 L7.56291648,3.48193566 L3.13305455,7.77549415 C3.0036157,7.90120761 2.83355297,7.964 2.66375576,7.964 L2.66375576,7.964 Z");
        dom.setAttribute(el5,"transform","matrix(0 1 1 0 4.3 3.737)");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--left l-4/24 l-4/24@tablet l-1/24@desk");
        dom.setAttribute(el1,"data-label","Dosierung");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell l-15/24@tablet l-4/24@desk");
        dom.setAttribute(el1,"data-label","Artikel/Leistung");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center l-8/24 l-4/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","VPE");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center l-8/24 l-4/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","Brutto");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--center l-8/24 l-6/24@tablet l-3/24@desk");
        dom.setAttribute(el1,"data-label","Rabatt");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","single-choice");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--right l-12/24@phone l-8/24@tablet l-4/24@desk icon-bar");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("pt5 pb0");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","checkbox-wrapper");
        dom.setAttribute(el2,"style","vertical-align: top;padding-top: 0px;");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","checkbox-wrapper");
        dom.setAttribute(el2,"style","width:  31px;");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--right l-12/24@phone  l-9/24@tablet l-2/24@desk");
        dom.setAttribute(el1,"data-label","Gesamtpreis");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--action table__cell--right l-1/24");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","exp icon-button icon-button--delete list-action-square");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__second-row hidden");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-1/24 table__cell--paceholder");
        dom.setAttribute(el2,"data-label","");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-12/24@tablet l-6/24@desk");
        dom.setAttribute(el2,"data-label","");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Arzt:");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__cell l-12/24@tablet l-8/24@desk");
        dom.setAttribute(el2,"data-label","");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Assistent/in:");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element21 = dom.childAt(fragment, [5, 1]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element21, [3]);
        var element24 = dom.childAt(element21, [5]);
        var element25 = dom.childAt(fragment, [17]);
        var element26 = dom.childAt(fragment, [21, 1]);
        var element27 = dom.childAt(fragment, [23]);
        var morphs = new Array(24);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
        morphs[2] = dom.createAttrMorph(element22, 'data-qty-entry-id');
        morphs[3] = dom.createElementMorph(element22);
        morphs[4] = dom.createAttrMorph(element23, 'data-qty-entry-id');
        morphs[5] = dom.createAttrMorph(element23, 'value');
        morphs[6] = dom.createElementMorph(element23);
        morphs[7] = dom.createAttrMorph(element24, 'data-qty-entry-id');
        morphs[8] = dom.createElementMorph(element24);
        morphs[9] = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(fragment, [9]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(fragment, [11, 1]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(fragment, [13]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(fragment, [15, 1]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element25, [3]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element25, [5]),1,1);
        morphs[16] = dom.createMorphAt(element25,7,7);
        morphs[17] = dom.createMorphAt(element25,9,9);
        morphs[18] = dom.createMorphAt(dom.childAt(fragment, [19]),1,1);
        morphs[19] = dom.createElementMorph(element26);
        morphs[20] = dom.createMorphAt(element26,1,1);
        morphs[21] = dom.createAttrMorph(element27, 'data-id');
        morphs[22] = dom.createMorphAt(dom.childAt(element27, [3]),3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element27, [5]),3,3);
        return morphs;
      },
      statements: [
        ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","entry",["loc",[null,[3,45],[3,50]]]]],[],[]],"toggle","toggle"],["loc",[null,[3,23],[3,68]]]],
        ["block","if",[["get","edit",["loc",[null,[6,10],[6,14]]]]],[],0,1,["loc",[null,[6,4],[10,11]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[14,52],[14,60]]]]]]],
        ["element","action",["increaseQuantity",["get","entry",["loc",[null,[14,92],[14,97]]]],["get","invoice.id",["loc",[null,[14,98],[14,108]]]],["get","entry.article.name",["loc",[null,[14,109],[14,127]]]]],[],["loc",[null,[14,64],[14,129]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[22,75],[22,83]]]]]]],
        ["attribute","value",["concat",[["get","entry.quantity",["loc",[null,[22,111],[22,125]]]]]]],
        ["element","action",["changeQuantity",["get","entry",["loc",[null,[22,155],[22,160]]]],["get","invoice.id",["loc",[null,[22,161],[22,171]]]],["get","entry.article.name",["loc",[null,[22,172],[22,190]]]],["get","entry.quantity",["loc",[null,[22,191],[22,205]]]]],["on","focusOut"],["loc",[null,[22,129],[22,221]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[24,53],[24,61]]]]]]],
        ["element","action",["decreaseQuantity",["get","entry",["loc",[null,[24,93],[24,98]]]],["get","invoice.id",["loc",[null,[24,99],[24,109]]]],["get","entry.article.name",["loc",[null,[24,110],[24,128]]]]],[],["loc",[null,[24,65],[24,130]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[33,17],[33,27]]]],"def"],[],["loc",[null,[33,13],[33,34]]]],["get","edit",["loc",[null,[33,36],[33,40]]]],["subexpr","is-product",[["get","entry",["loc",[null,[33,53],[33,58]]]]],[],["loc",[null,[33,41],[33,59]]]]],[],["loc",[null,[33,8],[33,60]]]]],[],2,null,["loc",[null,[33,2],[37,9]]]],
        ["block","if",[["get","edit",["loc",[null,[40,10],[40,14]]]]],[],3,4,["loc",[null,[40,4],[48,11]]]],
        ["block","unless",[["get","entry.unit",["loc",[null,[52,16],[52,26]]]]],[],5,6,["loc",[null,[52,6],[56,17]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[60,14],[60,24]]]],"def"],[],["loc",[null,[60,10],[60,31]]]]],[],7,null,["loc",[null,[60,4],[70,11]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[74,21],[74,31]]]],"def"],[],["loc",[null,[74,17],[74,38]]]],["subexpr","is-discount-available",[["get","entry.article",["loc",[null,[74,62],[74,75]]]],["get","type",["loc",[null,[74,76],[74,80]]]]],[],["loc",[null,[74,39],[74,81]]]]],[],["loc",[null,[74,12],[74,82]]]]],[],8,null,["loc",[null,[74,6],[92,13]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[98,16],[98,26]]]],"def"],[],["loc",[null,[98,12],[98,33]]]]],[],9,null,["loc",[null,[98,6],[109,13]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[113,16],[113,26]]]],"def"],[],["loc",[null,[113,12],[113,33]]]]],[],10,null,["loc",[null,[113,6],[139,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[142,19],[142,29]]]],"def"],[],["loc",[null,[142,15],[142,36]]]],["subexpr","is-applied-available",[["get","entry",["loc",[null,[142,59],[142,64]]]],["get","type",["loc",[null,[142,65],[142,69]]]]],[],["loc",[null,[142,37],[142,70]]]]],[],["loc",[null,[142,10],[142,71]]]]],[],11,null,["loc",[null,[142,4],[157,11]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.type",["loc",[null,[159,19],[159,29]]]],"def"],[],["loc",[null,[159,15],[159,36]]]],["subexpr","not",[["get","entry.bankadvice",["loc",[null,[159,42],[159,58]]]]],[],["loc",[null,[159,37],[159,59]]]],["subexpr","not",[["subexpr","is-deposit-entry",[["get","entry",["loc",[null,[159,83],[159,88]]]]],[],["loc",[null,[159,65],[159,89]]]]],[],["loc",[null,[159,60],[159,90]]]]],[],["loc",[null,[159,10],[159,91]]]]],[],12,null,["loc",[null,[159,4],[167,11]]]],
        ["block","if",[["subexpr","eq",[["get","entry.type",["loc",[null,[171,14],[171,24]]]],"def"],[],["loc",[null,[171,10],[171,31]]]]],[],13,null,["loc",[null,[171,4],[174,11]]]],
        ["element","action",["delete",["get","entry.id",["loc",[null,[177,25],[177,33]]]],["get","invoice.id",["loc",[null,[177,34],[177,44]]]],["get","entry.article.name",["loc",[null,[177,45],[177,63]]]]],[],["loc",[null,[177,7],[177,65]]]],
        ["inline","button-delete-list",[],["size","40","content","","showStroke",false,"color","#fff"],["loc",[null,[178,6],[178,79]]]],
        ["attribute","data-id",["concat",[["get","entry.id",["loc",[null,[182,51],[182,59]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[187,18],[187,25]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[188,16],[188,22]]]]],[],[]],"cssClass","input--dropdown input--small doctor unchanged entry-doctor","optionLabelPath","shortName","allowClear",true,"placeholder","keine/r"],["loc",[null,[186,8],[193,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[198,16],[198,26]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[199,14],[199,23]]]]],[],[]],"cssClass","input--dropdown input--small assistant unchanged entry-assistant","optionLabelPath","shortName","allowClear",true,"placeholder","keine/r"],["loc",[null,[197,6],[204,10]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
    };
  }()));

});
define('jason-frontend/templates/rlogin', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/rlogin.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"style","margin: 100px auto");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"width","38");
        dom.setAttribute(el2,"height","38");
        dom.setAttribute(el2,"viewBox","0 0 38 38");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"fill","#404040");
        dom.setAttribute(el2,"class","loading-animation-new");
        dom.setAttribute(el2,"style","margin: auto; display:block");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("defs");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("linearGradient");
        dom.setAttribute(el4,"x1","8.042%");
        dom.setAttribute(el4,"y1","0%");
        dom.setAttribute(el4,"x2","65.682%");
        dom.setAttribute(el4,"y2","23.865%");
        dom.setAttribute(el4,"id","a");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("stop");
        dom.setAttribute(el5,"stop-color","#404040");
        dom.setAttribute(el5,"stop-opacity","0");
        dom.setAttribute(el5,"offset","0%");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("stop");
        dom.setAttribute(el5,"stop-color","#404040");
        dom.setAttribute(el5,"stop-opacity",".631");
        dom.setAttribute(el5,"offset","63.146%");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("stop");
        dom.setAttribute(el5,"stop-color","#404040");
        dom.setAttribute(el5,"offset","100%");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"transform","translate(1 1)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M36 18c0-9.94-8.06-18-18-18");
        dom.setAttribute(el5,"id","Oval-2");
        dom.setAttribute(el5,"stroke","url(#a)");
        dom.setAttribute(el5,"stroke-width","2");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("animateTransform");
        dom.setAttribute(el6,"attributeName","transform");
        dom.setAttribute(el6,"type","rotate");
        dom.setAttribute(el6,"from","0 18 18");
        dom.setAttribute(el6,"to","360 18 18");
        dom.setAttribute(el6,"dur","0.9s");
        dom.setAttribute(el6,"repeatCount","indefinite");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("circle");
        dom.setAttribute(el5,"fill","#404040");
        dom.setAttribute(el5,"cx","36");
        dom.setAttribute(el5,"cy","18");
        dom.setAttribute(el5,"r","1");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("animateTransform");
        dom.setAttribute(el6,"attributeName","transform");
        dom.setAttribute(el6,"type","rotate");
        dom.setAttribute(el6,"from","0 18 18");
        dom.setAttribute(el6,"to","360 18 18");
        dom.setAttribute(el6,"dur","0.9s");
        dom.setAttribute(el6,"repeatCount","indefinite");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2,"class","text-center mt10");
        var el3 = dom.createTextNode("Bitte warten...");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});
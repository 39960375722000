define('jason-frontend/models/mandant', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    additionalName: DS['default'].attr('string'),
    street: DS['default'].attr('string'),
    shortName: DS['default'].attr('string'),
    town: DS['default'].attr('string'),
    postalCode: DS['default'].attr('string'),
    phone: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    purchaseEmail: DS['default'].attr('string'),
    homepage: DS['default'].attr('string'),
    maxUsers: DS['default'].attr(),
    etigaLabSetting: DS['default'].attr(),
    useRecommendedPriceAddition: DS['default'].attr(),
    etigaTreatmentReportSetting: DS['default'].attr(),
    taxNumber: DS['default'].attr('string'),
    bankName: DS['default'].attr('string'),
    barsoi: DS['default'].attr(''),
    taxCode: DS['default'].attr('string'),
    defaultReminderSetting: DS['default'].attr('string'),
    bic: DS['default'].attr('string'),
    invoiceEmail: DS['default'].attr(''),
    iban: DS['default'].attr('string'),
    active: DS['default'].attr('boolean'),
    showUid: DS['default'].attr('boolean'),
    invoiceSimFee: DS['default'].attr('boolean'),
    invoiceCashbackDetails: DS['default'].attr('boolean'),
    cashbackEnabled: DS['default'].attr('boolean'),
    privateEncryptionKey: DS['default'].attr('string'),
    privateEncryptionKeyCheckSum: DS['default'].attr('string'),
    certificateSerialNumber: DS['default'].attr('string'),
    jasonNetworkFee: DS['default'].attr('number'),
    warningEnabled: DS['default'].attr('boolean'),
    warningEmailEnabled: DS['default'].attr('boolean'),
    daysTillWarning1: DS['default'].attr('number'),
    daysTillWarning2: DS['default'].attr('number'),
    daysTillNopayer: DS['default'].attr('number'),
    operators: DS['default'].attr('string'),
    invoiceOpeningText: DS['default'].attr('string'),
    invoiceOpeningTextLinup: DS['default'].attr('string'),
    logo: DS['default'].belongsTo('media'),
    signatureMedia: DS['default'].belongsTo('media'),
    etigaAutohideTreatments: DS['default'].attr('boolean'),
    autoPrintT: DS['default'].attr('boolean'),
    autoPrintM: DS['default'].attr('boolean'),
    autoPrintJ: DS['default'].attr('boolean'),
    autoPrintTs: DS['default'].attr('boolean'),
    autoPrintMs: DS['default'].attr('boolean'),
    autoPrintJs: DS['default'].attr('boolean'),
    autoPrintZ: DS['default'].attr('boolean'),
    autoPrintZs: DS['default'].attr('boolean'),
    resetWarningProcessOnPayment: DS['default'].attr('boolean'),
    autoZbon: DS['default'].attr(''),
    licenceCash: DS['default'].attr('boolean'),
    laboklinUser: DS['default'].attr('string'),
    laboklinPass: DS['default'].attr('string'),
    exportInvoiceMode: DS['default'].attr(''),
    financeWsUser: DS['default'].attr('string'),
    financeWsPassword: DS['default'].attr('string'),
    financeWsUserid: DS['default'].attr('string'),
    elordUser: DS['default'].attr('string'),
    elordPassword: DS['default'].attr('string'),
    locationNumber: DS['default'].attr('string'),
    vetDoctorNumber: DS['default'].attr('string'),
    licencePurchase: DS['default'].attr('boolean'),
    defaultOverdueFines1: DS['default'].attr('number'),
    defaultOverdueFines2: DS['default'].attr('number'),
    defaultInterestForDelay: DS['default'].attr('number'),
    defaultInterestForDelay2: DS['default'].attr('number'),
    ehapoCheckCashdesk: DS['default'].attr(),
    ehapoCheckTreatment: DS['default'].attr(),
    ehapoShowStock: DS['default'].attr(),
    ehapoUseAutodosing: DS['default'].attr(),
    ehapoUseFavorites: DS['default'].attr(),
    ehapoAutoOrder: DS['default'].attr(),
    ehapoAutoBook: DS['default'].attr(),
    hidePrices: DS['default'].attr(),
    hapoNr: DS['default'].attr(),
    routeAutocalculationEnabled: DS['default'].attr(),
    emailData: DS['default'].belongsTo('mandant-email-text'),
    deliveryStreet: DS['default'].attr('string'),
    deliveryTown: DS['default'].attr('string'),
    deliveryPostalCode: DS['default'].attr('string'),
    mandantType: DS['default'].attr('string'),
    invoiceCashback: DS['default'].attr('boolean'),
    invoiceTransactionFee: DS['default'].attr('boolean'),
    invoiceCashbackReduction: DS['default'].attr('boolean'),
    jumpFuture: DS['default'].attr('boolean'),
    latestInvoice: DS['default'].attr(),
    currentInvoice: DS['default'].attr(),
    autosendInvoice: DS['default'].attr(),
    idfNumber: DS['default'].attr(),
    idfValid: DS['default'].attr(),
    idfPrivateValid: DS['default'].attr(),
    idfDrugValid: DS['default'].attr(),
    idfNumberPrivate: DS['default'].attr(),
    idfNumberDrug: DS['default'].attr(),
    idfNumberApv: DS['default'].attr(),
    idfApvValid: DS['default'].attr(),
    defaultDoctor: DS['default'].attr(''),
    defaultAssistant: DS['default'].attr(''),
    defaultRoom: DS['default'].attr(),
    calendarDefaultDuration: DS['default'].attr(),
    calendarTitleMode: DS['default'].attr(),
    defaultStation: DS['default'].attr(),
    defaultAppointmentType: DS['default'].attr(),
    autoAdmission: DS['default'].attr(),
    autoAdmissionTime: DS['default'].attr(),
    stayInCalendar: DS['default'].attr(),
    hapoSync: DS['default'].attr(),
    hapoActive: DS['default'].attr(),
    sendMessagesCalendar: DS['default'].attr(),
    calendarUserOverrule: DS['default'].attr(),
    vetconnectUsername: DS['default'].attr(),
    vetconnectPassword: DS['default'].attr(),
    defaultTemplateStyle: DS['default'].attr(),
    vetconnectAddition: DS['default'].attr(),
    paymentDeadline: DS['default'].attr(),
    paymentDeadlineWarning1: DS['default'].attr(),
    paymentDeadlineWarning2: DS['default'].attr(),
    validations: {
      name: {
        presence: true
      }
    }
  });

});